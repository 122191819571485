/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useMediaQuery } from "usehooks-ts";
import { Modal, Row } from "rsuite";
import moment from "moment";
import { Icon } from "@iconify/react";
import DetailMainScreen from "./DetailMainScreen";
import * as ReportService from "../../../services/reportServices";

const MainScreen = (props: any) => {
  ChartJS.register(ArcElement, Tooltip, Legend);
  const labels = ["On time", "Post deadline"];

  const {
    userContext,
    fromDate,
    groupTypeID,
    toDate,
    userStore,
    setUserStore,
  } = props;

  const dataArray = [];
  //DetailPage Region
  const [isDetailView, setDetailView] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  // region end
  const [succes, setSuccess] = useState(0);
  const [failed, setFailed] = useState(0);
  const [newPercentagesData, setNewPercentagesData] = useState(0);
  //region

  //#endregion
  const [AllOrderData, setAllOrderData] = useState([]);

  useEffect(() => {
    getOrderingData(
      moment(fromDate).format("YYYY/MM/DD"),
      moment(toDate).format("YYYY/MM/DD"),
      "Totalstore"
    );
  }, [userContext, fromDate, toDate, userStore, groupTypeID]);

  const getOrderingData = async (fromDate, toDate, Operation) => {
    const { groupTypeId, store, roleName } = userContext;

    if (roleName && roleName === "Super Admin" && userStore === "") {
      const storeType = "";
      ReportService.GetOrderingReport(
        fromDate,
        toDate,
        groupTypeID,
        storeType,
        Operation
      )
        .then((result) => {
          let failedDataa = 0;

          result.storeRpt.map((data) => {
            if (data.failed === "1") {
              failedDataa++;
            }
          });

          result.storeRpt.map((data) => {
            data.delivery_Date = moment(data.delivery_Date).format(
              "MM-DD-YYYY"
            );
          });
          result.storeRpt.map((data) => {
            data.creation_Date = moment(data.creation_Date).format(
              "MM-DD-YYYY hh:mm A"
            );
          });
          result.storeRpt.map((data) => {
            data.deadLine_Date = moment(data.deadLine_Date).format(
              "MM-DD-YYYY hh:mm A"
            );
          });
          result.storeRpt.map((data) => {
            if (data.department_Name) {
              data.department_Name = data.department_Name.trim();
            }
          });
          setFailed(failedDataa);
          setSuccess(result.storeRpt.length - failedDataa);
          setAllOrderData(result.storeRpt);
        })

        .catch((err) => {
          console.error("Error: Get Orders - ", err);
        });
    } else if (store !== 0) {
      const storeType = "Store";
      ReportService.GetOrderingReport(
        fromDate,
        toDate,
        store,
        storeType,
        Operation
      )
        .then((result) => {
          let failedDataa = 0;

          result.storeRpt.map((data) => {
            if (data.failed === "1") {
              failedDataa++;
            }
          });

          result.storeRpt.map((data) => {
            data.delivery_Date = moment(data.delivery_Date).format(
              "MM-DD-YYYY"
            );
          });
          result.storeRpt.map((data) => {
            data.creation_Date = moment(data.creation_Date).format(
              "MM-DD-YYYY hh:mm A"
            );
          });
          result.storeRpt.map((data) => {
            data.deadLine_Date = moment(data.deadLine_Date).format(
              "MM-DD-YYYY hh:mm A"
            );
          });
          result.storeRpt.map((data) => {
            if (data.department_Name) {
              data.department_Name = data.department_Name.trim();
            }
          });
          setFailed(failedDataa);
          setSuccess(result.storeRpt.length - failedDataa);
          setAllOrderData(result.storeRpt);
        })

        .catch((err) => {
          console.error("Error: Get Orders - ", err);
        });
    } else if (
      userContext.groupTypeID === 0 &&
      userContext.groupType &&
      userContext.groupTypeName === ""
    ) {
      const storeType = "Store";
      ReportService.GetOrderingReport(
        fromDate,
        toDate,
        userStore,
        storeType,
        Operation
      )
        .then((result) => {
          let failedDataa = 0;

          result.storeRpt.map((data) => {
            if (data.failed === "1") {
              failedDataa++;
            }
          });

          result.storeRpt.map((data) => {
            data.delivery_Date = moment(data.delivery_Date).format(
              "MM-DD-YYYY"
            );
          });
          result.storeRpt.map((data) => {
            data.creation_Date = moment(data.creation_Date).format(
              "MM-DD-YYYY hh:mm A"
            );
          });
          result.storeRpt.map((data) => {
            data.deadLine_Date = moment(data.deadLine_Date).format(
              "MM-DD-YYYY hh:mm A"
            );
          });
          result.storeRpt.map((data) => {
            if (data.department_Name) {
              data.department_Name = data.department_Name.trim();
            }
          });
          setFailed(failedDataa);
          setSuccess(result.storeRpt.length - failedDataa);
          setAllOrderData(result.storeRpt);
        })

        .catch((err) => {
          console.error("Error: Get Orders - ", err);
        });
    } else if (userStore) {
      const storeType = "Store";
      ReportService.GetOrderingReport(
        fromDate,
        toDate,
        userStore,
        storeType,
        Operation
      )
        .then((result) => {
          let failedDataa = 0;

          result.storeRpt.map((data) => {
            if (data.failed === "1") {
              failedDataa++;
            }
          });

          result.storeRpt.map((data) => {
            data.delivery_Date = moment(data.delivery_Date).format(
              "MM-DD-YYYY"
            );
          });
          result.storeRpt.map((data) => {
            data.creation_Date = moment(data.creation_Date).format(
              "MM-DD-YYYY hh:mm A"
            );
          });
          result.storeRpt.map((data) => {
            data.deadLine_Date = moment(data.deadLine_Date).format(
              "MM-DD-YYYY hh:mm A"
            );
          });
          result.storeRpt.map((data) => {
            if (data.department_Name) {
              data.department_Name = data.department_Name.trim();
            }
          });
          setFailed(failedDataa);
          setSuccess(result.storeRpt.length - failedDataa);
          setAllOrderData(result.storeRpt);
        })

        .catch((err) => {
          console.error("Error: Get Orders - ", err);
        });
    } else if (
      userContext.groupTypeID !== 0 &&
      userContext.groupType &&
      userContext.groupTypeName
    ) {
      const storeType = "";

      ReportService.GetOrderingReport(
        fromDate,
        toDate,
        groupTypeId,
        storeType,
        Operation
      )
        .then((result) => {
          let failedDataa = 0;

          result.storeRpt.map((data) => {
            if (data.failed === "1") {
              failedDataa++;
            }
          });

          result.storeRpt.map((data) => {
            data.delivery_Date = moment(data.delivery_Date).format(
              "MM-DD-YYYY"
            );
          });
          result.storeRpt.map((data) => {
            data.creation_Date = moment(data.creation_Date).format(
              "MM-DD-YYYY hh:mm A"
            );
          });
          result.storeRpt.map((data) => {
            data.deadLine_Date = moment(data.deadLine_Date).format(
              "MM-DD-YYYY hh:mm A"
            );
          });
          result.storeRpt.map((data) => {
            if (data.department_Name) {
              data.department_Name = data.department_Name.trim();
            }
          });
          setFailed(failedDataa);
          setSuccess(result.storeRpt.length - failedDataa);
          setAllOrderData(result.storeRpt);
        })

        .catch((err) => {
          console.error("Error: Get Orders - ", err);
        });
    } else {
      console.log(99);
    }
  };

  useEffect(() => {
    dataArray.push(succes);
    dataArray.push(failed);
    setNewPercentagesData(calculatePercentages(dataArray));
  }, [succes, failed]);

  function calculatePercentages(numbers) {
    const total = numbers.reduce((sum, number) => sum + number, 0);
    const percentages = numbers.map((number) =>
      ((number / total) * 100).toFixed(2)
    );
    return percentages;
  }

  const options = {
    borderWidth: 0,
    hoverBorderWith: 1,
    borderRadius: 3,
    maintainAspectRatio: useMediaQuery("(max-width: 768px)") ? false : true,

    plugins: {
      datalabels: {
        display: false,
        color: "#575757;",
        font: { weight: 900, size: 10 },
      },
      legend: {
        position: "top" as const,
        display: false,
      },
      title: {
        display: false,
        text: `From ${fromDate} To ${toDate} Track IT Shrink $ Price`,
      },

      tooltip: {
        callbacks: {
          label: (ttItem) => `${ttItem.parsed}%`,
        },
        displayColors: false,
      },
    },
  };

  const data = {
    labels: labels,
    datasets: [
      {
        data: newPercentagesData,
        backgroundColor: ["#198754", "#a94442"],
        borderColor: ["#186BA1", "#19ABDE"],
        spacing: 20,
        hoverOffset: 2,
      },
    ],
  };

  return (
    <>
      <div className="chart-heading-main">Main Screen</div>
      <>
        <Row>
          <div className="programming-stats-dashboard my-4">
            <div className="center-chart">
              <Doughnut options={options} data={data} />
            </div>
            <div className="details-chart">
              <ul>
                <li className="chart-li">
                  <div className={`chart-label-mainscreen-processed`}>
                    {"On Time"}
                  </div>
                  <span className="percantage">{" " + succes}</span>
                </li>
                <li className="chart-li">
                  <div className={`chart-label-mainscreen-failed`}>
                    {"Post Deadline"}
                  </div>
                  <span className="percantage">{" " + failed}</span>
                </li>
              </ul>
            </div>
            <div className="chart-li-item">
              <div
                onClick={() => {
                  setOpen(true);
                }}
                style={{ cursor: "pointer" }}
                className="chart-label-mainscreen center-row-direction"
              >
                <Icon className="icon-size" icon="ic:outline-remove-red-eye" />
                <div>Total Orders:</div>
                <div className="percantage">{succes + failed}</div>
              </div>
            </div>
          </div>
        </Row>
      </>
      <Modal size="full" open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>
            <span
              className="mx-2 filter"
              onClick={() => {
                setOpen(false);
              }}
            >
              <Icon icon="ep:back" />
            </span>
            Main Screen Total Orders
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DetailMainScreen
            setSuccess={setSuccess}
            setFailed={setFailed}
            fromDate={fromDate}
            toDate={toDate}
            AllOrderData={AllOrderData}
            userContext={userContext}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MainScreen;
