export const MainScreenConstants = {
    "TotalStore": [
        
        //{ Header: "UID", DataKey: "uid", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        //{ Header: "Store ID", DataKey: "store_ID", Width: 150, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Store ID", DataKey: "store_ID", Width: 100, isFixed: true, isSortable: true, Align: "right", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "User ID", DataKey: "user_ID", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Vendor Name", DataKey: "vendor_Name", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Department Name", DataKey: "department_Name", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        //{ Header: "GOT Department", DataKey: "gotDept", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "GOT Confirmation", DataKey: "gotConf", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "AWG Confirmation", DataKey: "awgConf", Width: 150, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        //{ Header: "Breaker ID", DataKey: "breaker_ID", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Delivery Date", DataKey: "delivery_Date", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Date", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Items Ordered", DataKey: "order_Lines", Width: 120, isFixed: false, isSortable: true, Align: "right", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Pieces", DataKey: "order_Pieces", Width: 120, isFixed: false, isSortable: true, Align: "right", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        //{ Header: "Failed", DataKey: "failed", Width: 120, isFixed: false, isSortable: true, Align: "right", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Send Date", DataKey: "creation_Date", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Date", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Deadline Date", DataKey: "deadLine_Date", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Date", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
    ]
}