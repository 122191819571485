import { TableHeaderConfig } from "../../../../models"

export const ReportConstants = {
    "Markdown & Shrink": [
        {...new TableHeaderConfig(), Header: "Company Store Id", DataKey: "client_Store_N", isFixed: true, isSearch: true, searchGroup: [2] },     
        //{...new TableHeaderConfig(), Header: "Company Store Id", DataKey: "client_Store_N", Width: 100,TypeOfData: "text-center-heading", isFixed: false, isSearch: true, searchGroup: [2] },
        {...new TableHeaderConfig(), Header: "User ID", DataKey: "user_ID", Width: 10, isFixed: false, isSearch: true, searchGroup: [1, 2] },
        {...new TableHeaderConfig(), Header: "Regular Price Keyed", DataKey: "Badge_Type", Width: 10, isSearch: true, searchGroup: [1, 2] , isHeader: false},
        {...new TableHeaderConfig(), Header: "UPC Scanned", DataKey: "upC_Scanned", Width: 10, isFixed: false, isSearch: true, searchGroup: [1, 2] },
        {...new TableHeaderConfig(), Header: "Description", DataKey: "Description", Width: 10, isFixed: false, TypeOfData: "text-center-heading", isSearch: true, searchGroup: [1, 2] },
        {...new TableHeaderConfig(), Header: "Confirmation ID", DataKey: "confirmation_ID", Width: 10 },
        {...new TableHeaderConfig(), Header: "Date Printed", DataKey: "FormatedPrintedDate", Width: 10 },
        {...new TableHeaderConfig(), Header: "Size", DataKey: "size", Width: 10, TypeOfData: "Number" },
        {...new TableHeaderConfig(), Header: "Department", DataKey: "department", Width: 10, isSearch: true, searchGroup: [1, 2] },
        {...new TableHeaderConfig(), Header: "Pnt Count", DataKey: "pnt_Count", Width: 10, TypeOfData: "Number" },
        {...new TableHeaderConfig(), Header: "Reg Price", DataKey: "reg_Price", Width: 10, TypeOfData: "Price", Icon : "reg_Price_Keyed" },
        {...new TableHeaderConfig(), Header: "Reg Total", DataKey: "reg_Total", Width: 10, TypeOfData: "Price" },
        {...new TableHeaderConfig(), Header: "Label Type", DataKey: "label_Type", Width: 10, TypeOfData: "text-center", isSearch: true, searchGroup: [1, 2] },
        {...new TableHeaderConfig(), Header: "Reason", DataKey: "reason", Width: 10, isSearch: true, searchGroup: [1, 2] },
        {...new TableHeaderConfig(), Header: "Disc Price", DataKey: "disc_Price", Width: 10, TypeOfData: "Price" },
        {...new TableHeaderConfig(), Header: "Disc Percent", DataKey: "disc_Percent", Width: 10, TypeOfData: "Number" },
        {...new TableHeaderConfig(), Header: "Disc Total", DataKey: "disc_Total", Width: 10, TypeOfData: "Price" },
        {...new TableHeaderConfig(), Header: "Markdown Loss", DataKey: "MarkDownLoss", Width: 10, TypeOfData: "Price" },
        {...new TableHeaderConfig(), Header: "Shrink Scan Time", DataKey: "shrink_Scan_Time", Width: 10 },
        {...new TableHeaderConfig(), Header: "Hours Shrink", DataKey: "hours_Shrink", Width: 10, TypeOfData: "Number" },
        {...new TableHeaderConfig(), Header: "Shrink Confirmation ID", DataKey: "shrink_Confirmation_ID", Width: 200, isSearch: true, searchGroup: [1, 2] },
        {...new TableHeaderConfig(), Header: "Shrink User ID", DataKey: "shrink_User_ID", Width: 10 },
        {...new TableHeaderConfig(), Header: "Department Incoming", DataKey: "department_Incoming", Width: 10, isHeader: false },
        {...new TableHeaderConfig(), Header: "Reclaim", DataKey: "reclaim", Width: 10, TypeOfData: "text-center" },
        {...new TableHeaderConfig(), Header: "Reason Code", DataKey: "reason_Code", Width: 10, TypeOfData: "Number", isHeader: false },
        {...new TableHeaderConfig(), Header: "File Detail UID", DataKey: "file_Detail_UID", Width: 10, isHeader: false }
    ],
    "Markdown": [
        {...new TableHeaderConfig(), Header: "Company Store Id", DataKey: "client_Store_N", isFixed: true, isSearch: true, searchGroup: [2] },     
        //{...new TableHeaderConfig(), Header: "Company Store Id", DataKey: "client_Store_N", Width: 100,TypeOfData: "text-center-heading", isFixed: false, isSearch: true, searchGroup: [2] },
        {...new TableHeaderConfig(), Header: "User ID", DataKey: "user_ID", Width: 10, isFixed: false, isSearch: true, searchGroup: [1, 2] },
        {...new TableHeaderConfig(), Header: "Regular Price Keyed", DataKey: "Badge_Type", Width: 10, isSearch: true, searchGroup: [1, 2] , isHeader: false},
        {...new TableHeaderConfig(), Header: "UPC Scanned", DataKey: "upC_Scanned", Width: 10, isFixed: false, isSearch: true, searchGroup: [1, 2] },
        {...new TableHeaderConfig(), Header: "Description", DataKey: "Description", Width: 10, isFixed: false, TypeOfData: "text-center-heading", isSearch: true, searchGroup: [1, 2] },
        {...new TableHeaderConfig(), Header: "Confirmation ID", DataKey: "confirmation_ID", Width: 10 },
        {...new TableHeaderConfig(), Header: "Date Printed", DataKey: "FormatedPrintedDate", Width: 10 },
        {...new TableHeaderConfig(), Header: "Size", DataKey: "size", Width: 10, TypeOfData: "Number" },
        {...new TableHeaderConfig(), Header: "Department", DataKey: "department", Width: 10, isSearch: true, searchGroup: [1, 2] },
        {...new TableHeaderConfig(), Header: "Pnt Count", DataKey: "pnt_Count", Width: 10, TypeOfData: "Number" },
        {...new TableHeaderConfig(), Header: "Reg Price", DataKey: "reg_Price", Width: 10, TypeOfData: "Price", Icon : "reg_Price_Keyed" },
        {...new TableHeaderConfig(), Header: "Reg Total", DataKey: "reg_Total", Width: 10, TypeOfData: "Price" },
        {...new TableHeaderConfig(), Header: "Label Type", DataKey: "label_Type", Width: 10, TypeOfData: "text-center", isSearch: true, searchGroup: [1, 2] },
        {...new TableHeaderConfig(), Header: "Reason", DataKey: "reason", Width: 10, isSearch: true, searchGroup: [1, 2] },
        {...new TableHeaderConfig(), Header: "Disc Price", DataKey: "disc_Price", Width: 10, TypeOfData: "Price" },
        {...new TableHeaderConfig(), Header: "Disc Percent", DataKey: "disc_Percent", Width: 10, TypeOfData: "Number" },
        {...new TableHeaderConfig(), Header: "Disc Total", DataKey: "disc_Total", Width: 10, TypeOfData: "Price" },
        {...new TableHeaderConfig(), Header: "Markdown Loss", DataKey: "MarkDownLoss", Width: 10, TypeOfData: "Price" },
    ],
    "Shrink": [
        {...new TableHeaderConfig(), Header: "Company Store Id", DataKey: "client_Store_N", isFixed: true, isSearch: true, searchGroup: [2] },     
        //{...new TableHeaderConfig(), Header: "Company Store Id", DataKey: "client_Store_N", Width: 100,TypeOfData: "text-center-heading", isFixed: false, isSearch: true, searchGroup: [2] },
        {...new TableHeaderConfig(), Header: "User ID", DataKey: "user_ID", Width: 10, isFixed: false, isSearch: true, searchGroup: [1, 2] },
        {...new TableHeaderConfig(), Header: "Regular Price Keyed", DataKey: "Badge_Type", Width: 10, isSearch: true, searchGroup: [1, 2] , isHeader: false},
        {...new TableHeaderConfig(), Header: "UPC Scanned", DataKey: "upC_Scanned", Width: 10, isFixed: false, isSearch: true, searchGroup: [1, 2] },
        {...new TableHeaderConfig(), Header: "Description", DataKey: "Description", Width: 10, isFixed: false, TypeOfData: "text-center-heading", isSearch: true, searchGroup: [1, 2] },
        {...new TableHeaderConfig(), Header: "Department", DataKey: "department", Width: 10, isSearch: true, searchGroup: [1, 2] },
        {...new TableHeaderConfig(), Header: "Reg Price", DataKey: "reg_Price", Width: 10, TypeOfData: "Price", Icon : "reg_Price_Keyed" },
        {...new TableHeaderConfig(), Header: "Reason", DataKey: "reason", Width: 10, isSearch: true, searchGroup: [1, 2] },
        {...new TableHeaderConfig(), Header: "Shrink Scan Time", DataKey: "shrink_Scan_Time", Width: 10 },
        {...new TableHeaderConfig(), Header: "Hours Shrink", DataKey: "hours_Shrink", Width: 10, TypeOfData: "Number" },
        {...new TableHeaderConfig(), Header: "Shrink Confirmation ID", DataKey: "shrink_Confirmation_ID", Width: 10, isSearch: true, searchGroup: [1, 2] },
        {...new TableHeaderConfig(), Header: "Shrink User ID", DataKey: "shrink_User_ID", Width: 10 },
        {...new TableHeaderConfig(), Header: "Department Incoming", DataKey: "department_Incoming", Width: 10, isHeader: false },
        {...new TableHeaderConfig(), Header: "Reclaim", DataKey: "reclaim", Width: 10, TypeOfData: "text-center" },
        {...new TableHeaderConfig(), Header: "Reason Code", DataKey: "reason_Code", Width: 10, TypeOfData: "Number", isHeader: false },
        {...new TableHeaderConfig(), Header: "File Detail UID", DataKey: "file_Detail_UID", Width: 10, isHeader: false }
    ],
    "Reclaim Eligible": [
{...new TableHeaderConfig(), Header: "Company Store Id", DataKey: "client_Store_N", isFixed: false, isSearch: true, searchGroup: [2] },     
        //{...new TableHeaderConfig(), Header: "Company Store Id", DataKey: "client_Store_N", Width: 100,TypeOfData: "text-center-heading", isFixed: false, isSearch: true, searchGroup: [2] },
        {...new TableHeaderConfig(), Header: "User ID", DataKey: "user_ID", Width: 10, isFixed: false, isSearch: true, searchGroup: [1, 2] },
        {...new TableHeaderConfig(), Header: "Regular Price Keyed", DataKey: "Badge_Type", Width: 10, isSearch: true, searchGroup: [1, 2] , isHeader: false},
        {...new TableHeaderConfig(), Header: "UPC Scanned", DataKey: "upC_Scanned", Width: 10, isFixed: false, isSearch: true, searchGroup: [1, 2] },
        {...new TableHeaderConfig(), Header: "Description", DataKey: "Description", Width: 10, isFixed: false, TypeOfData: "text-center-heading", isSearch: true, searchGroup: [1, 2] },
        {...new TableHeaderConfig(), Header: "Confirmation ID", DataKey: "confirmation_ID", Width: 10 },
        {...new TableHeaderConfig(), Header: "Date Printed", DataKey: "FormatedPrintedDate", Width: 10 },
        {...new TableHeaderConfig(), Header: "Size", DataKey: "size", Width: 10, TypeOfData: "Number" },
        {...new TableHeaderConfig(), Header: "Department", DataKey: "department", Width: 10, isSearch: true, searchGroup: [1, 2] },
        {...new TableHeaderConfig(), Header: "Pnt Count", DataKey: "pnt_Count", Width: 10, TypeOfData: "Number" },
        {...new TableHeaderConfig(), Header: "Reg Price", DataKey: "reg_Price", Width: 10, TypeOfData: "Price", Icon : "reg_Price_Keyed" },
        {...new TableHeaderConfig(), Header: "Reg Total", DataKey: "reg_Total", Width: 10, TypeOfData: "Price" },
        {...new TableHeaderConfig(), Header: "Label Type", DataKey: "label_Type", Width: 10, TypeOfData: "text-center", isSearch: true, searchGroup: [1, 2] },
        {...new TableHeaderConfig(), Header: "Reason", DataKey: "reason", Width: 10, isSearch: true, searchGroup: [1, 2] },
        {...new TableHeaderConfig(), Header: "Disc Price", DataKey: "disc_Price", Width: 10, TypeOfData: "Price" },
        {...new TableHeaderConfig(), Header: "Disc Percent", DataKey: "disc_Percent", Width: 10, TypeOfData: "Number" },
        {...new TableHeaderConfig(), Header: "Disc Total", DataKey: "disc_Total", Width: 10, TypeOfData: "Price" },
        {...new TableHeaderConfig(), Header: "Markdown Loss", DataKey: "MarkDownLoss", Width: 10, TypeOfData: "Price" },
        {...new TableHeaderConfig(), Header: "Shrink Scan Time", DataKey: "shrink_Scan_Time", Width: 10 },
        {...new TableHeaderConfig(), Header: "Hours Shrink", DataKey: "hours_Shrink", Width: 10, TypeOfData: "Number" },
        {...new TableHeaderConfig(), Header: "Shrink Confirmation ID", DataKey: "shrink_Confirmation_ID", Width: 200, isSearch: true, searchGroup: [1, 2] },
        {...new TableHeaderConfig(), Header: "Shrink User ID", DataKey: "shrink_User_ID", Width: 10 },
        {...new TableHeaderConfig(), Header: "Department Incoming", DataKey: "department_Incoming", Width: 10, isHeader: false },
        {...new TableHeaderConfig(), Header: "Reclaim", DataKey: "reclaim", Width: 10, TypeOfData: "text-center" },
        {...new TableHeaderConfig(), Header: "Reason Code", DataKey: "reason_Code", Width: 10, TypeOfData: "Number", isHeader: false },
        {...new TableHeaderConfig(), Header: "File Detail UID", DataKey: "file_Detail_UID", Width: 10, isHeader: false }
    ],
    "GroupDataTable": [
        {...new TableHeaderConfig(), Header: "Store ID", DataKey: "store_ID", Width:70, isFixed: true, isSearch: true, searchGroup: [2]},
        {...new TableHeaderConfig(), Header: "Company Store Id" ,TypeOfData: "text-center",Width:70,isFixed: false, DataKey: "store_ID1", isSearch: true, searchGroup: [2]},
        {...new TableHeaderConfig(), Header: "Regular Price Keyed", DataKey: "Badge_Type", Width: 10, isSearch: true, searchGroup: [1, 2] , isHeader: false},
        
    ],
    "GroupDeptDataTable": [
        {...new TableHeaderConfig(), Header: "Store ID", DataKey: "store_ID", isFixed: true, isSearch: true, searchGroup: [2]},
        {...new TableHeaderConfig(), Header: "Company Store Id", DataKey: "store_ID1", isSearch: true, searchGroup: [2]},
        {...new TableHeaderConfig(), Header: "Department", DataKey: "department"},
        {...new TableHeaderConfig(), Header: "Regular Price Keyed", DataKey: "Badge_Type", Width: 10, isSearch: true, searchGroup: [1, 2] , isHeader: false},
        {...new TableHeaderConfig(), Header: "Markdown Loss", DataKey: "markdownLoss", Width: 10, isSearch: true, searchGroup: [1, 2] , isHeader: false},

    ],
}