export const ReportProductMixOptConstants = {
    "MainPage": [
        
        { Header: "Product Name", DataKey: "commodity_IDGeneral", Width: 120, isFixed: true, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Detail Items", DataKey: "itemsCount", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Total Items", DataKey: "items", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

    ],

    "Detail1Page": [
        
        { Header: "Specific Product Name", DataKey: "commodity_IDSpecific", Width: 120, isFixed: true, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        //{ Header: "Total Items", DataKey: "items", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Items", DataKey: "itemsSpecific", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

    ],
    "DetailLastPage": [
        { Header: "UPC", DataKey: "upc", Width: 10, isFixed: true, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Item ID", DataKey: "item_ID", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Status", DataKey: "status", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Description", DataKey: "description", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Size", DataKey: "size", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Pack", DataKey: "pack", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

        { Header: "Placement", DataKey: "placement", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Mvnt Month Last 6 ", DataKey: "movement", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Sold Days Last", DataKey: "days_Last_Movement", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "W/H Ranking", DataKey: "wH_Rank2", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Invoice Days Last", DataKey: "days_last_Invoiced", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Price Type", DataKey: "active_Price_Type", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Block Reason", DataKey: "block_Reason", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Vendor Reason Discontinue", DataKey: "reason_Discontinue", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Sub Item", DataKey: "sub_Item_ID", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Status", DataKey: "status_Sub", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

        //{ Header: "Total Items", DataKey: "items", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        //{ Header: "sub_1_3_Description Items", DataKey: "sub_1_3_Description", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        //{ Header: "sub_4_6_Description Items", DataKey: "sub_4_6_Description", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

    ],

    
}