import TableComponent from "./DetailTable";
import { MainScreenConstants } from "./MainScreenConstant";

const DetailMainScreen = (props: any) => {
  const {
    handleSpinner,
    userContext = {},
    fromDate,
    toDate,
    setSuccess,
    setFailed,
    AllOrderData,
  } = props;

  //const [AllOrderData, setAllOrderData] = useState([]);

  /*   const getOrderingData = async (fromDate, toDate) => {
    const { handleSpinner, userContext } = props;
    handleSpinner && handleSpinner(true);
    const { groupTypeId } = userContext;

    ReportService.GetOrderingReport(fromDate, toDate, groupTypeId, "Totalstore")
      .then((result) => {
        result.storeRpt.map((data) => {
          data.delivery_Date = moment(data.delivery_Date).format("MM-DD-YYYY");
        });
        result.storeRpt.map((data) => {
          if (data.department_Name) {
            data.department_Name = data.department_Name.trim();
          }
        });
        console.log("Ordering Data: Result - ", result);
        setSuccess(result.storeRpt.length);
        setAllOrderData(result.storeRpt);
        //const response = modifyReportData(result.records);
        // console.log("Credits: Report - ", response);
        //setReportList(response);
        //setReportMasterList(response);
        // response.length > 0 && toast.success("Report Loaded Successfully");
      })
      .catch((err) => {
        console.error("Error: Get Orders - ", err);
        // toast.error("Error Loading Orders");
      })
      .finally(() => {
        handleSpinner && handleSpinner(false);
      });
  }; */

  /*   useEffect(() => {
    getOrderingData(
      moment(fromDate).format("YYYY/MM/DD"),
      moment(toDate).format("YYYY/MM/DD")
    );
  }, [fromDate, toDate]);

  useEffect(() => {
    AllOrderData.map(
      (data) =>
        (data.delivery_Date = moment(data.delivery_Date).format("MM-DD-YYYY"))
    );
  }, [AllOrderData]); */
  return (
    <div>
      <TableComponent
        isFromModal={false}
        tableDetails={MainScreenConstants["TotalStore"]}
        reportList={AllOrderData}
        iconColumn={"NA"}
        onTableRowClick={() => {}}
        reportType={""}
        totalTableRow={{}}
      />
    </div>
  );
};

export default DetailMainScreen;
