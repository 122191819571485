import { useEffect, useState, useMemo } from "react";
import {
  Modal,
  Tabs,
  Dropdown,
  Table,
  Button,
  DatePicker,
  ButtonToolbar,
} from "rsuite";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import * as ReportService from "../../../services/reportServices";
import * as SettingsServices from "../../../services/settingsServices";
import PageHeaderComponent from "../../../components/common/pageHeaderComponent";
import Constants from "../../.././utils/constants";
import TableComponent from "../../common/tableComponent";
import { Icon } from "@iconify/react";
import moment from "moment";
import { ReportOOSConstants } from "./ReportOutOfStockConstants";
import * as UMSService from "../../../services/umsService";
import * as AuthService from "../../../services/authService";
import { toast } from "react-toastify";
import { addDays } from "date-fns";

const ReportOutOfStockComp = (props: any) => {
  const [allVendorName, setAllVendorName] = useState([]);
  const [vendorID, setVendorID] = useState(allVendorName[1]);
  const { Cell } = Table;
  const [allOOSData, setAllOOSData] = useState([]);
  const [invoicedData, setInvoicedData] = useState([]);
  const [reasonsData, setReasonsData] = useState([]);
  const [notOrderedData, setNotOrderedData] = useState([]);
  const [orderExtraData, setOrderExtraData] = useState([]);
  const [notSentData, setNotSentData] = useState([]);
  const [fromDate, setFromDate] = useState<Date>(addDays(new Date(), -1));
  const [toDate, setToDate] = useState<Date>(addDays(new Date(), -1));
  // const [fromDateCreate, setFromDateCreate] = useState<Date>(
  //   addDays(new Date(), -1)
  // );
  // const [toDateCreate, setToDateCreate] = useState<Date>(
  //   addDays(new Date(), -1)
  // );
  const [deliveryDate, setDeliveryDate] = useState(null);
  // const [creditsDate, setCreditsDate] = useState(
  //   moment(toDate).format("MM-DD-YYYY")
  // );

  // const [toDate, setToDate] = useState<Date>(new Date());

  const [storeName, setStoreName] = useState([]);
  const [storeNameHeader, setStoreNameHeader] = useState([]);
  const [storeID, setStoreID] = useState([]);
  const [userStore, setUserStore] = useState(storeID[0]);
  const [awgOrGotSelect, setAwgOrGotSelect] = useState(1);
  const [groupTypeID, setGroupTypeID] = useState(null);
  const [selectedReportType, setSelectedReportType] = useState("");
  const [tabsActiveKey, setTabsActiveKey] = useState("All");

  const [selectedReportConstantName, setSelectedReporConstantName] =
    useState(null);

  const [selectedReportTableName, setSelectedReporTableName] = useState([]);
  const [detailData, setDetailData] = useState([]);
  const [detailRowData, setDetailRowData] = useState([]);
  const [data, setData] = useState([]);

  const [isDisplay, setIsDisplay] = useState(false);
  const [isActiveMessage, setIsActiveMessage] = useState(false);

  const [activeCreateOrder, setActiveCreateOrder] = useState([]);
  const [downloadCreateOrder, setDownloadCreateOrder] = useState([]);
  const { userContext = {} } = props;
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpenCreate(false);
    setOpen(false);
  };
  const [openCreate, setOpenCreate] = useState(false);
  const handleCloseCreate = () => {
    setOpenCreate(false);
    setActiveCreateOrder([]);
  };
  const handleCloseMessagePopUp = () => {
    setIsActiveMessage(false);
  };
  const handleCloseMessage = () => {
    setIsDisplay(false);
    setDownloadCreateOrder([]);
  };
  useEffect(() => {
    getReportData(
      userContext && userContext.store ? userContext.store : userStore
    );
  }, [userStore, storeNameHeader, fromDate, toDate, userContext, vendorID]);
  useEffect(() => {
    if (userContext && userContext.roleName === "Super Admin") {
      getVendorStoreName(groupTypeID);
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId !== 0
    ) {
      getVendorStoreName(userContext.groupTypeId);
    } else if (
      userContext &&
      !userContext.store &&
      userContext.groupType &&
      userContext.groupTypeId === 0
    ) {
      getVendorStoreName(groupTypeID);
    } else if (userContext && userContext.store) {
      getVendorStoreName(userContext.store);
    }
  }, [groupTypeID, userContext]);

  const [groupAndGroupID, setGroupAndGroupID] = useState([
    { groupStore_uid: "", groupStore_desc: "" },
  ]);

  const awgOrGot = [
    { groupStore_uid: "1", groupStore_desc: "GOT" },
    { groupStore_uid: "2", groupStore_desc: "AWG" },
  ];
  const getVendorStoreName = async (grouptypeID1 = "") => {
    const { handleSpinner, userContext } = props;
    const { user_ID, store } = userContext;
    var storesArray = [];
    const uniqueVendor = new Set();
    AuthService.getDMStores(user_ID)
      .then((result1) => {
        result1.records.map((item) => storesArray.push(item.stores));
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      });
    if (store) {
      SettingsServices.getVendorStoreName(store, "Store")
        .then((result) => {
          result.records.forEach((item) => {
            const { vendor_ID, vendor_Name } = item;
            uniqueVendor.add(JSON.stringify({ vendor_ID, vendor_Name }));
          });
          const uniqueVendorData = Array.from(uniqueVendor).map((store) =>
            JSON.parse(store as string)
          );
          setAllVendorName(uniqueVendorData);
          setVendorID(uniqueVendorData[0].vendor_ID.toString());
          result.records.sort((a, b) => {
            const Store_A = a.store_ID;
            const Store_B = b.store_ID;

            if (Store_A < Store_B) {
              return -1;
            } else if (Store_A > Store_B) {
              return 1;
            } else {
              return 0;
            }
          });
          if (storesArray.length >= 1) {
            result.records = result.records.filter((item) =>
              storesArray.includes(item.store_ID.toString())
            );
          }
          //console.log("StoreName: Result - ", result.records);
          var setArrayID = [];
          var setArrayName = [];
          result.records.filter((item) => {
            if (!setArrayID.includes(item.store_ID)) {
              setArrayID.push(item.store_ID);
            }
          });
          result.records.filter((item) => {
            if (!setArrayName.includes(item.store_Name)) {
              setArrayName.push(item.store_Name);
            }
          });

          setStoreName(setArrayName);
          setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    } else {
      SettingsServices.getVendorStoreName(grouptypeID1, "")
        .then((result) => {
          result.records.forEach((item) => {
            const { vendor_ID, vendor_Name } = item;
            uniqueVendor.add(JSON.stringify({ vendor_ID, vendor_Name }));
          });
          const uniqueVendorData = Array.from(uniqueVendor).map((store) =>
            JSON.parse(store as string)
          );
          setAllVendorName(uniqueVendorData);
          setVendorID(uniqueVendorData[0].vendor_ID.toString());
          result.records.sort((a, b) => {
            const Store_A = a.store_ID;
            const Store_B = b.store_ID;

            if (Store_A < Store_B) {
              return -1;
            } else if (Store_A > Store_B) {
              return 1;
            } else {
              return 0;
            }
          });
          if (storesArray.length >= 1) {
            result.records = result.records.filter((item) =>
              storesArray.includes(item.store_ID.toString())
            );
          }
          //console.log("StoreName: Result - ", result.records);
          var setArrayID = [];
          var setArrayName = [];
          result.records.filter((item) => {
            if (!setArrayID.includes(item.store_ID)) {
              setArrayID.push(item.store_ID);
            }
          });
          result.records.filter((item) => {
            if (!setArrayName.includes(item.store_Name)) {
              setArrayName.push(item.store_Name);
            }
          });

          setStoreName(setArrayName);
          setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    }
  };

  useEffect(() => {
    if (
      userContext &&
      userContext.groupType === 2 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("2", "StoreType");

    if (
      userContext &&
      userContext.groupType === 1 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("1", "StoreType");

    if (
      userContext &&
      userContext.groupType === 3 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("3", "StoreType");
  }, [userContext]);

  useEffect(() => {
    if (userContext && !userContext.store) {
      SettingsServices.getVendorStoreName(userStore, "Store")
        .then((result) => {
          //console.log("StoreName: Result - ", result.records);
          const uniqueVendor = new Set();
          result.records.forEach((item) => {
            const { vendor_ID, vendor_Name } = item;
            uniqueVendor.add(JSON.stringify({ vendor_ID, vendor_Name }));
          });
          const uniqueVendorData = Array.from(uniqueVendor).map((store) =>
            JSON.parse(store as string)
          );

          uniqueVendorData.sort((a, b) => {
            const Store_A = a.vendor_Name;
            const Store_B = b.vendor_Name;

            if (Store_A < Store_B || Store_A.includes("AWG KC")) {
              return -1;
            } else if (Store_A > Store_B) {
              return 1;
            } else {
              return 0;
            }
          });
          setAllVendorName(uniqueVendorData);
          setVendorID(uniqueVendorData[0].vendor_ID.toString());
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {});
    }
  }, [userStore, userContext]);
  //#region Get Group and Store by Types
  const GetFirstAllStore = async (IDType, operation) => {
    userContext &&
      !userContext.store &&
      UMSService.GetGroupAndStoreByTypes(IDType, operation)
        .then((result) => {
          var setArrayName = [];
          var setArrayID = [];
          if (IDType === "2") {
            result.map((item) => {
              return (item.groupStore_desc =
                item.groupStore_uid + " / " + item.groupStore_desc);
            });
          }
          result.sort((a, b) => {
            const Store_A = a.groupStore_uid;
            const Store_B = b.groupStore_uid;

            if (Store_A < Store_B) {
              return -1;
            } else if (Store_A > Store_B) {
              return 1;
            } else {
              return 0;
            }
          });

          result.filter((item) => {
            if (!setArrayID.includes(item.groupStore_uid)) {
              setArrayID.push(item.groupStore_uid);
            }
          });
          result.filter((item) => {
            if (!setArrayName.includes(item.groupStore_desc)) {
              setArrayName.push(item.groupStore_desc);
            }
          });
          setStoreName(setArrayName);
          setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
        })
        .catch((err) => {
          console.error("Error:  - ", err);
          // toast.error("Error Loading Orders");
        });
  };

  useEffect(() => {
    if (
      userContext &&
      userContext.roleName &&
      userContext.roleName === "Super Admin"
    ) {
      GetGroupAndStoreByTypes(awgOrGotSelect, "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 1
    ) {
      GetGroupAndStoreByTypes("1", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 2
    ) {
      GetGroupAndStoreByTypes("2", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId &&
      userContext.groupType === "" &&
      userContext.store !== 0
    ) {
      getStoreName(userContext.store);
    }
  }, [props.userContext, awgOrGotSelect]);

  const GetGroupAndStoreByTypes = async (IDType, operation) => {
    UMSService.GetGroupAndStoreByTypes(IDType, operation)
      .then((result) => {
        if (IDType === "2") {
          result.map((item) => {
            return (item.groupStore_desc =
              item.groupStore_uid + " / " + item.groupStore_desc);
          });
        }
        result.sort((a, b) => {
          const Store_A = a.groupStore_desc.toUpperCase();
          const Store_B = b.groupStore_desc.toUpperCase();

          if (Store_A < Store_B) {
            return -1;
          } else if (Store_A > Store_B) {
            return 1;
          } else {
            return 0;
          }
        });
        setGroupAndGroupID(result);
      })
      .catch((err) => {
        console.error("Error:  - ", err);
        // toast.error("Error Loading Orders");
      });
  };

  const getReportData = async (storeID) => {
    const { handleSpinner, userContext = {} } = props;
    const { store } = userContext;

    const changeZeroToString = (array) => {
      for (var i = 0; i < array.length; i++) {
        var item = array[i];

        for (var key in item) {
          if (item[key] === 0) {
            item[key] = "";
          }
        }
      }
    };
    if (store !== 0) {
      handleSpinner && handleSpinner(true);
      ReportService.getOutOfStock(
        vendorID,
        storeID,
        moment(fromDate).format("MM-DD-YYYY"),
        moment(toDate).format("MM-DD-YYYY")
      )
        .then((result) => {
          ReportOOSConstants["GeneralConstants"].map((item) => {
            if (item.addDate && item.addDate === true) {
              item.Header = `${item.Header} ${moment(fromDate).format(
                "MM-DD"
              )}`;
            }
          });
          setAllOOSData(result.allRecords);
          setData(result.allRecords);
          setSelectedReporTableName(result.allRecords);
          setInvoicedData(result.invoicedRecords);
          setReasonsData(result.reasonsRecords);
          setNotOrderedData(result.notOrderedRecords);
          setOrderExtraData(result.orderExtraRecords);
          setNotSentData(result.notSentRecords);
          result && setSelectedReportType("All");
        })
        .catch((err) => {
          console.error("Error: Get Orders - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    } else {
      handleSpinner && handleSpinner(true);
      ReportService.getOutOfStock(
        vendorID,
        storeID,
        moment(fromDate).format("MM-DD-YYYY"),
        moment(toDate).format("MM-DD-YYYY")
      )
        .then((result) => {
          changeZeroToString(result.allRecords);
          changeZeroToString(result.invoicedRecords);
          changeZeroToString(result.reasonsRecords);
          changeZeroToString(result.notOrderedRecords);
          changeZeroToString(result.orderExtraRecords);
          changeZeroToString(result.notSentRecords);
          setAllOOSData(result.allRecords);
          setData(result.allRecords);
          // getReasonHeaders(result.allRecords);
          setInvoicedData(result.invoicedRecords);
          setReasonsData(result.reasonsRecords);
          setNotOrderedData(result.notOrderedRecords);
          setOrderExtraData(result.orderExtraRecords);
          setNotSentData(result.notSentRecords);
          setTabsActiveKey("All");
        })
        .catch((err) => {
          console.error("Error: Get Orders - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    }
  };

  const getHeader = (value) => {
    ReportOOSConstants[value].map((item) => {
      if (item.DataKey.includes("user_Ord_Adj_Qty")) {
        item.Header = `Order ${moment(deliveryDate).format("MM/DD")}`;
      }
      if (item.DataKey.includes("qty_Loaded")) {
        item.Header = `Ord on ${moment(toDate).format("MM/DD")}`;
      }

      if (item.DataKey.includes("sent_ORD_Qty")) {
        item.Header = `Sent on ${moment(toDate).format("MM/DD")}`;
      }

      if (item.DataKey.includes("inv_Qty")) {
        item.Header = `Inv on ${moment(toDate).format("MM/DD")}`;
      }

      if (item.DataKey.includes("qty_Updated_1")) {
        item.Header = `Ord on ${moment(addDays(fromDate, 0)).format("MM/DD")}`;
      }
      if (item.DataKey.includes("qty_Updated_2")) {
        item.Header = `Ord on ${moment(addDays(fromDate, 1)).format("MM/DD")}`;
      }
      if (item.DataKey.includes("qty_Updated_3")) {
        item.Header = `Ord on ${moment(addDays(fromDate, 2)).format("MM/DD")}`;
      }
      if (item.DataKey.includes("qty_Updated_4")) {
        item.Header = `Ord on ${moment(addDays(fromDate, 3)).format("MM/DD")}`;
      }
    });
    return ReportOOSConstants[value];
  };

  useEffect(() => {
    getDeliverySchedule(
      vendorID,
      userContext && userContext.store ? userContext.store : userStore
    );
  }, [userContext, vendorID]);

  const getDeliverySchedule = (vendorID, storeID) => {
    ReportService.getOutOfStockDeliverySchedule(vendorID, storeID)
      .then((result) => {
        result && setDeliveryDate(result.allRecords[0].closest_Delivery_Day);
      })
      .catch((err) => {
        console.error("Error: Get Orders - ", err);
        // toast.error("Error Loading Orders");
      })
      .finally(() => {});
  };
  //   const modifyReportData = (data) => {
  //     let modifiedData = data.map((item) => {
  //       item["dateTime_In"] =
  //         item["dateTime_In"] && moment(item["dateTime_In"]).format("MM-DD-YYYY");
  //       item["invoice_Date"] =
  //         item["invoice_Date"] &&
  //         moment(item["invoice_Date"]).format("MM-DD-YYYY");
  //       item["invoice_Date_Next"] =
  //         item["invoice_Date_Next"] &&
  //         moment(item["invoice_Date_Next"]).format("MM-DD-YYYY");
  //       item["delivery_Date"] =
  //         item["delivery_Date"] &&
  //         moment(item["delivery_Date"]).format("MM-DD-YYYY");

  //       item["rec_Date"] =
  //         item["rec_Date"] && moment(item["rec_Date"]).format("MM-DD-YYYY");

  //       return item;
  //     });
  //     return modifiedData;
  //   };

  //#region Excel Export
  const exportToExcel = (excelData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: Constants.fileType });
    FileSaver.saveAs(data, fileName + Constants.fileExtension);
  };
  //#endregion

  //#region Filters Drawer

  //#endregion

  useEffect(() => {
    if (userContext && userContext.store && userContext.store !== 0) {
      getStoreName(userContext.store);
    }
  }, [userContext]);

  const getStoreName = (storeID) => {
    SettingsServices.GetAdGroupandGroupID(storeID)
      .then((result) => {
        setStoreNameHeader(result.records[0].description);
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      });
  };

  //   const filterList = (value) => {
  //     if (value) {
  //       setSelectedReporTableName(
  //         selectedReportTableName.filter((item) =>
  //           item.description
  //             .toLowerCase()
  //             .includes(value.toString().toLowerCase())
  //         )
  //       );
  //     } else {
  //       selectedData(selectedReportType);
  //     }
  //   };

  const onOptionChangeVendor = (event) => {
    const VendorAndStore = event.toString();
    setVendorID(VendorAndStore);
    //setShowAllData(true);
  };
  useEffect(() => {
    selectedData(selectedReportType);
  }, [selectedReportType, userStore, allOOSData]);

  useEffect(() => {
    setSelectedReporConstantName(getHeader("GeneralConstants"));
  }, [fromDate, toDate]);

  const selectedData = (selectedTab) => {
    if (!selectedTab) {
      setSelectedReporConstantName(getHeader("GeneralConstants"));
      setTabsActiveKey("All");
      setSelectedReporTableName(allOOSData);
    } else {
      switch (selectedTab) {
        case "All":
          setSelectedReporTableName(allOOSData);
          setSelectedReporConstantName(getHeader("GeneralConstants"));
          break;
        case "Invoiced":
          setSelectedReporTableName(invoicedData);
          setSelectedReporConstantName(getHeader("GeneralConstants"));
          break;
        case "Reasons":
          setSelectedReporTableName(reasonsData);
          setSelectedReporConstantName(getHeader("ReasonConstants"));
          break;
        case "NotOrdered":
          setSelectedReporTableName(notOrderedData);
          setSelectedReporConstantName(getHeader("GeneralConstants"));
          break;
        case "OrderExtra":
          setSelectedReporTableName(orderExtraData);
          setSelectedReporConstantName(getHeader("GeneralConstants"));
          break;
        case "NotSent":
          setSelectedReporTableName(notSentData);
          setSelectedReporConstantName(getHeader("GeneralConstants"));
          break;
      }
    }
  };

  const getDetailRecord = (data) => {
    setOpenCreate(false);
    const changeZeroToString = (array) => {
      for (var i = 0; i < array.length; i++) {
        var item = array[i];

        for (var key in item) {
          if (item[key] === 0 || item[key] === 0.0) {
            item[key] = "";
          }
        }
      }
    };
    setDetailRowData(data);
    data &&
      ReportService.getOutOfStockDetail(
        vendorID,
        userStore,
        moment(fromDate).format("MM-DD-YYYY"),
        data.department_Store_ID,
        data.item_ID,
        data.upc.toString(),
        data.pack1,
        moment(toDate).format("MM-DD-YYYY")

        // asasdasdadas
        // 70038000,
        // 9999,
        // moment(fromDate).format("MM-DD-YYYY"),
        // 3200,
        // 500081,
        // "7003861275",
        // 6,
        // moment(toDate).format("MM-DD-YYYY")
        // testtt
        // 70038000,
        // 9999,
        // moment(fromDate).format("MM-DD-YYYY"),
        // data.department_Store_ID,
        // data.item_ID,
        // data.upc.toString(),
        // data.pack1,
        // moment(toDate).format("MM-DD-YYYY")
      )
        .then((result) => {
          result.allRecords.map(
            (item) =>
              (item.delivery_Date = moment(item.delivery_Date).format(
                "MM-DD-YYYY"
              ))
          );

          changeZeroToString(result.allRecords);
          setDetailData(result.allRecords);
          result && setOpen(true);
        })
        .catch((err) => {
          console.error("Error: Get Orders - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {});
  };
  const getCreateData = (value1) => {
    // Create Order Side
    if (value1 && Number(value1.user_Ord_Adj_Qty) !== 0) {
      const newValue = {
        Department_Store_ID: value1.department_Store_ID,
        Store_ID: userStore,
        // Store_ID: 9999,
        Vendor_ID: vendorID,
        // Vendor_ID: 70038000,
        User_ID: userContext && userContext.user_ID,
        UPC: value1.upc ? value1.upc : 0,
        Item_ID: value1.item_ID ? value1.item_ID : 0,
        Status: "0",
        Quantity: Number(value1.user_Ord_Adj_Qty),
        // Delivery_Date: moment(fromDateCreate).format("YYYY-MM-DD"), //from Date
        // Delivery_Date: moment(fromDate).format("YYYY-MM-DD"), //from Date
        Delivery_Date: moment(deliveryDate).format("YYYY-MM-DD"), //from Date
        Update_Date: addDays(new Date(), 0),
        Record_Type: 2,
        Module_ID: 24,
      };
      ReportService.InsertOutofStockOrder(Array(newValue))
        .then((result) => {
          // toast.success(
          //   `${Number(value1.user_Ord_Adj_Qty)} Orders Created Successfully`
          // );
        })
        .catch((err) => {
          // toast.error("Error Loading Orders");
          toast.error("Error Create Orders look UPC or Item ID column");
        })
        .finally(() => {});
    }
    // Create Credits Side
    if (
      value1 &&
      Number(value1.user_Credit_Adj_Qty) !== 0 &&
      value1.credit_Qty !== ""
    ) {
      const newValue = {
        Department_Store_ID: value1.department_Store_ID,
        Store_ID: userStore,
        //Store_ID: 9999,
        Vendor_ID: vendorID,
        // Vendor_ID: 70038000,
        User_ID: userContext && userContext.user_ID,
        UPC: value1.upc,
        Item_ID: value1.item_ID,
        Status: "0",
        Quantity: Number(value1.user_Credit_Adj_Qty),
        // Delivery_Date: moment(toDateCreate).format("YYYY-MM-DD"), //invoice Date
        Delivery_Date: moment(toDate).format("YYYY-MM-DD"), //invoice Date
        Update_Date: addDays(new Date(), 0),
        Record_Type: 6,
        Module_ID: 24,
      };
      ReportService.InsertOutofStockOrder(Array(newValue))
        .then((result) => {
          // toast.success(
          //   `${Number(value1.user_Credit_Adj_Qty)} Credits Created Successfully`
          // );
        })
        .catch((err) => {
          toast.error("Error Create Credits look UPC or Item ID column");
        })
        .finally(() => {});
    }
  };
  const EditableCell = ({ rowData, dataKey, onChange, ...props }) => {
    //const editing = useMemo(() => rowData.status === "EDIT", [rowData.status]);
    const editing =
      rowData["credit_Qty"] === "" && dataKey === "user_Credit_Adj_Qty"
        ? false
        : selectedReportType === "All"
        ? rowData["credit_Qty"] === "" && dataKey === "user_Credit_Adj_Qty"
          ? true
          : dataKey === "user_Ord_Adj_Qty"
          ? true
          : false
        : true;

    const inputElement = useMemo(() => {
      return (
        <input
          //onKeyUp={(e) => e.preventDefault()}
          type="text"
          style={{ width: "40px", textAlign: "start" }}
          value={rowData[dataKey]}
          //max={rowData["credit_Qty"]}
          className=" py-1 text-center outline-none"
          onChange={(event) => {
            onChange && onChange(rowData.uid, dataKey, event.target.value);
          }}
        />
      );
    }, [rowData, dataKey, onChange]);
    return (
      <Cell {...props} className={editing ? "table-content-editing" : ""}>
        {editing && inputElement}
      </Cell>
    );
  };

  const handleChange = (id, key, value) => {
    value = value ? value : "0";
    var nextData = Object.assign([], selectedReportTableName);
    var activeItem = Array(nextData.find((item) => item.uid === id));
    nextData.find((item) => item.uid === id)[key] = value;
    nextData.map((item) => {
      if (item.credit_Qty === "") return (item.user_Credit_Adj_Qty = "");
    });

    activeItem = activeItem.filter(
      (item) => item.user_Credit_Adj_Qty !== "" || item.user_Ord_Adj_Qty !== ""
    );
    if (
      nextData[id].credit_Qty === "" &&
      nextData[id].user_Credit_Adj_Qty === ""
    ) {
      function updateOrAddObjectByUID(array, newObj) {
        if (newObj.user_Ord_Adj_Qty === "0" || newObj.user_Ord_Adj_Qty === "") {
          newObj.user_Ord_Adj_Qty = "";
        }

        if (
          newObj.user_Credit_Adj_Qty === "0" ||
          newObj.user_Credit_Adj_Qty === ""
        ) {
          newObj.user_Credit_Adj_Qty = "";
        }
        // UID'ye göre mevcut objeyi bul
        const existingIndex = array.findIndex((obj) => obj.uid === newObj.uid);

        // Eğer mevcut obje bulunursa, onu güncelle
        if (existingIndex !== -1) {
          array[existingIndex] = newObj;
        } else {
          // Eğer mevcut obje bulunmazsa, yeni objeyi ekle
          array.push(newObj);
        }

        return array;
      }
      //const combined = [...activeCreateOrder, ...activeItem];
      const combined = updateOrAddObjectByUID(activeCreateOrder, activeItem[0]);
      // combined.filter((item) => {
      //   return (
      //     !(item.credit_Qty === "" && item.user_Credit_Adj_Qty) &&
      //     item.user_Ord_Adj_Qty !== ""
      //   );
      // });
      setDownloadCreateOrder(combined);
      setActiveCreateOrder(combined);
    }

    setData(nextData);
  };
  // const handleEditState = (id, status) => {
  //   console.log(status);
  //   const nextData = Object.assign([], data);

  //   const activeItem = Array(nextData.find((item) => item.uid === id));
  //   activeItem[0].status = activeItem[0].status ? null : "EDIT";
  //   if (status === "Save") {
  //     console.log(activeItem);
  //     const combined = [...activeCreateOrder, ...activeItem];
  //     setActiveCreateOrder(combined);
  //   }

  //   // getCreateData(
  //   //   activeItem &&
  //   //     (Number(activeItem.user_Ord_Adj_Qty) >= 1 ||
  //   //       Number(activeItem.user_Credit_Adj_Qty) >= 1)
  //   //     ? activeCreateOrder
  //   //     : null
  //   // );
  //   setData(nextData);
  // };

  const sendData = (value) => {
    //setIsDisplay(true);

    for (let index = 0; index < value.length; index++) {
      const element = value[index];
      getCreateData(
        element &&
          (Number(element.user_Ord_Adj_Qty) >= 1 ||
            Number(element.user_Credit_Adj_Qty) >= 1)
          ? element
          : null
      );
    }
    setActiveCreateOrder([]);
    setOpenCreate(false);
    setIsActiveMessage(false);
  };

  const sendAllProcess = () => {
    sendData(activeCreateOrder);
    getReportData(
      userContext && userContext.store ? userContext.store : userStore
    );
  };

  const quickOrder = () => {
    if (selectedReportTableName.length >= 1) {
      var quickData = selectedReportTableName.map((item) => {
        item.user_Ord_Adj_Qty = "1";
        return item;
      });
      setSelectedReporTableName(quickData);

      const newActiveOrders = [];

      for (let index = 0; index < selectedReportTableName.length; index++) {
        if (selectedReportTableName[index]["user_Ord_Adj_Qty"] === "1") {
          newActiveOrders.push(selectedReportTableName[index]);
        }
      }

      setActiveCreateOrder([...activeCreateOrder, ...newActiveOrders]);
    }
  };

  return (
    <>
      <div>
        <div>
          <div className="d-flex flex-wrap justify-content-start align-items-center ">
            <>
              <PageHeaderComponent
                setGroupTypeID={setGroupTypeID}
                setAwgOrGotSelect={setAwgOrGotSelect}
                setUserStore={setUserStore}
                userContext={userContext}
                groupAndGroupID={groupAndGroupID}
                awgOrGot={awgOrGot}
                storeName={storeName}
                storeNameHeader={storeNameHeader}
                storeID={storeID}
                fromDate={fromDate}
                toDate={toDate}
                setFromDate={setFromDate}
                setToDate={setToDate}
                isVendor={true}
                allVendorName={allVendorName}
                onOptionChangeVendor={onOptionChangeVendor}
                isCalendar={false}
              />
              <div className="calendar-container">
                <div style={{ maxWidth: "250px", marginLeft: "10px" }}>
                  <DatePicker
                    label="OOS Date:"
                    oneTap
                    value={fromDate ? new Date(fromDate) : new Date()}
                    onChange={(value) => {
                      setFromDate(value);
                    }}
                  />
                </div>
              </div>
              <div className="calendar-container">
                <div>
                  <DatePicker
                    style={{ maxWidth: "250px", marginLeft: "10px" }}
                    appearance="default"
                    label="Invoice Date:"
                    oneTap
                    value={toDate ? new Date(toDate) : new Date()}
                    onChange={(value) => {
                      setToDate(value);
                    }}
                  />
                </div>
              </div>

              <Modal
                size={"400px"}
                open={isActiveMessage}
                onClose={handleCloseMessagePopUp}
              >
                <p className="text-center">
                  Do you want to Save Adjustments and Send?
                </p>
                <hr />
                <ButtonToolbar
                  className="d-flex justify-content-evenly"
                  justified
                >
                  <Button
                    onClick={() => setIsActiveMessage(false)}
                    appearance="primary"
                    color="red"
                  >
                    No
                  </Button>
                  <Button
                    appearance="primary"
                    color="green"
                    onClick={() => sendAllProcess()}
                  >
                    Yes
                  </Button>
                </ButtonToolbar>
              </Modal>

              {/* Finish Create page after this modal show records. */}

              {/* <Modal size={"sm"} open={isDisplay} onClose={handleCloseMessage}>
                <Modal.Body>
                  <Message
                    style={{ textAlign: "center" }}
                    type="info"
                    header="Do you want to download excel files for orders / credits ?"
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Button
                        appearance="primary"
                        color="red"
                        onClick={() => {
                          setIsDisplay(false);
                        }}
                        size="lg"
                      >
                        No
                      </Button>
                      <Button
                        appearance="primary"
                        color="green"
                        onClick={() => {
                          exportToExcel(
                            downloadCreateOrder,
                            `Created Stock  -  Store: ${
                              userContext && userContext.store
                                ? userContext.store
                                : userStore
                            }`
                          );
                        }}
                        size="lg"
                      >
                        Yes
                      </Button>
                    </div>
                  </Message>
                </Modal.Body>
              </Modal> */}
            </>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-2">
            <div
              style={{
                fontWeight: "600",
              }}
            >
              <Tabs
                style={{ maxWidth: "50" }}
                defaultActiveKey={tabsActiveKey}
                onSelect={(e) => setSelectedReportType(e)}
              >
                <Tabs.Tab eventKey="All" title="All"></Tabs.Tab>
                <Tabs.Tab eventKey="Invoiced" title="Invoiced"></Tabs.Tab>
                <Tabs.Tab eventKey="Reasons" title="Reasons"></Tabs.Tab>
                <Tabs.Tab eventKey="NotOrdered" title="Not Ordered"></Tabs.Tab>
                <Tabs.Tab eventKey="OrderExtra" title="Order Extra"></Tabs.Tab>
                <Tabs.Tab eventKey="NotSent" title="Not Sent"></Tabs.Tab>
              </Tabs>
            </div>
            <Button
              style={{ marginLeft: "10px", marginBottom: "10px" }}
              // onClick={() => setOpenCreate(true)}
              onClick={() => quickOrder()}
              color="green"
              appearance="primary"
            >
              Quick Order
            </Button>
            {activeCreateOrder.length >= 1 ? (
              <>
                <Button
                  style={{ marginLeft: "10px", marginBottom: "10px" }}
                  // onClick={() => setOpenCreate(true)}
                  onClick={() =>
                    activeCreateOrder.length >= 1 && setIsActiveMessage(true)
                  }
                  appearance="primary"
                >
                  Save Adjustments and Send
                </Button>
              </>
            ) : (
              <div style={{ width: "220px" }}></div>
            )}

            <Dropdown
              renderToggle={(_props, _ref) => (
                <div className="filter" ref={_ref} {..._props}>
                  <Icon icon="pajamas:export" /> Export
                </div>
              )}
              className="ms-2 me-0 button-fixed"
              appearance="link"
              placement="bottomEnd"
            >
              <Dropdown.Item
                icon={<FileDownloadIcon />}
                onClick={() => {
                  exportToExcel(
                    selectedReportTableName,
                    `Out Of Stock Report ${selectedReportType} Store: ${
                      userContext && userContext.store
                        ? userContext.store
                        : userStore
                    }`
                  );
                }}
              >
                Export to Excel
              </Dropdown.Item>
            </Dropdown>
          </div>

          <TableComponent
            isFromModal={false}
            tableDetails={selectedReportConstantName}
            reportList={selectedReportTableName}
            iconColumn={"description"}
            onTableRowClick={(e) => {}}
            EditableCell={EditableCell}
            handleChange={handleChange}
            onTableRowClickEye={(e) => {
              setOpenCreate(false);
              getDetailRecord(e);
            }}
            setOpenCreate={setOpenCreate}
            reportType={""}
            totalTableRow={{}}
            isTotalRowRequired={false}
          />
        </div>

        <Modal size={"calc(100% - 120px"} open={open} onClose={handleClose}>
          <Modal.Header>
            <Modal.Title>
              {" "}
              <div>
                <span
                  className="mx-2 filter"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <Icon icon="ep:back" />
                </span>
                <div style={{ textAlign: "center" }}>Detail Out Of Stock</div>
              </div>
              <div className="bg-container row m-0 mt-3 p-3">
                <div className="col-3 p-2 reportDescItem">
                  <>
                    <div className="icon">
                      <Icon icon="material-symbols:store-outline" />
                    </div>
                    <div className="label">
                      <p className="m-0">Description</p>
                      <p className="m-1">
                        {detailRowData && detailRowData["description"]}
                      </p>
                    </div>
                  </>
                </div>
                <div className="col-3 p-2 reportDescItem">
                  <>
                    <div className="icon">
                      <Icon icon="material-symbols:store-outline" />
                    </div>
                    <div className="label">
                      <p className="m-0">Department</p>
                      <p className="m-1">
                        {detailRowData &&
                          detailRowData["department_Store_Name"]}
                      </p>
                    </div>
                  </>
                </div>

                <div className="col-2 p-2 reportDescItem">
                  <>
                    <div className="icon">
                      <Icon icon="material-symbols:store-outline" />
                    </div>
                    <div className="label">
                      <p className="m-0">Item ID</p>
                      <p className="m-1">
                        {detailRowData && detailRowData["item_ID"]}
                      </p>
                    </div>
                  </>
                </div>
                <div className="col-2 p-2 reportDescItem">
                  <>
                    <div className="icon">
                      <Icon icon="material-symbols:store-outline" />
                    </div>
                    <div className="label">
                      <p className="m-0">UPC</p>
                      <p className="m-1">
                        {detailRowData && detailRowData["upc"]}
                      </p>
                    </div>
                  </>
                </div>
                <div className="col-2 p-2 reportDescItem">
                  <>
                    <div className="icon">
                      <Icon icon="material-symbols:store-outline" />
                    </div>
                    <div className="label">
                      <p className="m-0">Pack</p>
                      <p className="m-1">
                        {detailRowData && detailRowData["pack"]}
                      </p>
                    </div>
                  </>
                </div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TableComponent
              isFromModal={true}
              tableDetails={ReportOOSConstants["DetailConstants"]}
              reportList={detailData}
              iconColumn={""}
              onTableRowClick={() => {}}
              reportType={""}
              totalTableRow={{}}
              isTotalRowRequired={false}
            />
          </Modal.Body>
        </Modal>

        {/* Create Order Side */}
        {/* 
        <Modal
          size={"calc(100% - 120px"}
          open={openCreate}
          onClose={handleCloseCreate}
        >
          <Modal.Header>
            <Modal.Title>
              {" "}
              <div style={{ textAlign: "center" }}>
                <Modal.Title>Create Order / Credit</Modal.Title>
              </div>
              <div className="d-flex flex-row justify-content-evenly flex-nowrap align-items-center mt-5">
                {activeCreateOrder.length !== 0 && (
                  <div>
                    <Button
                      // onClick={() => {
                      //   sendData(activeCreateOrder);
                      // }}
                      style={{ textAlign: "center", height: "36px" }}
                      appearance="primary"
                    >
                      Send Order
                    </Button>
                  </div>
                )}
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table rowHeight={60} virtualized data={data} autoHeight={true}>
              <Column width={200}>
                <HeaderCell style={{ textAlign: "center" }}>
                  Description
                </HeaderCell>
                <Cell
                  style={{ textAlign: "center" }}
                  rowData={data}
                  dataKey="description"
                  //onChange={handleChange}
                />
              </Column>
              <Column width={60}>
                <HeaderCell style={{ textAlign: "center" }}>Size</HeaderCell>
                <Cell
                  style={{ textAlign: "center" }}
                  rowData={data}
                  dataKey="size"
                  //onChange={handleChange}
                />
              </Column>
              <Column width={130}>
                <HeaderCell style={{ textAlign: "center" }}>
                  {`Order on ${moment(fromDate).format("MM-DD")}`}
                </HeaderCell>
                <Cell
                  style={{ textAlign: "center" }}
                  rowData={data}
                  dataKey="qty_Updated_1"
                  //onChange={handleChange}
                />
              </Column>
              <Column width={130}>
                <HeaderCell style={{ textAlign: "center" }}>
                  {`Order on ${moment(addDays(fromDate, 1)).format("MM-DD")}`}
                </HeaderCell>
                <Cell
                  style={{ textAlign: "center" }}
                  rowData={data}
                  dataKey="qty_Updated_2"
                  //onChange={handleChange}
                />
              </Column>
              <Column width={130}>
                <HeaderCell style={{ textAlign: "center" }}>
                  {`Order on ${moment(addDays(fromDate, 2)).format("MM-DD")}`}
                </HeaderCell>
                <Cell
                  style={{ textAlign: "center" }}
                  rowData={data}
                  dataKey="qty_Updated_3"
                  //onChange={handleChange}
                />
              </Column>
              <Column width={130}>
                <HeaderCell style={{ textAlign: "center" }}>
                  {`Order on ${moment(addDays(fromDate, 3)).format("MM-DD")}`}
                </HeaderCell>
                <Cell
                  style={{ textAlign: "center" }}
                  rowData={data}
                  dataKey="qty_Updated_4"
                  //onChange={handleChange}
                />
              </Column>
              <Column width={150}>
                <HeaderCell style={{ textAlign: "center" }}>
                  Order Quantity
                </HeaderCell>
                <EditableCell
                  style={{ textAlign: "center" }}
                  rowData={data}
                  dataKey="user_Ord_Adj_Qty"
                  onChange={handleChange}
                />
              </Column>

              <Column width={150}>
                <HeaderCell style={{ textAlign: "center" }}>
                  Credit Quantity
                </HeaderCell>
                <Cell
                  style={{ textAlign: "center" }}
                  rowData={data}
                  dataKey="credit_Qty"
                  //onChange={handleChange}
                />
              </Column>

              <Column width={150}>
                <HeaderCell style={{ textAlign: "center" }}>
                  Create Credit Quantity
                </HeaderCell>
                <EditableCell
                  style={{ textAlign: "center" }}
                  rowData={data}
                  dataKey="user_Credit_Adj_Qty"
                  onChange={handleChange}
                />
              </Column>

              {/* <Column width={200}>
                  <HeaderCell>Order Date Quantity</HeaderCell>
                  <EditableCell
                    rowData={data}
                    dataKey="qty_Updated_1"
                    onChange={handleChange}
                  />
                </Column> */}
        {/* <Column width={200}>
                  <HeaderCell>Order Date +1 Quantity</HeaderCell>
                  <EditableCell
                    rowData={data}
                    dataKey="qty_Updated_2"
                    onChange={handleChange}
                  />
                </Column>
                <Column width={200}>
                  <HeaderCell>Order Date +2 Quantity</HeaderCell>
                  <EditableCell
                    rowData={data}
                    dataKey="qty_Updated_3"
                    onChange={handleChange}
                  />
                </Column>
                <Column width={200}>
                  <HeaderCell>Order Date +3 Quantity</HeaderCell>
                  <EditableCell
                    rowData={data}
                    dataKey="qty_Updated_4"
                    onChange={handleChange}
                  />
                </Column> */}
        {/* <Column flexGrow={1}>
                <HeaderCell>...</HeaderCell>
                <ActionCell
                  rowData={data}
                  dataKey="uid"
                  onClick={handleEditState}
                />
              </Column> */}
        {/* </Table>
          </Modal.Body>
        </Modal>  */}
      </div>
    </>
  );
};

export default ReportOutOfStockComp;
