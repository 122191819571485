/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useMemo } from "react";
import { Modal, Table, Button, ButtonToolbar } from "rsuite";
import * as ReportService from "../../../../services/reportServices";
import * as SettingsService from "../../../../services/settingsServices";

import { ReportLDTSConstants } from "../ReportLastDayToSaveConstants";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import moment from "moment";
import LastDayToSaveTable from "./ItemDetailTable";
import { addDays } from "date-fns";
import LastDayToSaveItemDetailAMP from "../ItemDetailAMP/LastDayToSaveItemDetailAmp";
import { Icon } from "@iconify/react";
const LastDayToSaveItemDetail = (props: any) => {
  const {
    userContext,
    groupTypeID,
    vendorID,
    fromDate,
    itemDetailRow,
    isDetail,
    setIsDetail,
    setOpenDetail,
    openDetail,
  } = props;
  const { Cell } = Table;
  const [isActiveMessage, setIsActiveMessage] = useState(false);

  const { item_ID, upc, pack1, department_Store_ID } = itemDetailRow;
  const [itemAmpDetailRow, setItemAmpDetailRow] = useState({});
  const [openDetailAmp, setOpenDetailAmp] = useState(false);
  const [isDetailAmp, setIsDetailAmp] = useState(false);
  // const { chosenGroup, chosenFromDate } = useSelector(
  //   (state: RootState) => state?.filterSelection
  // );
  const [itemDetailData, setItemDetailData] = useState([]);
  const [deliveryDay, setDeliveryDay] = useState([]);
  useEffect(() => {
    GetItemDetailData();
  }, [itemDetailRow, groupTypeID, vendorID, fromDate, isDetail, openDetailAmp]);
  const handleClose = () => {
    setOpenDetail(false);
    setIsDetail(false);
  };

  const handleCloseMessagePopUp = () => {
    setIsActiveMessage(false);
  };

  useEffect(() => {
    GetDeliveryScheduleDay(vendorID, itemAmpDetailRow["store_ID"]);
  }, [vendorID, itemAmpDetailRow]);
  const GetDeliveryScheduleDay = async (VendorID, StoreID) => {
    const { handleSpinner, userContext = {} } = props;
    const { store } = userContext;

    handleSpinner && handleSpinner(true);
    SettingsService.GetDeliveryScheduleWeekDay(VendorID, StoreID)
      .then((result) => {
        const targetDays = result?.records.map((item) => item.order_day);

        setDeliveryDay(targetDays);
      })
      .catch((err) => {
        console.error("Error: Get Orders - ", err);
        // toast.error("Error Loading Orders");
      })
      .finally(() => {
        handleSpinner && handleSpinner(false);
      });
  };
  const GetItemDetailData = async () => {
    const { handleSpinner, userContext = {} } = props;
    const { store } = userContext;

    handleSpinner && handleSpinner(true);
    ReportService.GetItemDetailLastDayToSave(
      vendorID,
      groupTypeID,
      moment(addDays(fromDate, -7)).format("YYYY-MM-DD"),
      department_Store_ID,
      item_ID,
      upc,
      pack1
    )
      .then((result) => {
        modifyItemDetail(result.records);
        getHeader("LastDayToSaveDetail");

        setItemDetailData(result.records);
        setOpenDetail(true);
      })
      .catch((err) => {
        console.error("Error: Get Orders - ", err);
        // toast.error("Error Loading Orders");
      })
      .finally(() => {
        handleSpinner && handleSpinner(false);
      });
  };
  const modifyItemDetail = (data) => {
    data.map((item) => {
      item.ad_Future === 0 ? (item.ad_Future = "") : item.ad_Future;
      item.poS_MVT === 0 ? (item.poS_MVT = "") : item.poS_MVT;
      item.will_Hold === 0 ? (item.will_Hold = "") : item.will_Hold;
      item.total_Qty_Back_0 === 0
        ? (item.total_Qty_Back_0 = "")
        : item.total_Qty_Back_0;
      item.onHand_Supply_Days === 0
        ? (item.onHand_Supply_Days = "")
        : item.onHand_Supply_Days;

      for (let i = 0; i <= 7; i++) {
        const key = `ordeR_Qty_${i}`;
        if (item[key] === 0) {
          item[key] = "";
        }
      }

      for (let i = 0; i <= 7; i++) {
        const key = `total_Qty_${i}`;
        if (item[key] === 0) {
          item[key] = "";
        }
      }

      for (let i = 1; i <= 7; i++) {
        const key = `total_Qty_Back_${i}`;
        if (item[key] === 0) {
          item[key] = "";
        }
      }
      item;

      return item;
    });
  };
  const getHeader = (value) => {
    const parsedDate = new Date(fromDate);

    for (let i = 0; i <= 7; i++) {
      const currentDate = addDays(parsedDate, -i);

      ReportLDTSConstants[value].forEach((item) => {
        if (item.DataKey.includes(`total_Qty_${7 - i}`)) {
          const formattedDate = moment(currentDate).format("MMMM D ddd");

          item.Header = `${formattedDate}`;
        }
      });
    }

    // for (let i = 0; i <= 8; i++) {
    //   ReportLDTSConstants[value].forEach((item) => {
    //     if (item.DataKey.includes(`c_B_${i}`)) {
    //       item.Header = moment(addDays(parsedDate, -15 + i)).format(
    //         "MMMM D ddd"
    //       );
    //     }
    //   });
    // }
    for (let i = 1; i <= 8; i++) {
      ReportLDTSConstants[value].forEach((item) => {
        if (item.DataKey.includes(`total_Qty_Back_${8 - i}`)) {
          const adjustedDateHeader = addDays(parsedDate, -15 + i);
          const adjustedDate = addDays(parsedDate, -8 + i);
          const adjustedDateHeaderLastDay = addDays(parsedDate, 0);

          item.Header = moment(adjustedDateHeader).format("MMMM D ddd");

          if (item.DataKey.includes(`total_Qty_Back_0`)) {
            item.Header = `Order on ${moment(adjustedDateHeaderLastDay).format(
              "MMMM D ddd"
            )}`;
          }
          const currentDate = new Date();
          const adjustedMonthDay =
            adjustedDate.getMonth() * 100 + adjustedDate.getDate();
          const currentMonthDay =
            currentDate.getMonth() * 100 + currentDate.getDate();

          if (adjustedMonthDay >= currentMonthDay) {
            item.isEditableTable = true;
            item.DateForTable = "adjustedDate";
          } else {
            item.isEditableTable = false;
          }
        }
      });
    }
  };
  const GetItemDetailAMP = (value) => {
    setItemAmpDetailRow(value);
    setIsDetailAmp(true);
    setOpenDetailAmp(true);
  };

  const EditableCell = ({ rowData, dataKey, onChange, ...props }) => {
    const result = itemDetailData
      .filter((item) => item.store_ID === rowData?.store_ID)
      .map((item) => (item.isOrderDay === 1 ? true : false));
    //const editing = useMemo(() => rowData.status === "EDIT", [rowData.status]);
    var editing = result;
    const inputElement = useMemo(() => {
      return (
        <input
          //onKeyDown={(e) => e.preventDefault()}

          type="text"
          style={{ width: "35px", textAlign: "start" }}
          value={rowData[dataKey]}
          className=" py-1 text-center outline-none"
          onChange={(event) => {
            onChange && onChange(rowData.store_ID, dataKey, event.target.value);
          }}
        />
      );
    }, [rowData, dataKey, onChange]);
    return (
      <Cell {...props} className={editing ? "table-content-editing" : ""}>
        {result && inputElement}
      </Cell>
    );
  };

  const handleChange = (id, key, value) => {
    value = value ? value : "0";
    var nextDataDiscount = Object.assign([], itemDetailData);
    var activeItemDisc = Array(
      nextDataDiscount.find((item) => item.store_ID === id)
    );
    activeItemDisc.find((item) => item.store_ID === id)[key] = value;
    activeItemDisc.find((item) => item.store_ID === id)["row_Changed"] = true;
    const combinedDisc = updateOrAddObjectByUID(
      nextDataDiscount,
      activeItemDisc[0]
    );
    setItemDetailData(combinedDisc);

    function updateOrAddObjectByUID(array, newObj) {
      // UID'ye göre mevcut objeyi bul
      const existingIndex = array.findIndex(
        (obj) => obj.store_ID === newObj.store_ID
      );

      // Eğer mevcut obje bulunursa, onu güncelle
      if (existingIndex !== -1) {
        array[existingIndex] = newObj;
      } else {
        // Eğer mevcut obje bulunmazsa, yeni objeyi ekle
        array.push(newObj);
      }

      return array;
    }
  };

  const sendData = (value) => {
    for (let index = 0; index < value.length; index++) {
      const element = value[index];
      getCreateData(
        element &&
          Number(element.total_Qty_7) >= 1 &&
          element.row_Changed === true
          ? element
          : null
      );
    }
    setOpenDetail(false);
  };

  const sendAllProcess = () => {
    setIsActiveMessage(false);
    sendData(itemDetailData);
    setTimeout(() => {
      GetItemDetailData();
    }, 2000);
  };

  const getCreateData = (value1) => {
    // Create AMP Side
    if (value1 && Number(value1.total_Qty_7) !== 0) {
      const newValue = {
        Department_Store_ID: itemDetailRow.department_Store_ID,
        Store_ID: Number(value1?.store_ID),
        Vendor_ID: vendorID,
        User_ID: userContext?.user_ID,
        UPC: itemDetailRow.upc ? itemDetailRow.upc : 0,
        Item_ID: itemDetailRow.item_ID ? itemDetailRow.item_ID : 0,
        Status: "0",
        Quantity: Number(value1.total_Qty_7),
        Delivery_Date: moment(fromDate).format("YYYY-MM-DD 00:00:00.0"),
        Update_Date: addDays(new Date(), 0),
        Record_Type: 21,
        Module_ID: null,
      };
      ReportService.InsertOutofStockOrder(Array(newValue))
        .then((result) => {})
        .catch((err) => {
          // toast.error("Error Loading Orders");
        })
        .finally(() => {});
    }
  };
  return (
    <>
      <Modal size={"xl"} open={openDetail} onClose={handleClose}>
        <Modal.Header>
          <div>
            <Modal.Title>
              <span
                className="mx-2 filter"
                onClick={() => {
                  setOpenDetail(false);
                }}
              >
                <Icon icon="ep:back" />
              </span>
              <div style={{ textAlign: "center" }}>
                {" "}
                Item Detail for Stores in a Group
              </div>
            </Modal.Title>
          </div>
          <div className="bg-container row m-0 mt-3 p-3">
            <div className="col-1 px-1  p-2 reportDescItem">
              <>
                <p className="m-0 fw-bold">UPC: </p>
                <p className="m-0">{`${itemDetailRow?.upc}`}</p>
              </>
            </div>
            <div className="col-1 px-1  p-2 reportDescItem">
              <>
                <p className="m-0 fw-bold">Item ID: </p>
                <p className="m-0">{`${itemDetailRow?.item_ID}`}</p>
              </>
            </div>
            <div className="col-2 px-1  p-2 reportDescItem">
              <>
                <p className="m-0 fw-bold">Description: </p>
                <p className="m-0">{` ${itemDetailRow?.description}`}</p>
              </>
            </div>
            <div className="col-1 px-1  p-2 reportDescItem">
              <>
                <p className="m-0 fw-bold">Size: </p>
                <p className="m-0">{` ${itemDetailRow?.size}`}</p>
              </>
            </div>

            <div className="col-1 px-1  p-2 reportDescItem">
              <>
                <p className="m-0 fw-bold">Pack: </p>
                <p className="m-0">{`${itemDetailRow?.pack1}`}</p>
              </>
            </div>
            <div className="col-2  px-1  p-2 reportDescItem">
              <>
                <p className="m-0 px-1 fw-bold">Department: </p>
                <p className="m-0">{`${itemDetailRow?.department_Store_Name}`}</p>
              </>
            </div>
            <div className="col-2 px-1  p-2 reportDescItem">
              <>
                <p className="m-0 fw-bold">Warehouse Q/H Qty: </p>
                <p className="m-0">{`${
                  itemDetailRow?.cases_in_WH === ""
                    ? 0
                    : itemDetailRow?.cases_in_WH
                }`}</p>
              </>
            </div>

            <div className="col-1 px-1  p-2 reportDescItem">
              <>
                <p className="m-0 fw-bold">Future Days: </p>
                <p className="m-0">{`${
                  itemDetailRow?.ad_Future === "" ? 0 : itemDetailRow?.ad_Future
                }`}</p>
              </>
            </div>
            <div className="col-2  px-1  p-2 reportDescItem">
              <>
                <Button
                  style={{ marginLeft: "10px", marginBottom: "10px" }}
                  // onClick={() => setOpenCreate(true)}
                  onClick={() =>
                    itemDetailData.length >= 1 && setIsActiveMessage(true)
                  }
                  appearance="primary"
                >
                  Save Adjustments
                </Button>
              </>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <LastDayToSaveTable
            isFromModal={false}
            tableDetails={ReportLDTSConstants["LastDayToSaveDetail"]}
            reportList={itemDetailData}
            iconColumn={"store_Name"}
            // onTableRowClick={(e) => {
            //   GetItemDetailAMP(e);
            // }}
            onTableRowClickEye={(e) => {
              GetItemDetailAMP(e);
            }}
            EditableCell={EditableCell}
            handleChange={handleChange}
            reportType={""}
            totalTableRow={{}}
            isTotalRowRequired={false}
          />
        </Modal.Body>
      </Modal>
      {isDetailAmp && (
        <LastDayToSaveItemDetailAMP
          isDetail={isDetailAmp}
          userContext={userContext}
          setIsDetail={setIsDetailAmp}
          vendorID={vendorID}
          itemAmpDetailRow={itemAmpDetailRow}
          GroupTypeID={groupTypeID}
          openDetail={openDetailAmp}
          setOpenDetail={setOpenDetailAmp}
          fromDate={fromDate}
          itemDetailRow={itemDetailRow}
        />
      )}

      <Modal
        size={"400px"}
        open={isActiveMessage}
        onClose={handleCloseMessagePopUp}
      >
        <p className="text-center">Do you want to Save Adjustments</p>
        <hr />
        <ButtonToolbar className="d-flex justify-content-evenly" justified>
          <Button
            onClick={() => setIsActiveMessage(false)}
            appearance="primary"
            color="red"
          >
            No
          </Button>
          <Button
            appearance="primary"
            color="green"
            onClick={() => sendAllProcess()}
          >
            Yes
          </Button>
        </ButtonToolbar>
      </Modal>
    </>
  );
};

export default LastDayToSaveItemDetail;
