import { AxiosService } from "../utils/axiosService";
import { stringDecompression } from "./stringLZService";
import { Grindlog } from "../models/grindlog";

const API_URL = {
  vendorList: "EditHeader/GetVendorInfo",
  storeList: "EditHeader/GetStores",
  departments: "Reports/OrderBooksDepartments",
  reportOrderBooks: "Reports/OrderBooks",
  reportTrackIt: "Reports/TrackIT",
  reportTrackItDepartment: "Reports/TrackItDepartment",
  trackItDetails: "Reports/TrackIt/Details",
  reportTrackItWeek: "Reports/TrackIt/Week",
  reportTrackItWeekDepartment: "Reports/TrackIt/WeekDepartment",
  reportMarkdownStore: "Reports/Markdown",
  reportMarkdownDepartment: "Reports/MarkdownDepartment",
  reportDonation: "Reports/Donation",
  reportCredits: "Reports/Credits",
  reportCreditsPending: "Reports/CreditsPending",
  reportCreditsPaid: "Reports/CreditsPaid",
  reportCreditsNotApproved: "Reports/CreditsNotApproved",
  vendorListByGroup: "Reports/VendorByGroup/",
  storeListByGroup: "Reports/StoreByGroup/",
  departmentsByGroup: "Reports/DepartmentByGroup/",
  reasonByGroup: "Reports/ReasonByGroup/",
  reportGrindLog: "Reports/GrindLog",
  reportGrindLogCase: "Reports/GrindLog/Case",
  reportGrindLogSummary: "Reports/GrindLog/SummaryNew",
  reportOrdering: "Reports/GetOrderingReport",
  reportInventory: "Reports/GetInventoryDetailByConf",
  reportProductMixOpt: "Reports/GetProductMixOptimization",
  chartsGrindlog: "MainScreen/GetGrindData",
  reportOutOfStock: "Reports/GetOutOfStock",
  reportOutOfStockDetail: "Reports/GetOutOfStockDetail",
  reportOutOfStockCreateOrder: "Reports/GetOutOfStockCreateOrder",
  reportOutOfStockDeliverySchedule: "Reports/GetOutOfStockDeliverySchedule",
  reportSectionsToScan: "Reports/GetSectionsToScan",
  reportPlacementHistory: "Reports/GetPlacementHistory",
  reportActivePlacement: "Reports/GetActivePlacement",
  reportPlacementStoreSummary: "Reports/GetPlacementStoreSummary",
  reportPlacementGroupSummary: "Reports/GetPlacementGroupSummary",
  reportPlacementByConfID: "Reports/GetPlacementByConfID",
  reportPlacementWeeklyPercentage: "Reports/GetWeeklyPercentagePlacement",
  reportLastDayToSave: "Reports/GetLastDayToSave",
  reportLastDayToSaveItemDetail: "Reports/GetItemDetailLastDayToSave",
  reportLastDayToSaveItemAMPDetail: "Reports/GetItemAMPDetailLastDayToSave",
  getItemIDorUPC: "Reports/GetItemIDorUPC",
  discountDetail: "Reports/GetDiscountDetails",
  priceDetail: "Reports/GetPriceDetails",
  costprebookDetail: "Reports/GetCostPrebookDetails",
};

//#region Lists
export const getVendorList = () => {
  return AxiosService.Get(API_URL.vendorList);
};

export const getStoreList = () => {
  return AxiosService.Get(API_URL.storeList);
};

export const getVendorListByGroup = (groupId) => {
  return AxiosService.Get(API_URL.vendorListByGroup + groupId);
};

export const getStoreListByGroup = (groupId) => {
  return AxiosService.Get(API_URL.storeListByGroup + groupId);
};

export const getDepartmentListByGroup = (groupId) => {
  return AxiosService.Get(API_URL.departmentsByGroup + groupId);
};

export const getReasonListByGroup = (groupId, programId) => {
  return AxiosService.Get(
    API_URL.reasonByGroup + groupId + "/type/" + programId
  );
};
//#endregion

//#region Report - Order Books
export const getDepartmentsList = (vendorId, storeId) => {
  const requestObject = {
    vendorId: vendorId,
    storeId: storeId,
  };
  return AxiosService.Post(API_URL.departments, requestObject);
};

export const getReportOrderBooks = (
  vendorId?: any,
  storeId?: any,
  depName?: any
) => {
  const requestObject = {
    vendorId: vendorId ?? 0,
    storeId: storeId ?? 0,
    depName: depName ?? "",
  };
  return AxiosService.Post(API_URL.reportOrderBooks, requestObject);
};
//#endregion

//#region Report - Track IT
export const getReportTrackIt = (
  programId,
  groupId,
  groupType,
  fromDate,
  toDate,
  storelist
) => {
  const requestObject = {
    programCode: programId,
    nID: groupId,
    nID_type: groupType,
    toDate: toDate,
    fromDate: fromDate,
    storeidlist: storelist.toString(),
    //storeidlist:"4004"
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.reportTrackIt, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#region Report - Track IT Department Layer
export const getReportTrackItDepartment = (
  programId,
  groupId,
  groupType,
  fromDate,
  toDate,
  storelist
) => {
  const requestObject = {
    programCode: programId,
    nID: groupId,
    nID_type: groupType,
    toDate: toDate,
    fromDate: fromDate,
    storeidlist: storelist.toString(),
    //storeidlist:"4004"
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.reportTrackItDepartment, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#region Report - Track IT Details
export const getDetailedReportTrackIt = (
  programId,
  storeId,
  groupType,
  fromDate,
  toDate,
  departmentId,
  isAll,
  storeidlist
) => {
  const requestObject = {
    programCode: programId,
    nID: storeId,
    nID_type: groupType,
    toDate: toDate,
    fromDate: fromDate,
    nDepartmentId: departmentId,
    isAll: isAll,
    storeidlist: storeidlist,
  };
  //console.log('hisss',requestObject);
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.trackItDetails, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#region Report - Track IT Week
export const getReportTrackItWeek = (
  programId,
  groupId,
  groupType,
  fromDate,
  toDate,
  storeidlist
) => {
  const requestObject = {
    programCode: programId,
    nID: groupId,
    nID_type: groupType,
    toDate: toDate,
    fromDate: fromDate,
    storeidlist: storeidlist.toString(),
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.reportTrackItWeek, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#region Report - Track IT Week Department
export const getReportTrackItWeekDepartment = (
  programId,
  groupId,
  groupType,
  fromDate,
  toDate,
  storeidlist
) => {
  const requestObject = {
    programCode: programId,
    nID: groupId,
    nID_type: groupType,
    toDate: toDate,
    fromDate: fromDate,
    storeidlist: storeidlist.toString(),
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.reportTrackItWeekDepartment, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#region Report - Markdown Store
export const getReportMarkdown = (groupId, groupType, fromDate, toDate) => {
  const requestObject = {
    nID: groupId,
    nID_type: groupType,
    toDate: toDate,
    fromDate: fromDate,
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.reportMarkdownStore, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#region Report - Markdown Department
export const getReportMarkdownDepartment = (
  groupId,
  groupType,
  fromDate,
  toDate
) => {
  const requestObject = {
    nID: groupId,
    nID_type: groupType,
    toDate: toDate,
    fromDate: fromDate,
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.reportMarkdownDepartment, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#region Report - GrindLog
export const getReportGrindLog = (groupId, fromDate, toDate) => {
  const requestObject = {
    nID: groupId,
    toDate: toDate,
    fromDate: fromDate,
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.reportGrindLog, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#region Report - GrindLog Case
export const getReportGrindLogCase = (groupId, fromDate, toDate) => {
  const requestObject = {
    nID: groupId,
    toDate: toDate,
    fromDate: fromDate,
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.reportGrindLogCase, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#region Report - GrindLog Summary
export const getReportGrindLogSummary = (
  groupId,
  groupType,
  fromDate,
  toDate
) => {
  const requestObject = {
    nID: groupId,
    nID_type: groupType,
    toDate: toDate,
    fromDate: fromDate,
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.reportGrindLogSummary, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#region Report - Donation
export const getReportDonation = (groupId, groupType, fromDate, toDate) => {
  const requestObject = {
    nID: groupId,
    nID_type: groupType,
    toDate: toDate,
    fromDate: fromDate,
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.reportDonation, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#region Report- Credits
export const getReportCredits = (storeID) => {
  const requestObject = {
    nStore_ID: storeID,
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.reportCredits, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#region Report- Out Of Stock
export const getOutOfStock = (vendorID, storeID, fromDate, toDate) => {
  const requestObject = {
    nVendorID: vendorID,
    nStore_ID: storeID,
    dReport_Date: fromDate,
    dInvoiceDate: toDate,
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.reportOutOfStock, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#region Report- Out Of Stock Detail
export const getOutOfStockDetail = (
  vendorID,
  storeID,
  fromDate,
  Department_Store_ID,
  Item_ID,
  UPC,
  Pack,
  toDate
) => {
  const requestObject = {
    nVendorID: vendorID,
    nStore_ID: storeID,
    dReport_Date: fromDate,
    nDepartment_Store_ID: Department_Store_ID,
    nItem_ID: Item_ID,
    nUPC: UPC,
    nPack1: Pack,
    dInvoiceDate: toDate,
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.reportOutOfStockDetail, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#region Report- Out Of Stock Create Order
export const InsertOutofStockOrder = (obj) => {
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.reportOutOfStockCreateOrder, obj)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          if (data) resolve(JSON.parse(data));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#region Report- Out Of Stock Delivery Schedule
export const getOutOfStockDeliverySchedule = (vendorID, storeID) => {
  const requestObject = {
    Vendor_ID: vendorID,
    Store_ID: storeID,
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.reportOutOfStockDeliverySchedule, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#region Report- Credits

//#region Pending
export const getReportCreditsPending = (storeID, start_Date, end_Date) => {
  const requestObject = {
    nStore_ID: storeID,
    nStart_Date: start_Date,
    nEnd_Date: end_Date,
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.reportCreditsPending, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

//#endregion

//#region Paid
export const getReportCreditsPaid = (storeID, start_Date, end_Date) => {
  const requestObject = {
    nStore_ID: storeID,
    nStart_Date: start_Date,
    nEnd_Date: end_Date,
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.reportCreditsPaid, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

//#endregion

//#region Not Approved
export const getReportCreditsNotApproved = (storeID, start_Date, end_Date) => {
  const requestObject = {
    nStore_ID: storeID,
    nStart_Date: start_Date,
    nEnd_Date: end_Date,
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.reportCreditsNotApproved, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

//#endregion

//#endregion

//#region Report- Ordering
export const GetOrderingReport = (
  fromDate,
  toDate,
  groupTypeId,
  storeType,
  Operation
) => {
  const requestObject = {
    //StartDate: "2023/11/07",
    //EndDate: "2023/11/07",
    //GroupStore_UID: "6",
    StartDate: fromDate,
    EndDate: toDate,
    GroupStore_UID: groupTypeId.toString(),
    //operation:"TotalstoreFailedDeadline",
    operation: Operation,
    storeType: storeType,

    pageIndex: "1",
    pageSize: "9999999",
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.reportOrdering, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#region Report- Inventory
export const GetInventoryReport = (StoreID, StartDate, End_Date) => {
  const requestObject = {
    Store_ID: StoreID,
    Start_Date: StartDate,
    End_Date: End_Date,
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.reportInventory, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#region Report- Product Mix Optimization
export const GetProductMixOptReport = (StoreID) => {
  const requestObject = {
    Store_ID: StoreID,
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.reportProductMixOpt, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#region Grindlog Charts
export const getGrindLogData = (
  GroupOrStoreID,
  ChartTypeID,
  StartTime,
  EndTime,
  UserType
) => {
  const requestObject: Grindlog = {
    GroupOrStoreID: GroupOrStoreID,
    ChartTypeID: ChartTypeID,
    StartTime: StartTime,
    EndTime: EndTime,
    UserType: UserType,
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.chartsGrindlog, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

// #region Placement Report
//#region Sections to Scan report - Placement
export const getSectionsToScan = (storeID, Items) => {
  const requestObject = {
    nStore_ID: storeID,
    nItems: Items,
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.reportSectionsToScan, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#region Sections to Scan report - Placement
export const getPlacementHistory = (storeID, UPC) => {
  const requestObject = {
    nStore_ID: storeID,
    sUpc: UPC,
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.reportPlacementHistory, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#region Sections to Scan report - Placement
export const getActivePlacement = (storeID) => {
  const requestObject = {
    nStore_ID: storeID,
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.reportActivePlacement, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#region Placement Store Summary  - Placement
export const getPlacementStoreSummary = (
  nID,
  nID_Type,
  Start_Date,
  End_Date
) => {
  const requestObject = {
    nID: nID,
    nID_Type: nID_Type,
    dDate1: Start_Date,
    dDate2: End_Date,
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.reportPlacementStoreSummary, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#region Placement Group Summary  - Placement
export const getPlacementGroupSummary = (nID, Start_Date, End_Date) => {
  const requestObject = {
    nGroupStore_UID: nID,
    dDate1: Start_Date,
    dDate2: End_Date,
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.reportPlacementGroupSummary, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#region Placement By Confirmation ID
export const getPlacementByConfID = (Store_ID, ConfID) => {
  const requestObject = {
    nStoreID: Store_ID,
    sConfID: ConfID,
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.reportPlacementByConfID, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#region Placement Weekly Percentage
export const getPlacementWeeklyPercentage = (nID, nID_Type, dDate) => {
  const requestObject = {
    nID: nID,
    nID_Type: nID_Type, // 1 Store 2 Group
    //dDate: "05/12/2024",
    dDate: dDate,
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.reportPlacementWeeklyPercentage, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#endregion

//#region Report- Last Day To Save

//#region Report- Last Day To Save Get Report
export const getLastDayToSave = (
  Vendor_ID,
  GroupStore_ID,
  Report_Date,
  Department_Store_ID = 0
) => {
  const requestObject = {
    Vendor_ID: Vendor_ID,
    GroupStore_UID: GroupStore_ID,
    Report_Date: Report_Date,
    Department_Store_ID: Department_Store_ID,
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.reportLastDayToSave, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#region Report- Last Day To Save Get Item Detail
export const GetItemDetailLastDayToSave = (
  Vendor_ID,
  GroupStore_ID,
  Report_Date,
  Department_Store_ID = 0,
  Item_ID,
  UPC,
  Pack
) => {
  const requestObject = {
    Vendor_ID: Vendor_ID,
    GroupStore_UID: GroupStore_ID,
    Report_Date: Report_Date,
    Department_Store_ID: Department_Store_ID,
    Item_ID: Item_ID,
    UPC: UPC,
    Pack: Pack,
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.reportLastDayToSaveItemDetail, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

//#endregion

//#region Report- Last Day To Save AMP
export const GetItemAMPDetailLastDayToSave = (
  vendorID,
  storeID,
  fromDate,
  Department_Store_ID,
  Item_ID,
  UPC,
  Pack,
  toDate
) => {
  const requestObject = {
    Vendor_ID: vendorID,
    Store_ID: storeID,
    Report_Date: fromDate,
    Department_Store_ID: Department_Store_ID,
    Item_ID: Item_ID,
    UPC: UPC,
    Pack: Pack,
    ORD_Date: toDate,
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.reportLastDayToSaveItemAMPDetail, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#region Discount Detail

//#region Discount Detail // Get Item ID or UPC
export const GetItemIDorUPC = (nItemIDorUPC, nVendor_ID) => {
  const requestObject = {
    nItemIDorUPC: nItemIDorUPC,
    nVendorID: nVendor_ID,
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.getItemIDorUPC, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#region Discount Detail // Discount
export const GetDiscountDetails = (
  nStore_ID,
  UPC,
  nVendor_ID,
  nItem_ID,
  dDate,
  nType
) => {
  const requestObject = {
    nStore_ID: nStore_ID,
    UPC: UPC,
    nVendor_ID: nVendor_ID,
    nItem_ID: nItem_ID,
    dDate: dDate,
    nType: nType,
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.discountDetail, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#region Discount Detail // Prices
export const GetPriceDetails = (
  nStore_ID,
  UPC,
  nVendor_ID,
  nItem_ID,
  dDate,
  nType
) => {
  const requestObject = {
    nStore_ID: nStore_ID,
    UPC: UPC,
    nVendor_ID: nVendor_ID,
    nItem_ID: nItem_ID,
    dDate: dDate,
    nType: nType,
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.priceDetail, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#region Discount Detail // Cost Prebooks
export const GetCostPrebookDetails = (
  nStore_ID,
  UPC,
  nVendor_ID,
  nItem_ID,
  dDate,
  nType
) => {
  const requestObject = {
    nStore_ID: nStore_ID,
    UPC: UPC,
    nVendor_ID: nVendor_ID,
    nItem_ID: nItem_ID,
    dDate: dDate,
    nType: nType,
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.costprebookDetail, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#endregion

//#endregion
