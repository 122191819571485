import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useMediaQuery } from "usehooks-ts";

const MainChart = (props: any) => {
  ChartJS.register(ArcElement, Tooltip, Legend);

  const {
    labels,
    value,
    totalValue,
    toDate,
    fromDate,
    MainData,
    setDepToStore,
    setStoreToDep,
    scrollTo,
    ref1,
    setFirstLayer,
    setSecondLayer,
    setThirdLayer,
  } = props;

  const numberValue = value.map((value) => Number(value));
  function calculatePercentages(numbers) {
    const total = numbers.reduce((sum, number) => sum + number, 0);
    const percentages = numbers.map((number) =>
      ((number / total) * 100).toFixed(2)
    );
    return percentages;
  }
  const newPercentages = calculatePercentages(numberValue);

  const showRightDataNoPerSign = value.map((value) => parseFloat(value));
  if (totalValue !== null || totalValue !== undefined) {
    var TotalValueRight = parseFloat(totalValue).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  }
  const combineObject = labels.map((label, index) => ({
    label,
    value: showRightDataNoPerSign[index],
  }));

  combineObject.sort((a, b) => b.value - a.value);

  const newSortLabels = [];
  const newSortValues = [];

  combineObject.map((object) => {
    return newSortLabels.push(object.label);
  });

  combineObject.map((object) => {
    return newSortValues.push(
      object.value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })
    );
  });

  const options = {
    borderWidth: 0,
    hoverBorderWith: 1,
    borderRadius: 3,
    maintainAspectRatio: useMediaQuery("(max-width: 768px)") ? false : true,

    plugins: {
      datalabels: {
        display: false,
        color: "#575757;",
        font: { weight: 900, size: 10 },
      },
      legend: {
        position: "top" as const,
        display: false,
      },
      title: {
        display: false,
        text: `From ${fromDate} To ${toDate} Track IT Shrink $ Price`,
      },

      tooltip: {
        callbacks: {
          label: (ttItem) => `${ttItem.parsed}%`,
        },
        displayColors: false,
      },
    },
  };

  //Change labels "_" to " "
  /*   const SortLabels = newSortLabels.map((label) => {
    if (label.includes("_")) {
      return label.replaceAll("_", " ");
    }
    return label;
  }); */

  const data = {
    labels: labels,
    datasets: [
      {
        data: newPercentages,
        backgroundColor: [
          "rgba(20, 36, 89, 0.6)",
          "rgba(23, 107, 160, 0.6)",
          "rgba(25, 170, 222, 0.6)",
          "rgba(26, 201, 230, 0.6)",
          "rgba(27, 212, 212, 0.6)",
          "rgba(29, 228, 189, 0.6)",
          "rgba(199, 249, 238, 0.6)",
        ],
        borderColor: [
          "#186BA1",
          "#19ABDE",
          "#1AC9E6",
          "#1CD4D4",
          "#1DE4BD",
          "#6DF0D1",
          "#C7F9EE",
        ],
        spacing: 20,
        hoverOffset: 2,
      },
    ],
  };
  const charTextColor = [
    "rgba(20, 36, 89, 0.6)",
    "rgba(23, 107, 160, 0.6)",
    "rgba(25, 170, 222, 0.6)",
    "rgba(26, 201, 230, 0.6)",
    "rgba(27, 212, 212, 0.6)",
    "rgba(29, 228, 189, 0.6)",
    "rgba(199, 249, 238, 0.6)",
  ];

  return (
    <>
      <div className="programming-stats-dashboard my-4">
        <div className="center-chart">
          <Doughnut options={options} data={data} />
        </div>
        <div className="details-chart">
          <ul>
            {newSortLabels.map((label, index) => {
              return (
                <li className="chart-li">
                  <div
                    style={{
                      color: charTextColor[index],
                      fontWeight: "700",
                      filter: "contrast(300)",
                    }}
                    className="chart-label"
                    onClick={(e) => {
                      scrollTo(ref1);
                      if (e.currentTarget.innerHTML.includes(" ")) {
                        MainData(
                          e.currentTarget.innerHTML.replaceAll(" ", "_")
                        );
                        scrollTo(ref1);

                        setDepToStore(true);
                        setStoreToDep(true);
                      } else {
                        MainData(e.currentTarget.innerHTML);
                        scrollTo(ref1);
                        setDepToStore(true);
                        setStoreToDep(true);
                      }
                      scrollTo(ref1);
                      setFirstLayer(false);
                      setSecondLayer(true);
                      setDepToStore(true);
                      setStoreToDep(true);
                    }}
                  >
                    {label.includes("_") ? label.replaceAll("_", " ") : label}

                    {""}
                  </div>
                  <span className="percantage">
                    {" " + newSortValues[index]}
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="chart-li-item">
          Total:
          <span className="percantage">{TotalValueRight}</span>
        </div>
      </div>
    </>
  );
};

export default MainChart;
