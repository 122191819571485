import { useState } from "react";
import ConfirmationListComponent from "./ConfirmationListAll/ConfirmationListComponent";
import DepartmentMain from "./ConfirmationDepartment/DepartmentMain";
import { SelectPicker } from "rsuite";

const ConfirmationListMain = (props: any) => {
  const { userContext, handleSpinner } = props;
  const SelectMainOrGrind = (event) => {
    setIsGrindOrMain(event);
  };
  const [isGrindOrMain, setIsGrindOrMain] = useState("1");
  const mainOrGrind = [
    { value: "1", label: "Main" },
    { value: "2", label: "Department" },
  ];
  return (
    <>
      <>
        <SelectPicker
          placement="auto"
          size="sm"
          style={{ marginBottom: "20px" }}
          className="header-bar-item"
          placeholder="Select Filter Type"
          onChange={SelectMainOrGrind}
          data={mainOrGrind.map((item) => ({
            label: item.label,
            value: item.value,
          }))}
        />
      </>
      {isGrindOrMain === "1" ? (
        <ConfirmationListComponent
          userContext={userContext}
          handleSpinner={handleSpinner}
        />
      ) : (
        <DepartmentMain
          userContext={userContext}
          handleSpinner={handleSpinner}
        />
      )}
    </>
  );
};

export default ConfirmationListMain;
