import { useState, useEffect } from "react";
import * as UMSService from "../../../services/umsService";
import {
  Form,
  Button,
  Divider,
  Row,
  Col,
  Table,
  SelectPicker,
  ButtonToolbar,
} from "rsuite";
import Calendar from "../../common/CalendarComponent";
import { addDays } from "date-fns";
import moment from "moment";
import { toast } from "react-toastify";

const LoginHistory = (props: any) => {
  const { userContext, handleSpinner } = props;
  const { Column, HeaderCell, Cell } = Table;

  // Calendar
  const [fromDate, setFromDate] = useState<Date>(addDays(new Date(), -1));
  const [toDate, setToDate] = useState<Date>(addDays(new Date(), -1));
  const [rolesList, setRolesList] = useState([]);
  const [userListByRole, setUserListByRole] = useState([]);
  const [historyData, setHistoryData] = useState([]);

  const initFormValue = {
    _roleID: null,
    _userID: false,
    _dateTo: moment(toDate).format("YYYY-MM-DD"),
    _dateFrom: moment(fromDate).format("YYYY-MM-DD"),
    _sortBy: "",
  };
  const [formValue, setFormValue] = useState(initFormValue);

  const [sortList, setSortList] = useState([
    {
      SortName: "User",
      id: "1",
    },
    {
      SortName: "Login Time",
      id: "2",
    },
  ]);

  useEffect(() => {
    GetRolesList();
  }, []);

  const GetRolesList = async () => {
    UMSService.GetRolesList()
      .then((result) => {
        setRolesList(result);
      })
      .catch((err) => {
        console.error("Error:  - ", err);
        // toast.error("Error Loading Orders");
      });
  };

  const GetUsersByRole = async (roleID) => {
    UMSService.GetUsersByRole(roleID)
      .then((result) => {
        setUserListByRole(result);
      })
      .catch((err) => {
        console.error("Error:  - ", err);
        // toast.error("Error Loading Orders");
      });
  };

  const calenderOnClick = (startDate, endDate) => {
    setFromDate(startDate);
    setToDate(endDate);
  };

  const SelectRoleType = (event) => {
    GetUsersByRole(event);
  };

  const handleSubmit = () => {
    const obj = {
      _dateTo: moment(toDate).format("YYYY-MM-DD"),
      _dateFrom: moment(fromDate).format("YYYY-MM-DD"),
      ...formValue,
    };
    SearchLoginHistory(obj);
    //InsertNewRole(obj);
  };

  const SearchLoginHistory = async (userData) => {
    UMSService.SearchLoginHistory(userData)
      .then((result) => {
        result.map((item) => {
          item.loginDateTime = moment(item.loginDateTime).format("MM-DD-YYYY");
          item.logoutDateTime = moment(item.logoutDateTime).format(
            "MM-DD-YYYY"
          );
        });
        setHistoryData(result);
        toast.success("Data loaded successfully");

        //result && window.location.reload();
      })
      .catch((err) => {
        console.error("Error:  - ", err);
        toast.error("There is no data");
        // toast.error("Error Loading Orders");
      });
  };

  return (
    <>
      <Row className="show-grid">
        <Col xs={36}>
          <Form
            onChange={setFormValue}
            onSubmit={handleSubmit}
            layout={"inline"}
          >
            <Form.Group controlId="_roleID">
              <Form.ControlLabel>Select Role:</Form.ControlLabel>
              <Form.Control
                style={{ width: 150 }}
                name="_roleID"
                data={rolesList.map((item) => ({
                  label: item.role_Name,
                  value: item.uid.toString(),
                }))}
                accepter={SelectPicker}
                onChange={SelectRoleType}
              />
            </Form.Group>
            <Form.Group controlId="_userID">
              <Form.ControlLabel>Select User:</Form.ControlLabel>
              <Form.Control
                style={{ width: 150 }}
                name="_userID"
                data={userListByRole.map((item) => ({
                  label: item.first_Name,
                  value: item.uid.toString(),
                }))}
                accepter={SelectPicker}
                //onChange={SelectUserType}
              />
            </Form.Group>

            <Form.Group controlId="_sortBy">
              <Form.ControlLabel>Sort By:</Form.ControlLabel>
              <Form.Control
                style={{ width: 150 }}
                name="_sortBy"
                data={sortList.map((item) => ({
                  label: item.SortName,
                  value: item.id,
                }))}
                accepter={SelectPicker}
                //onChange={SelectUserType}
              />
            </Form.Group>

            <ButtonToolbar>
              <Button
                style={{ width: 150 }}
                appearance="primary"
                color="green"
                type="submit"
              >
                Search
              </Button>
            </ButtonToolbar>
          </Form>
          <Divider />
        </Col>{" "}
        <Calendar
          value={[fromDate, toDate]}
          calenderOnClick={calenderOnClick}
        />
      </Row>

      <Table
        height={800}
        data={historyData}
        /* onRowClick={(rowData) => {
          }} */
      >
        <Column width={100} fullText>
          <HeaderCell>First Name</HeaderCell>
          <Cell dataKey="firstName" />
        </Column>
        <Column width={100} fullText>
          <HeaderCell>Last Name</HeaderCell>
          <Cell dataKey="lastName" />
        </Column>
        <Column width={200} fullText>
          <HeaderCell>email</HeaderCell>
          <Cell dataKey="email" />
        </Column>
        {/*         <Column width={200}>
          <HeaderCell>Phone</HeaderCell>
          <Cell dataKey="phone" />
        </Column> */}
        <Column width={100}>
          <HeaderCell>State</HeaderCell>
          <Cell dataKey="state" />
        </Column>{" "}
        <Column width={100}>
          <HeaderCell>Zip</HeaderCell>
          <Cell dataKey="zip" />
        </Column>{" "}
        <Column width={100}>
          <HeaderCell>Role</HeaderCell>
          <Cell dataKey="roleName" />
        </Column>{" "}
        <Column width={100}>
          <HeaderCell>Login Time </HeaderCell>
          <Cell dataKey="loginDateTime" />
        </Column>
        <Column width={100}>
          <HeaderCell>Logout Time </HeaderCell>
          <Cell dataKey="logoutDateTime" />
        </Column>
        <Column width={100}>
          <HeaderCell>Mac Address</HeaderCell>
          <Cell dataKey="macAddress" />
        </Column>
        {/*         <Column width={80} fixed="right">
          <HeaderCell>Edit Role</HeaderCell>

          <Cell style={{ padding: "6px" }}>
            {(rowData) => (
              <Button
                appearance="link"
                // onClick={() => alert(`id:${rowData.uid}`)}
                onClick={() => editfunction(rowData.uid)}
              >
                Edit
              </Button>
            )}
          </Cell>
        </Column> */}
        {/*         <Column width={120} fixed="right">
          <HeaderCell>Delete Role</HeaderCell>

          <Cell style={{ padding: "6px" }}>
            {(rowData) => (
              <Button
                color="red"
                appearance="ghost"
                onClick={() =>
                  // eslint-disable-next-line no-restricted-globals
                  confirm(`Are you sure to delete ${rowData.role_Name}`) &&
                  DeleteRole(rowData.uid.toString())
                }
              >
                Delete
              </Button>
            )}
          </Cell>
        </Column> */}
      </Table>
    </>
  );
};

export default LoginHistory;
