import { useEffect, useState } from "react";
import { SelectPicker } from "rsuite";
import TableComponent from "./tableComponent";
import * as SettingsServices from "services/settingsServices";
import { MilkDeliveryScheduleConstants } from "./MilkDeliveryScheduleConstants";

const MilkDeliverySchedule = (props: any) => {
  const { handleSpinner, userContext = {} } = props;

  const [VendorStoreName, setVendorStoreName] = useState([]);
  const [scheduleTime, setScheduleTime] = useState([]);
  const [vendorID, setVendorID] = useState("");
  const [DayID, setDayID] = useState("");
  const [milkDeliverySchedule, setMilkDeliverySchedule] = useState([]);

  useEffect(() => {
    getMilkDeliverySchedule(vendorID, DayID);
  }, [DayID,vendorID]);
  /*   useEffect(() => {
    
    getMilkDeliverySchedule(vendorID, DayID);
  }, [vendorID, DayID]); */

  const getMilkDeliverySchedule = async (Vendor, Delivery_Day) => {
    const { handleSpinner, userContext = {} } = props;
    if (Vendor && Delivery_Day) {
      SettingsServices.GetMilkDeliverySchedule(Vendor, Delivery_Day)
        .then((result) => {
          result.records.map((item) =>
            item.deadline_Minute === 0 ? (item.deadline_Minute = "00") : null
          );
          result.records.map((item) =>
            item.deadline_Minute1 === 0 ? (item.deadline_Minute1 = "00") : null
          );
          result.records.map((item) =>
            item.c_Deadline_Minute === 0
              ? (item.c_Deadline_Minute = "00")
              : null
          );
          result.records.map((item) =>
            item.c_Deadline_Minute1 === 0
              ? (item.c_Deadline_Minute1 = "00")
              : null
          );

          setMilkDeliverySchedule(result.records);
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    }
  };

  const VendorList = [
    { key: "60049000", value: "A E" },
    { key: "2023", value: "BELFONTE" },
    { key: "1114", value: "Dean Foods" },
    { key: "1115", value: "HILAND KC" },
    { key: "1116", value: "HILAND NORMAN" },
    { key: "111555", value: "HILAND OK" },
    { key: "1113", value: "HILAND RS" },
    { key: "15555", value: "HILAND SPRING" },
  ];

  const DayList = [
    { key: "1", value: "Sunday" },
    { key: "2", value: "Monday" },
    { key: "3", value: "Tuesday" },
    { key: "4", value: "Wednesday" },
    { key: "5", value: "Thursday" },
    { key: "6", value: "Friday" },
    { key: "7", value: "Saturday" },
  ];

  const onChangeVendor = (event) => {
    setVendorID(event);
  };
  const onChangeDay = (event) => {
    setDayID(event);
  };

  return (
    <>
      <SelectPicker
        onChange={onChangeVendor}
        label="Choose Vendor"
        virtualized
        data={VendorList.map((item, index) => ({
          label: item.value,
          value: item.key,
        }))}
        style={{ width: 224 }}
      />
      {vendorID ? (
        <span>
          <SelectPicker
            onChange={onChangeDay}
            label="Choose Day"
            virtualized
            data={DayList.map((item, index) => ({
              label: item.value,
              value: item.key,
            }))}
            style={{ width: 224 }}
          />
        </span>
      ) : null}

      <TableComponent
        isFromModal={false}
        tableDetails={MilkDeliveryScheduleConstants["MilkDeliverySchedule"]}
        reportList={milkDeliverySchedule}
        iconColumn={"NA"}
        onTableRowClick={() => {}}
        reportType={""}
        totalTableRow={{}}
        isTotalRowRequired={false}
      />
    </>
  );
};

export default MilkDeliverySchedule;
