import { EnhancedStore, configureStore } from "@reduxjs/toolkit";
import filterSelectionReducer from "./filterSelection/filterSelectionSlice";

export const store: EnhancedStore = configureStore({
    reducer: {
        filterSelection: filterSelectionReducer
    },
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store;
