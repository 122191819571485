export const ReportConstants = {
    "Pending": [
        { Header: "Store ID1", DataKey: "store_ID1", Width: 10, isFixed: true, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        
        { Header: "Vendor", DataKey: "vendor", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "text-center-heading", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Department", DataKey: "department", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Invoice Number", DataKey: "invoice_Number", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Invoice Date", DataKey: "invoice_Date", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Date", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Confirmation ID", DataKey: "confirmation_ID", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

        { Header: "UPC", DataKey: "upc", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
       
        { Header: "Item", DataKey: "item_ID", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Description", DataKey: "description", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "text-center-heading", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Size", DataKey: "size", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Pack", DataKey: "pack", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Reason Description", DataKey: "reason_Description", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Credit Qty", DataKey: "qty", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
       //{ Header: "Days Reordered", DataKey: "pack", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Credit Type", DataKey: "credit_Type", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "WH Comments", DataKey: "got_Comments", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Store Comments", DataKey: "comments", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Invoice Qty", DataKey: "invoice_Qty", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Case Cost", DataKey: "price", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Price", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Extended Cost", DataKey: "credit_Amount", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Price", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Detail Delivery Date", DataKey: "detail_Delivery_Date", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Date", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Invoice Date Next", DataKey: "invoice_Date_Next", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Date", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

    ],
    "Paid": [
        { Header: "Store ID1", DataKey: "store_ID1", Width: 100, isFixed: true, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     

        { Header: "Vendor", DataKey: "vendor", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Department", DataKey: "department", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Confirmation ID", DataKey: "confirmation_ID", Width: 150, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

        { Header: "Invoice Number", DataKey: "invoice_Number", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Invoice Date", DataKey: "invoice_Date", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Date", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "UPC", DataKey: "upc", Width: 150, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
       
        { Header: "Item", DataKey: "item_ID", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Description", DataKey: "description", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "text-center-heading", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Size", DataKey: "size", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Pack", DataKey: "pack", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Reason_Description", DataKey: "reason_Description", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Credit Qty", DataKey: "qty", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        //{ Header: "Days Reordered", DataKey: "pack", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Credit_Type", DataKey: "credit_Type", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "WH Comments", DataKey: "got_Comments", Width: 150, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Store Comments", DataKey: "comments", Width: 150, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Invoice Qty", DataKey: "invoice_Qty", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Case Cost", DataKey: "price", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Price", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
       // { Header: "Extended Cost", DataKey: "credit_Amount", Width: 120, isFixed: false, isSortable: false, Align: "left", TypeOfData: "Price", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Detail Delivery Date", DataKey: "detail_Delivery_Date", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Date", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Invoice Date Next", DataKey: "invoice_Date_Next", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Date", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

      
    ],
    "NotApproved": [
        { Header: "Store ID1", DataKey: "store_ID1", Width: 100, isFixed: true, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "Department", DataKey: "department", Width: 120, isFixed: false, isSortable: true, Align: "right", TypeOfData: "text-center-heading", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Credit_Type", DataKey: "credit_Type", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Credit N", DataKey: "credit_N", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Confirmation ID", DataKey: "confirmation_ID", Width: 150, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

        { Header: "Invoice Number", DataKey: "invoice_Number", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Invoice Date", DataKey: "invoice_Date", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Date", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Delivery Date", DataKey: "delivery_Date", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Date", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Item", DataKey: "item_ID", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Description", DataKey: "description", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "text-center-heading", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Size", DataKey: "size", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Pack", DataKey: "pack", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Reason_Description", DataKey: "reason_Description", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Credit Qty", DataKey: "credit_Qty", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Not Approved Reason", DataKey: "notApproved_Reason", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "WH Comments", DataKey: "wH_Comments", Width: 150, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Store Comments", DataKey: "store_Comments", Width: 150, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Days Reordered", DataKey: "days_Reordered", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Rec Date", DataKey: "rec_Date", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Date", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Ext Cost", DataKey: "ext_Cost", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "DM", DataKey: "dm", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "text-center-heading", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Vendor", DataKey: "vendor", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
    ]
}