/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import * as ReportService from "../../../services/reportServices";
import moment from "moment";
import { capitalCase } from "change-case";
import { ReportMaster, TableHeaderConfig } from "../../../models";
import { InputPicker } from "rsuite";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { ReportConstants } from "../../reports/MarkdownReport/MarkdownStore/reportMarkdownConstants";
import MainControllerMarkdownShrink from "./MarkDown&ShrinkCharts/MainControllerMarkdownShrink";
import MainControllerMarkdown from "./MarkdownCharts/MainControllerMarkdown";
import MarkdownMainControllerShrink from "./ShrinkCharts/MarkdownMainControllerShrink";
import ReclaimMainControllerShrink from "./ReclaimCharts/ReclaimMainController";

const MarkDownContainer = (props: any) => {
  //#region Props
  const {
    userContext = {},
    fromDate,
    groupTypeID,
    setFromDate,
    handleSpinner,
    toDate,
    setToDate,
    userStore,
    setUserStore,
  } = props;

  const { groupType } = userContext ?? {};
  //#endregion

  //#region Report API Filters
  // Calendar

  // Tab
  const [selectedTab, setSelectedTab] = useState({
    value: "1",
    label: "Markdown & Shrink",
  });

  const tabsList: any[] = [
    { value: "1", label: "Markdown & Shrink" },
    { value: "2", label: "Markdown" },
    { value: "3", label: "Shrink" },
    { value: "4", label: "Reclaim Eligible" },
  ];

  const data = tabsList.map((item) => {
    return { label: item.label, value: item.value };
  });

  //#endregion

  //#region Reports Master Lists
  const [isDataloaded, setIsDataloaded] = useState(false);

  const [reportData, setReportData] = useState(new ReportMaster());
  //#endregion
  //#region Reports Tables Config
  // Headers
  const [groupTableHeaders, setGroupTableHeaders] = useState([]);

  // Totals
  const [groupTableTotals, setGroupTableTotals] = useState([]);
  // reports List
  const [groupTableReports, setGroupTableReports] = useState([]);

  // No Data found

  //#endregion

  //#region Filters

  //#endregion

  useEffect(() => {
    setGroupTableHeaders([]);
    setGroupTableTotals([]);
    setGroupTableReports([]);
    getReportData();
  }, [props.userContext, fromDate, toDate, userStore, groupTypeID]);
  useEffect(() => {
    handleDataChange();
  }, [reportData, selectedTab]);
  //#region Common Functions
  const getTotalRowData = (reportList, headers: Array<any>) => {
    const totalObj: any = {};
    headers.forEach((res) => {
      totalObj[res.DataKey] =
        res.TypeOfData === "Number" || res.TypeOfData === "Price"
          ? res.TypeOfData === "Price"
            ? Number(
                reportList?.reduce(
                  (acc, item) => acc + (item[res.DataKey] ?? 0),
                  0
                )
              ).toFixed(2)
            : Number(
                reportList?.reduce(
                  (acc, item) => acc + (item[res.DataKey] ?? 0),
                  0
                )
              )
          : "";
    });
    return totalObj;
  };

  //#endregion

  //#region Markdown Report
  const handleDataChange = () => {
    let headers = [];
    let data = [];
    let totals = [];
    headers = getMarkdownHeaders(reportData.headers, false);
    data = handleMarkdownData(true);
    totals = getTotalRowData(data, headers);
    setGroupTableTotals(totals);
    setGroupTableReports(data);
  };

  const getReportData = async () => {
    var { groupType, groupTypeId, store, roleName } = userContext;
    if (roleName === "Super Admin" && userStore === "") {
      handleSpinner && handleSpinner(true);
      ReportService.getReportMarkdown(
        groupTypeID,
        2,
        moment(fromDate).format("YYYY-MM-DD"),
        moment(toDate).format("YYYY-MM-DD")
      )
        .then((result) => {
          result.records.map(
            (record) =>
              (record["MarkDownLoss"] =
                (record["reg_Total"] * record["disc_Percent"]) / 100)
          );

          const _reportData = handleReportData(result);
          setReportData(_reportData);
          setIsDataloaded(true);
        })
        .catch((err) => {
          console.error("Error: Get Orders - ", err);
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    } else if (
      roleName === "Super Admin" ||
      (store === 0 && groupTypeID !== 0 && userContext.groupTypeName === "")
    ) {
      handleSpinner && handleSpinner(true);
      ReportService.getReportMarkdown(
        userStore,
        1,
        moment(fromDate).format("YYYY-MM-DD"),
        moment(toDate).format("YYYY-MM-DD")
      )
        .then((result) => {
          result.records.map(
            (record) =>
              (record["MarkDownLoss"] =
                (record["reg_Total"] * record["disc_Percent"]) / 100)
          );

          const _reportData = handleReportData(result);
          setReportData(_reportData);
          setIsDataloaded(true);
        })
        .catch((err) => {
          console.error("Error: Get Orders - ", err);
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    } else if (userStore) {

      handleSpinner && handleSpinner(true);
      ReportService.getReportMarkdown(
        userStore,
        1,
        moment(fromDate).format("YYYY-MM-DD"),
        moment(toDate).format("YYYY-MM-DD")
      )
        .then((result) => {
          result.records.map(
            (record) =>
              (record["MarkDownLoss"] =
                (record["reg_Total"] * record["disc_Percent"]) / 100)
          );

          const _reportData = handleReportData(result);
          setReportData(_reportData);
          setIsDataloaded(true);
        })
        .catch((err) => {
          console.error("Error: Get Orders - ", err);
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    } else if(roleName !=='DM') {
      if (store !== 0) {
        groupTypeId = store;
        groupType = 1;
      } else {
        groupType = 2;
      }
      handleSpinner && handleSpinner(true);
      ReportService.getReportMarkdown(
        groupTypeId,
        groupType,
        moment(fromDate).format("YYYY-MM-DD"),
        moment(toDate).format("YYYY-MM-DD")
      )
        .then((result) => {
          result.records.map(
            (record) =>
              (record["MarkDownLoss"] =
                (record["reg_Total"] * record["disc_Percent"]) / 100)
          );

          const _reportData = handleReportData(result);
          setReportData(_reportData);
          setIsDataloaded(true);
        })
        .catch((err) => {
          console.error("Error: Get Orders - ", err);
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    }
  };
  const handleReportData = (response: any) => {
    const { records = [], uniqueReasons = [] } = response;
    const _reportData = { ...reportData };
    _reportData.headers = uniqueReasons;
    _reportData.reportList = records.map((item) => {
      item["FormatedPrintedDate"] =
        item.date_Printed && moment(item.date_Printed).format("MM-DD-YYYY");
      item["Badge_Type"] = item.reg_Price_Keyed > 0 ? " Yes" : "No";
      item["Description"] = item.description !== "" && item.description.trim();
      item["shrink_Scan_Time"] =
        item.shrink_Scan_Time && item.shrink_Scan_Time.trim() !== "00:00:00"
          ? moment(item.shrink_Scan_Time, ["HH:mm:ss"]).format("hh:mm:ss A")
          : "";
      return item;
    });
    return _reportData;
  };

  const getMarkdownHeaders = (reasonHeaders, isGroup = false) => {
    const headers = reasonHeaders.map((data) => {
      return {
        ...new TableHeaderConfig(),
        Header: capitalCase(data),
        DataKey: data,
        TypeOfData: "Price",
      };
    });
    headers.push({
      ...new TableHeaderConfig(),
      Header: "Total",
      DataKey: "total",
      Width: 10,
      TypeOfData: "Price",
      Icon: "reg_price_keyed",
    });
    const headerStart =
      ReportConstants[isGroup ? "GroupDeptDataTable" : "GroupDataTable"];
    return [...headerStart, ...headers];
  };

  const handleMarkdownData = (isGroup = false, isDetailed = false) => {
    let _reportData = [];
    const { value: selectedTabValue } = selectedTab;
    switch (selectedTabValue) {
      case "2":
        _reportData = reportData.reportList.filter((res) => {
          return res.shrink_Confirmation_ID === "";
        });
        break;
      case "3":
        _reportData = reportData.reportList.filter((res) => {
          return res.shrink_Confirmation_ID !== "";
        });
        break;
      case "4":
        _reportData = reportData.reportList.filter((res) => {
          return res.reclaim && res.reclaim.trim() === "Y";
        });
        break;
      default:
        _reportData = [...reportData.reportList];
        break;
    }
    if (!isDetailed) return groupMarkdownData(_reportData, isGroup);
    else return _reportData;
  };

  const groupMarkdownData = (_reportData: Array<any>, isGroup = false) => {
    const { value: selectedTabValue } = selectedTab;
    const reportGrpArr = Object.values(
      _reportData.reduce((repGrp, report) => {
        const { store_ID, department } = report;
        const _key = isGroup ? `${store_ID}_${department}` : store_ID;
        if (repGrp[_key]) {
          repGrp[_key].push(report);
        } else {
          repGrp[_key] = [report];
        }
        return repGrp;
      }, {})
    ).map((rep: Array<any>) => {
      const obj: any = {
        store_ID: rep[0].store_ID,
        store_ID1: rep[0].client_Store_N,
        total: 0,
        Badge_Type: "No",
      };
      if (isGroup) obj.department = rep[0].department;
      const priceKeyed = rep.reduce(
        (acc, item) => acc + (item["reg_Price_Keyed"] ?? 0),
        0
      );
      obj.Badge_Type = priceKeyed > 0 ? " Yes" : "No";
      obj.reg_price_keyed = priceKeyed > 0 ? true : false;
      reportData.headers.forEach((head) => {
        const _reasonPrice = rep
          .filter((_reportFltr) => _reportFltr.reason === head)
          .reduce(
            (acc, item) =>
              acc +
              (item[selectedTabValue === "3" ? "reg_Price" : "reg_Total"] ?? 0),
            0
          ); //).toFixed(2)
        obj[head] = _reasonPrice;
        obj.total += _reasonPrice;
      });
      return obj;
    });
    return reportGrpArr ?? [];
  };
  //#endregion

  //#endregion
  return (
    <>
      <div className="header-main-dashboard">
        <InputPicker
          className="input-picker"
          onChange={(e) => setSelectedTab({ ...selectedTab, value: String(e) })}
          size="lg"
          placeholder="Markdown & Shrink"
          data={data}
          cleanable={false}
        />
      </div>
      {selectedTab.value === "1" ? (
        <MainControllerMarkdownShrink
          toDate={toDate}
          groupTableTotals={groupTableTotals}
          fromDate={fromDate}
          groupTypeID={groupTypeID}
          userStore={userStore}
          reasonReportData={groupTableReports}
          userContext={userContext}
        />
      ) : (
        ""
      )}
      {selectedTab.value === "2" ? (
        <MainControllerMarkdown
          toDate={toDate}
          groupTypeID={groupTypeID}
          userStore={userStore}
          groupTableTotals={groupTableTotals}
          fromDate={fromDate}
          reasonReportData={groupTableReports}
          userContext={userContext}
        />
      ) : (
        ""
      )}
      {selectedTab.value === "3" ? (
        <MarkdownMainControllerShrink
          toDate={toDate}
          groupTypeID={groupTypeID}
          userStore={userStore}
          groupTableTotals={groupTableTotals}
          fromDate={fromDate}
          reasonReportData={groupTableReports}
          userContext={userContext}
        />
      ) : (
        ""
      )}
      {selectedTab.value === "4" ? (
        <ReclaimMainControllerShrink
          toDate={toDate}
          groupTypeID={groupTypeID}
          userStore={userStore}
          groupTableTotals={groupTableTotals}
          fromDate={fromDate}
          reasonReportData={groupTableReports}
          userContext={userContext}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default MarkDownContainer;
