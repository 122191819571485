export class OrdersRequestFailed {
  _startDate?: string; // "04-23-2023",
  _HeaderMenu?: string; // "MO,MO1,MT,MT1,MI,MP,MPI,MD,MR,M$,MS,MX,MB,MJ,MNC,MNO,S1,TRK",
  _headerType?: string; // "a,b,c",
  _status?: string; // "StatusList_Issues",
  _isMove?: boolean; // false,
  user_store_type?: number;

  constructor(startDate) {
    this._startDate = startDate;
    this.user_store_type = 0;
    this._HeaderMenu =
      "MO,MO1,MT,MT1,MI,MP,MPI,MD,MR,M$,MS,MX,MB,MJ,MNC,MNO,S1,TRK";
    this._headerType = "a,b,c";
    this._status = "StatusList_Issues";
    this._isMove = false;
  }
}

export class OrdersRequestProcessed {
  _startDate?: string; // "04-23-2023",
  _HeaderMenu?: string; // "MO,MO1,MT,MT1,MI,MP,MPI,MD,MR,M$,MS,MX,MB,MJ,MNC,MNO,S1,TRK",
  _headerType?: string; // "a,b,c",
  _status?: string; // "",
  _isMove?: boolean; // false,
  user_store_type?: number;

  constructor(startDate) {
    this._startDate = startDate;
    this.user_store_type = 0;
    this._HeaderMenu =
      "MO,MO1,MT,MT1,MI,MP,MPI,MD,MR,M$,MS,MX,MB,MJ,MNC,MNO,S1,TRK";
    this._headerType = "a,b,c";
    this._status = "";
    this._isMove = false;
  }
}

export class OrdersRequestNotBuilt {
  _startDate?: string; // "04-23-2023",
  _HeaderMenu?: string; // "MO,MO1,MT,MT1,MI,MP,MPI,MD,MR,M$,MS,MX,MB,MJ,MNC,MNO,S1,TRK",
  _headerType?: string; // "a,b,c",
  _status?: string; // "StatusList_Issues",
  _isMove?: boolean; // false,
  user_store_type?: number;

  constructor(startDate) {
    this._startDate = startDate;
    this.user_store_type = 0;
    this._HeaderMenu =
      "MO,MO1,MT,MT1,MI,MP,MPI,MD,MR,M$,MS,MX,MB,MJ,MNC,MNO,S1,TRK";
    this._headerType = "a,b,c";
    this._status = "StatusList_NotBuilt";
    this._isMove = false;
  }
}

export class OrdersRequestWaiting {
  _startDate?: string; // "04-23-2023",
  _HeaderMenu?: string; // "MO,MO1,MT,MT1,MI,MP,MPI,MD,MR,M$,MS,MX,MB,MJ,MNC,MNO,S1,TRK",
  _headerType?: string; // "a,b,c",
  _status?: string; // "StatusList_Issues",
  _isMove?: boolean; // false,
  user_store_type?: number;

  constructor(startDate) {
    this._startDate = startDate;
    this.user_store_type = 0;
    this._HeaderMenu =
      "MO,MO1,MT,MT1,MI,MP,MPI,MD,MR,M$,MS,MX,MB,MJ,MNC,MNO,S1,TRK";
    this._headerType = "a,b,c";
    this._status = "StatusList_Waiting";
    this._isMove = false;
  }
}
