import { Icon } from "@iconify/react";
import { useNavigate, useLocation } from "react-router-dom";
import profileImage from "../../assets/images/img_avatar.png";
import { useEffect, useState } from "react";
import { StorageService } from "../../utils/sessionService";
import { Button, Modal } from "rsuite";
import * as SettingsServices from "../../services/settingsServices";
import Checkgroups from "../auth/checkgroups";
import * as UMSService from "../../services/umsService";

const HeaderComponent = (props: any) => {
  const { isMenuCollapsed, azureAuth, userContext = {} } = props;
  const location = useLocation();
  const [title, setTitle] = useState("Dashboard");
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [storeName, setStoreName] = useState(" ");
  const navigate = useNavigate();
  const [isMultipleGroup, setIsMultipleGroup] = useState(false);
  const [size, setSize] = useState();
  const handleOpen = (value) => {
    setSize(value);
    setOpen(true);
  };
  const handleClose = (value) => setOpen(false);
  const handleClose1 = (value) => setOpen1(false);
  useEffect(() => {
    if (userContext && userContext.store && userContext.store !== 0) {
      getStoreName(userContext.store);
    }
  }, [userContext]);

  const getStoreName = (storeID) => {
    SettingsServices.GetAdGroupandGroupID(storeID)
      .then((result) => {
        setStoreName(result.records[0].description);
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      });
  };

  useEffect(() => {
    const { state } = location;
    if (state) {
      setTitle(state.headerTitle ?? "Dashboard");
    }
  }, [location]);
  const InsertLoginHistoryData = (user_ID) => {
    const InsertLoginTime = {
      Login_UserID: user_ID,
      Status: 0,
      Login_DateTime: null,
      Logout_DateTime: new Date(),
    };

    UMSService.InsertLoginHistoryPortal(Array(InsertLoginTime))
      .then((result) => {})
      .catch((err) => {
        // toast.error("Error Loading Orders");
      })
      .finally(() => {});
  };
  const handleLogout = () => {
    InsertLoginHistoryData(userContext && userContext.user_ID);
    StorageService.clearSLSItem("UserContext");
    azureAuth.logoutRedirect();
  };

  const openLogoutAndChange = () => {
    handleOpen("xs");
    //setOpen1(true)
  };
  return (
    <>
      <div className="mainBodyHeader">
        <div className="title">
          <Icon
            icon="material-symbols:menu-rounded"
            onClick={() => {
              props.menuCollapse(!isMenuCollapsed);
            }}
          />
          {title}
        </div>
        <div className="actions">
          <div
            onClick={() => openLogoutAndChange()}
            className={`actionItems profile d-flex align-items-center`}
            title="Profile"
          >
            <div className={`profileTitle`}>
              {userContext && (userContext.userName ?? "")}{" "}
              <span>
                {userContext && userContext.roleName === "Super Admin"
                  ? "Super Admin"
                  : userContext && userContext.roleName === "DM"
                  ? `DM - ${userContext.groupTypeName}`
                  : userContext &&
                    userContext.groupTypeId === 0 &&
                    userContext.store === 0
                  ? userContext && userContext.groupType === 1
                    ? "GOT"
                    : userContext && userContext.groupType === 2
                    ? "AWG"
                    : userContext && userContext.groupType === 3
                    ? "MDI"
                    : userContext
                    ? `${userContext.groupTypeName ? "Group" : `Store `} - ${
                        userContext.groupTypeName
                          ? userContext.groupTypeName
                          : storeName
                      } `
                    : null
                  : `${
                      userContext && userContext.groupTypeName
                        ? "Group"
                        : `Store `
                    } - ${
                      userContext && userContext.groupTypeName
                        ? userContext.groupTypeName
                        : storeName
                    } `}
                {/*                 {userContext &&
                  `${userContext.groupTypeName ? "Group" : `Store `} - ${
                    userContext.groupTypeName
                      ? userContext.groupTypeName
                      : storeName
                  } `}{" "} */}
              </span>
            </div>
            <img
              className={`rounded-circle`}
              src={profileImage}
              alt={`Profile`}
            />
          </div>
          <div className="actionItems" title="Notification">
            <Icon
              icon="material-symbols:notifications-outline"
              // onClick={() => {
              //   navigate(RoutingConstants.userGroups, { replace: true });
              // }}
            />
            {/* <Modal
              className="modalPos"
              size={"full"}
              open={open1}
              onClose={handleClose1}
            >
              <Modal.Header>
                <Modal.Title
                  style={{ color: "#acacb0" }}
                  className="modalText modalpos2"
                >
                  Are you sure, you want to change group ?
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="modalDes">
                <Checkgroups userContext={userContext} />
              </Modal.Body>
            </Modal> */}
          </div>

          {/* <Icon icon="ph:power-bold" onClick={() => handleOpen("xs")} /> */}
          <Modal
            className="modalPos "
            size={"lg"}
            open={open}
            onClose={handleClose}
          >
            <Modal.Header>
              <Modal.Title className="modalText modalpos2">
                {isMultipleGroup
                  ? "Select a group to switch or Sign out"
                  : "             Sign out?               "}
              </Modal.Title>

              <Modal.Body className={isMultipleGroup ? "modalDes1" : ""}>
                <Checkgroups
                  setIsMultipleGroup={setIsMultipleGroup}
                  userContext={userContext}
                />
              </Modal.Body>
            </Modal.Header>
            <Modal.Footer className="modalDes">
              <Button
                className="btnStyle"
                onClick={handleClose}
                appearance="subtle"
              >
                Cancel
              </Button>
              <Button
                className="btnStyle"
                onClick={handleLogout}
                appearance="primary"
              >
                Sign Out
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default HeaderComponent;
