import GOTLogo from "../../assets/images/GOTLogo.png"

const DoesNotExistComponent = (props) => {
    return <>  <div className="not-found">
        <img className="logo" src={GOTLogo} alt={`GOT Logo`} />
        <h1 className="mt-4">{`USER DOES NOT EXIST IN THE SYSTEM`}</h1>
        <h4 className="mt-4">Please contact the administrator for further details.</h4>
    </div></>;
};

export default DoesNotExistComponent;