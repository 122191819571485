export const BadItemsConstants = {
    "BadItemList": [
        { Header: "In File", DataKey: "in_File", Width: 50, isFixed: true, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "User", DataKey: "user_ID", Width: 50, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

        { Header: "Conf#", DataKey: "confirmation_ID", Width: 80, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Text", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Vendor", DataKey: "vendor_Name", Width: 50, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Delivery Date", DataKey: "delivery_Date", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Date", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Quantity", DataKey: "quantity", Width: 20, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Key", DataKey: "key_In", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "UPC", DataKey: "upc_Out", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Item ID", DataKey: "item_Out", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Date", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Description", DataKey: "description", Width: 50, isFixed: false, isSortable: false, Align: "center", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "ic:outline-report", isSubHeader: false, colSpan: 1},
       
    ]
}