/**usage:
 * 
 * if you call redux state you can import useSelector and then use it. ex:
 * 
 * const { chosenGroup, chosenGroupType } = useSelector((state) => state.filterSelection);
 * 
 * 
 * if you store a state you can use dispatch. ex:
 * 
 * const dispatch = useDispatch();
 * dispatch(setGroupType({
      groupType: event,
    }))

 * ex2:
 * const dispatch = useDispatch();
 *  dispatch(setDate({
      chosenFromDate: fromDate,
      chosenToDate: toDate,
    }))
 *
*/

import { createSlice } from "@reduxjs/toolkit";
import { awgOrGot } from "../../components/MainDashboard";

const initialState = {
    chosenGroupType: "1",
    chosenGroupTypeName: "",
    chosenGroup: "",
    chosenGroupName: "",
    chosenStore: 0,
    chosenStoreName: "",
    chosenFromDate: "",
    chosenToDate: "",
    storeRecords: [],
    groupRecords: []
}


const filterSelectionSlice = createSlice({
    name: "filterSelection",
    initialState,
    reducers: {
        setGroupType: (state, action) => {
            const selectedType = awgOrGot.find(type => type.groupStore_uid === action.payload.groupType);
            state.chosenGroupType = selectedType.groupStore_uid;
            state.chosenGroupTypeName = selectedType.groupStore_desc;
        },
        setGroup: (state, action) => {
            state.chosenGroup = action.payload.group;
            state.chosenGroupName = action.payload.groupName;
        },
        setStore: (state, action) => {
            state.chosenStore = action.payload.store;
            state.chosenStoreName = action.payload.storeName;
        },
        setDate: (state, action) => {
            state.chosenFromDate = action.payload.chosenFromDate;
            state.chosenToDate = action.payload.chosenToDate;
        },
        setGroupRecord: (state, action) => {
            state.groupRecords = action.payload.groupRecords;
        },
        setStoreRecord: (state, action) => {
            state.storeRecords = action.payload.storeRecords;
        },
    }
})

export const { setGroupType, setGroup, setStore, setDate, setStoreRecord, setGroupRecord } = filterSelectionSlice.actions;
export default filterSelectionSlice.reducer;
