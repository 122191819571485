/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useMemo } from "react";
import { Modal, Table, Button, ButtonToolbar, IconButton, Input } from "rsuite";
import * as ReportService from "../../../../services/reportServices";

import * as SettingsService from "../../../../services/settingsServices";
import { ReportLDTSConstants } from "../ReportLastDayToSaveConstants";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import moment from "moment";
import LastDayToSaveTable from "./LastDayToSaveAmpTable";
import { addDays } from "date-fns";
import { Icon } from "@iconify/react";
import PagePreviousIcon from "@rsuite/icons/PagePrevious";
import PageNextIcon from "@rsuite/icons/PageNext";
import DiscountDetails from "../DiscountDetails/DiscountDetails";
import PriceDetails from "../DiscountDetails/PriceDetails";
import CostPrebookDetails from "../DiscountDetails/CostPrebookDetails";
const LastDayToSaveItemDetailAMP = (props: any) => {
  var {
    userContext,
    groupTypeID,
    vendorID,
    fromDate,
    itemDetailRow,
    isDetail,
    setIsDetail,
    openDetail,
    setOpenDetail,
    itemAmpDetailRow,
  } = props;
  const { item_ID, upc, pack1, department_Store_ID } = itemDetailRow;
  const { Cell } = Table;
  const { chosenGroup, chosenFromDate } = useSelector(
    (state: RootState) => state?.filterSelection
  );
  //groupTypeID = chosenGroup ? chosenGroup : groupTypeID;
  const [itemDetailData, setItemDetailData] = useState([]);
  useEffect(() => {
    GetItemDetailData(moment(fromDate).format("YYYY-MM-DD"));
  }, [itemDetailRow, groupTypeID, vendorID, fromDate, isDetail]);
  const handleClose = () => {
    setOpenDetail(false);
    setIsDetail(false);
  };

  const [isActiveMessage, setIsActiveMessage] = useState(false);
  const [isActiveDiscounts, setIsActiveDiscounts] = useState(false);

  const [itemOrUPCData, setItemOrUPCData] = useState([]);
  const [deliveryDay, setDeliveryDay] = useState([]);

  const [itemIDorUPCValue, setItemIDorUPCValue] = useState("");
  const [queryDate, setQueryDate] = useState(fromDate);
  const handleCloseMessagePopUp = () => {
    setIsActiveMessage(false);
  };

  const handleCloseDiscounts = () => {
    setIsActiveDiscounts(false);
    setItemOrUPCData([]);
  };
  const GetItemDetailData = async (fromDate) => {
    const { handleSpinner, userContext = {} } = props;
    const { store } = userContext;

    handleSpinner && handleSpinner(true);
    ReportService.GetItemAMPDetailLastDayToSave(
      vendorID,
      itemAmpDetailRow?.store_ID,
      fromDate,
      department_Store_ID,
      item_ID,
      upc,
      pack1,
      fromDate
    )
      .then((result) => {
        result.records.map((item) => {
          item.delivery_Date = moment(item.delivery_Date).format("MM/DD/YYYY");
          return item;
        });
        result.records.forEach((obj) => {
          for (let key in obj) {
            if (obj[key] === 0) {
              obj[key] = "";
            }
          }
        });
        setItemDetailData(result.records);
        result.records && setOpenDetail(true);
      })
      .catch((err) => {
        console.error("Error: Get Orders - ", err);
        // toast.error("Error Loading Orders");
      })
      .finally(() => {
        handleSpinner && handleSpinner(false);
      });
  };
  useEffect(() => {
    GetDeliveryScheduleDay(vendorID, itemAmpDetailRow?.store_ID);
  }, [vendorID, itemAmpDetailRow]);
  const GetDeliveryScheduleDay = async (VendorID, StoreID) => {
    const { handleSpinner, userContext = {} } = props;
    const { store } = userContext;

    handleSpinner && handleSpinner(true);
    SettingsService.GetDeliveryScheduleWeekDay(VendorID, StoreID)
      .then((result) => {
        const targetDays = result?.records.map((item) => item.order_day);

        setDeliveryDay(targetDays);
      })
      .catch((err) => {
        console.error("Error: Get Orders - ", err);
        // toast.error("Error Loading Orders");
      })
      .finally(() => {
        handleSpinner && handleSpinner(false);
      });
  };

  const GetItemIDorUPC = async (ItemOrUPC, VendorID) => {
    const { handleSpinner, userContext = {} } = props;
    const { store } = userContext;

    handleSpinner && handleSpinner(true);
    ReportService.GetItemIDorUPC(ItemOrUPC, VendorID)
      .then((result) => {
        setItemOrUPCData(result.records);
      })
      .catch((err) => {
        console.error("Error: Get Orders - ", err);
        // toast.error("Error Loading Orders");
      })
      .finally(() => {
        handleSpinner && handleSpinner(false);
      });
  };
  const EditableCell = ({ rowData, dataKey, onChange, ...props }) => {
    //const editing = useMemo(() => rowData.status === "EDIT", [rowData.status]);

    function isTargetDay(dateStr, targetDays) {
      // Date string format: MM/DD/YYYY
      const date = new Date(dateStr);

      // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
      const dayOfWeek = date.getDay();

      // Convert JavaScript's getDay() result to match your format
      // (0 = Sunday should be 1, 1 = Monday should be 2, ..., 6 = Saturday should be 7)
      const convertedDayOfWeek = dayOfWeek === 0 ? 1 : dayOfWeek + 1;

      // Check if the day of the week is in the targetDays array
      return targetDays.includes(convertedDayOfWeek);
    }
    // const editing =
    //   dataKey === "adJ_Qty" &&
    //   rowData?.delivery_Date >= moment(new Date()).format("MM/DD/YYYY")
    //     ? true
    //     : false;

    const editing =
      dataKey === "adJ_Qty" &&
      isTargetDay(rowData?.delivery_Date, deliveryDay) &&
      rowData?.delivery_Date >= moment(new Date()).format("MM/DD/YYYY")
        ? true
        : false;

    const inputElement = useMemo(() => {
      return (
        <input
          //onKeyUp={(e) => e.preventDefault()}
          type="text"
          style={{ width: "40px", textAlign: "start" }}
          value={rowData[dataKey]}
          //max={rowData["credit_Qty"]}
          className=" py-1 text-center outline-none"
          onChange={(event) => {
            onChange && onChange(rowData.uid, dataKey, event.target.value);
          }}
        />
      );
    }, [rowData, dataKey, onChange]);
    return (
      <Cell {...props} className={editing ? "table-content-editing" : ""}>
        {editing && inputElement}
      </Cell>
    );
  };
  const handleChange = (id, key, value) => {
    value = value ? value : "0";
    var nextDataDiscount = Object.assign([], itemDetailData);
    var activeItemDisc = Array(
      nextDataDiscount.find((item) => item.uid === id)
    );
    activeItemDisc.find((item) => item.uid === id)[key] = value;
    activeItemDisc.find((item) => item.uid === id)["row_Changed"] = true;
    const combinedDisc = updateOrAddObjectByUID(
      nextDataDiscount,
      activeItemDisc[0]
    );
    setItemDetailData(combinedDisc);

    function updateOrAddObjectByUID(array, newObj) {
      // UID'ye göre mevcut objeyi bul
      const existingIndex = array.findIndex((obj) => obj.uid === newObj.uid);

      // Eğer mevcut obje bulunursa, onu güncelle
      if (existingIndex !== -1) {
        array[existingIndex] = newObj;
      } else {
        // Eğer mevcut obje bulunmazsa, yeni objeyi ekle
        array.push(newObj);
      }

      return array;
    }
  };

  const sendData = (value) => {
    for (let index = 0; index < value.length; index++) {
      const element = value[index];
      getCreateData(
        element && Number(element.adJ_Qty) >= 1 && element.row_Changed === true
          ? element
          : null
      );
    }
    setOpenDetail(false);
  };

  const sendAllProcess = () => {
    setIsActiveMessage(false);
    sendData(itemDetailData);
    // setTimeout(() => {
    //   // GetItemDetailDataBefore();
    //   GetItemDetailData();
    // }, 2000);
  };

  const NextWeekQuery = () => {
    var NextWeek = addDays(queryDate, 14);
    setQueryDate(NextWeek);
  };

  const PreviousWeekQuery = () => {
    var PreviousWeek = addDays(queryDate, -14);
    setQueryDate(PreviousWeek);
  };

  const DiscountsPage = () => {
    setIsActiveDiscounts(true);
  };

  useEffect(() => {
    GetItemDetailData(moment(queryDate).format("YYYY-MM-DD"));
  }, [queryDate]);

  const getCreateData = (value1) => {
    // Create AMP Side
    if (value1 && Number(value1.adJ_Qty) !== 0) {
      const newValue = {
        Department_Store_ID: itemDetailRow.department_Store_ID,
        Store_ID: itemAmpDetailRow?.store_ID,
        Vendor_ID: vendorID,
        User_ID: userContext?.user_ID,
        UPC: itemDetailRow.upc ? itemDetailRow.upc : 0,
        Item_ID: itemDetailRow.item_ID ? itemDetailRow.item_ID : 0,
        Status: "0",
        Quantity: Number(value1.adJ_Qty),
        Delivery_Date: value1?.delivery_Date,
        Update_Date: addDays(new Date(), 0),
        Record_Type: 21,
        Module_ID: null,
      };
      ReportService.InsertOutofStockOrder(Array(newValue))
        .then((result) => {})
        .catch((err) => {
          // toast.error("Error Loading Orders");
        })
        .finally(() => {});
    }
  };
  return (
    <>
      <Modal size={"xl"} open={openDetail} onClose={handleClose}>
        <Modal.Header>
          <span
            className="mx-2 filter"
            onClick={() => {
              setIsDetail(false);
            }}
          >
            <Icon icon="ep:back" />
          </span>
          <div style={{ textAlign: "center" }}>
            <Modal.Title>Item Detail</Modal.Title>
          </div>
          <div className="bg-container row m-0 mt-3 p-3">
            <div className="col-1 px-1  p-2 reportDescItem">
              <>
                <p className="m-0 fw-bold">UPC: </p>
                <p className="m-0">{`${itemDetailRow?.upc}`}</p>
              </>
            </div>
            <div className="col-1 px-1  p-2 reportDescItem">
              <>
                <p className="m-0 fw-bold">Item ID: </p>
                <p className="m-0">{`${itemDetailRow?.item_ID}`}</p>
              </>
            </div>
            <div className="col-2 px-1  p-2 reportDescItem">
              <>
                <p className="m-0 fw-bold">Description: </p>
                <p className="m-0">{` ${itemDetailRow?.description}`}</p>
              </>
            </div>
            <div className="col-1 px-1  p-2 reportDescItem">
              <>
                <p className="m-0 fw-bold">Size: </p>
                <p className="m-0">{` ${itemDetailRow?.size}`}</p>
              </>
            </div>

            <div className="col-1 px-1  p-2 reportDescItem">
              <>
                <p className="m-0 fw-bold">Pack: </p>
                <p className="m-0">{`${itemDetailRow?.pack1}`}</p>
              </>
            </div>

            <div className="col-2  px-1  p-2 reportDescItem">
              <>
                <p className="m-0 px-1 fw-bold">Department: </p>
                <p className="m-0">{`${itemDetailRow?.department_Store_Name}`}</p>
              </>
            </div>
            <div className="col-1 px-1  p-2 reportDescItem">
              <>
                <p className="m-0 fw-bold">Store ID: </p>
                <p className="m-0">{`${itemAmpDetailRow?.store_ID}`}</p>
              </>
            </div>
            <div className="col-2  px-1  p-2 reportDescItem">
              <>
                <Button
                  style={{ marginLeft: "10px", marginBottom: "10px" }}
                  // onClick={() => setOpenCreate(true)}
                  onClick={() =>
                    itemDetailData.length >= 1 && setIsActiveMessage(true)
                  }
                  appearance="primary"
                >
                  Save Adjustments and Send
                </Button>
              </>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <LastDayToSaveTable
            isFromModal={false}
            tableDetails={ReportLDTSConstants["AMPDetailConstants"]}
            reportList={itemDetailData}
            iconColumn={""}
            EditableCell={EditableCell}
            handleChange={handleChange}
            onTableRowClick={() => {}}
            reportType={""}
            totalTableRow={{}}
            isTotalRowRequired={false}
          />
        </Modal.Body>
        <div className="d-flex align-content-center gap-3 mt-3">
          <ButtonToolbar>
            <IconButton
              icon={<PagePreviousIcon />}
              onClick={() => PreviousWeekQuery()}
              appearance="primary"
              color="red"
            >
              Prev Week
            </IconButton>
          </ButtonToolbar>

          <ButtonToolbar>
            <IconButton
              placement="right"
              icon={<PageNextIcon />}
              onClick={() => NextWeekQuery()}
              appearance="primary"
              color="green"
            >
              Next Week
            </IconButton>
          </ButtonToolbar>

          <ButtonToolbar>
            <IconButton
              placement="right"
              icon={<PageNextIcon />}
              onClick={() => DiscountsPage()}
              appearance="primary"
              color="green"
            >
              Discounts
            </IconButton>
          </ButtonToolbar>
        </div>
      </Modal>
      <Modal
        size={"400px"}
        open={isActiveMessage}
        onClose={handleCloseMessagePopUp}
      >
        <p className="text-center">Do you want to Save Adjustments and Send?</p>
        <hr />
        <ButtonToolbar className="d-flex justify-content-evenly" justified>
          <Button
            onClick={() => setIsActiveMessage(false)}
            appearance="primary"
            color="red"
          >
            No
          </Button>
          <Button
            appearance="primary"
            color="green"
            onClick={() => sendAllProcess()}
          >
            Yes
          </Button>
        </ButtonToolbar>
      </Modal>

      <Modal
        size={"full"}
        open={isActiveDiscounts}
        onClose={handleCloseDiscounts}
      >
        <Modal.Header>
          <span
            className="mx-2 filter"
            onClick={() => {
              setIsActiveDiscounts(false);
              setItemOrUPCData([]);
            }}
          >
            <Icon icon="ep:back" />
          </span>
          <div className="text-center">Discounts Detail</div>

          <div className="bg-container row m-0 mt-3 p-3">
            <div className="col-1 px-1  p-2 reportDescItem">
              <>
                <p className="m-0 fw-bold">UPC: </p>
                <p className="m-0">{`${
                  itemOrUPCData.length >= 1
                    ? itemOrUPCData[0] && itemOrUPCData[0].upc
                    : itemDetailRow?.upc
                }`}</p>
              </>
            </div>
            <div className="col-1 px-1  p-2 reportDescItem">
              <>
                <p className="m-0 fw-bold">Item ID: </p>
                <p className="m-0">{`${
                  itemOrUPCData.length >= 1
                    ? itemOrUPCData[0] && itemOrUPCData[0].item_ID
                    : itemDetailRow?.item_ID
                }`}</p>
              </>
            </div>
            <div className="col-2 px-1  p-2 reportDescItem">
              <>
                <p className="m-0 fw-bold">Description: </p>
                <p className="m-0">{` ${
                  itemOrUPCData.length >= 1
                    ? itemOrUPCData[0] && itemOrUPCData[0].description
                    : itemDetailRow?.description
                }`}</p>
              </>
            </div>
            <div className="col-1 px-1  p-2 reportDescItem">
              <>
                <p className="m-0 fw-bold">Size: </p>
                <p className="m-0">{` ${
                  itemOrUPCData.length >= 1
                    ? itemOrUPCData[0] && itemOrUPCData[0].size
                    : itemDetailRow?.size
                }`}</p>
              </>
            </div>

            <div className="col-1 px-1  p-2 reportDescItem">
              <>
                <p className="m-0 fw-bold">Pack: </p>
                <p className="m-0">{`${
                  itemOrUPCData.length >= 1
                    ? itemOrUPCData[0] && itemOrUPCData[0].pack
                    : itemDetailRow?.pack1
                }`}</p>
              </>
            </div>
            <div className="col-1 px-1  p-2 reportDescItem">
              <>
                <p className="m-0 fw-bold">Store ID: </p>
                <p className="m-0">{`${itemAmpDetailRow?.store_ID}`}</p>
              </>
            </div>
            <div className="col-1  px-1  p-2 reportDescItem">
              <>
                <p className="m-0 px-1 fw-bold">Vendor: </p>
                <p className="m-0">{`${vendorID}`}</p>
              </>
            </div>
            <div className="col-1  px-1  p-2 reportDescItem">
              <>
                <p className="m-0 px-1 fw-bold">Date: </p>
                <p className="m-0">{`${moment(fromDate).format(
                  "MM/DD/YYYY"
                )}`}</p>
              </>
            </div>
            <div className="col-2 px-1  p-2 reportDescItem">
              <>
                <p className="m-0 fw-bold">Enter Item/UPC </p>
                <Input
                  style={{ maxWidth: "200px" }}
                  onChange={(e) => {
                    setItemIDorUPCValue(e);
                  }}
                ></Input>
                <Button
                  appearance="primary"
                  onClick={() => {
                    GetItemIDorUPC(itemIDorUPCValue, vendorID);
                  }}
                >
                  Enter
                </Button>
              </>
            </div>
          </div>
        </Modal.Header>

        <Modal.Body>
          <div style={{ fontSize: "16px" }} className="text-red text-center ">
            {" "}
            Discounts
          </div>
          <DiscountDetails
            userContext={userContext}
            storeID={itemAmpDetailRow?.store_ID}
            UPC={
              itemOrUPCData.length >= 1
                ? itemOrUPCData[0] && itemOrUPCData[0].upc
                : itemDetailRow?.upc
            }
            vendorID={vendorID}
            ItemID={
              itemOrUPCData.length >= 1
                ? itemOrUPCData[0] && itemOrUPCData[0].item_ID
                : itemDetailRow?.item_ID
            }
            fromDate={fromDate}
          />
          <div style={{ fontSize: "16px" }} className="text-red text-center ">
            {" "}
            Price
          </div>
          <PriceDetails
            userContext={userContext}
            storeID={itemAmpDetailRow?.store_ID}
            UPC={
              itemOrUPCData.length >= 1
                ? itemOrUPCData[0] && itemOrUPCData[0].upc
                : itemDetailRow?.upc
            }
            vendorID={vendorID}
            ItemID={
              itemOrUPCData.length >= 1
                ? itemOrUPCData[0] && itemOrUPCData[0].item_ID
                : itemDetailRow?.item_ID
            }
            fromDate={fromDate}
          />
          <div style={{ fontSize: "16px" }} className="text-red text-center ">
            {" "}
            Cost Prebook
          </div>
          <CostPrebookDetails
            userContext={userContext}
            storeID={itemAmpDetailRow?.store_ID}
            UPC={
              itemOrUPCData.length >= 1
                ? itemOrUPCData[0] && itemOrUPCData[0].upc
                : itemDetailRow?.upc
            }
            vendorID={vendorID}
            ItemID={
              itemOrUPCData.length >= 1
                ? itemOrUPCData[0] && itemOrUPCData[0].item_ID
                : itemDetailRow?.item_ID
            }
            fromDate={fromDate}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LastDayToSaveItemDetailAMP;
