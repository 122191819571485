export const DeliveryScheduleConstansts = {
    "DeliverySchedule": [
        { Header: "Active Depts", DataKey: "department_Store_Name", Width: 100, isFixed: true, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        //{ Header: "Time", DataKey: "deadline_Hour", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Date", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Monday", DataKey: "monday", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "text-center", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Tuesday", DataKey: "tuesday", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "text-center", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Wednesday", DataKey: "wednesday", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "text-center", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Thursday", DataKey: "thursday", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "text-center", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Friday", DataKey: "friday", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "text-center", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Saturday", DataKey: "saturday", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "text-center", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Sunday", DataKey: "sunday", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "text-center", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
       
    ]
}