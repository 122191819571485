import { useState, useEffect } from "react";
import * as UMSService from "../../../services/umsService";
import { Button, Modal, Table } from "rsuite";
import CreateRole from "./CreateRole/CreateRole";
import UpdateRole from "./UpdateRole/UpdateRole";

const RolesList = (props: any) => {
  const { userContext, handleSpinner } = props;
  const { Column, HeaderCell, Cell } = Table;

  const [rolesList, setRolesList] = useState([]);
  const [updateUserData, setUpdateUserData] = useState([]);
  const [uid, setUid] = useState(null);
  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };
  useEffect(() => {
    GetRolesList();
  }, []);

  const GetRolesList = async () => {
    handleSpinner && handleSpinner(true);

    UMSService.GetRolesList()
      .then((result) => {
        //console.log(result);

        result.map((item) => {
          if (item.isAdmin === true) {
            item.isAdmin = "True";
          }
          if (item.isUsers === true) {
            item.isUsers = "True";
          }
        });
        setUid(result[result.length - 1].uid);
        setRolesList(result);

        result && handleSpinner && handleSpinner(false);
      })
      .catch((err) => {
        console.error("Error:  - ", err);
        err && handleSpinner && handleSpinner(false);
        // toast.error("Error Loading Orders");
      });
  };

  const DeleteRole = (userID) => {
    UMSService.DeleteRole(userID)
      .then((result) => {
        //console.log(result);
        result && window.location.reload();
      })
      .catch((err) => {
        console.error("Error:  - ", err);
        // toast.error("Error Loading Orders");
      });
    return console.log(userID.toString());
  };

  const editfunction = (rowdataUID) => {
    setUpdateUserData(rolesList.filter((user) => user.uid === rowdataUID));
    setOpenUpdate(true);
  };
  if (userContext) {
    var { roleName } = userContext;
  }
  if (roleName === "Admin" || roleName === "Super Admin") {
    return (
      <>
        <Button appearance="primary" onClick={() => setOpen(true)}>
          Create New Role
        </Button>

        <Table
          height={800}
          data={rolesList}
          /* onRowClick={(rowData) => {
            //console.log(rowData);
          }} */
        >
          <Column width={200} align="center" fixed>
            <HeaderCell>ID</HeaderCell>
            <Cell dataKey="uid" />
          </Column>

          <Column width={200}>
            <HeaderCell>Role Name</HeaderCell>
            <Cell dataKey="role_Name" />
          </Column>
          {/* 
          <Column width={150}>
            <HeaderCell>Status</HeaderCell>
            <Cell dataKey="status" />
          </Column> */}

          <Column width={200}>
            <HeaderCell>is Admin</HeaderCell>
            <Cell dataKey="isAdmin" />
          </Column>
          <Column width={200}>
            <HeaderCell>is Users</HeaderCell>
            <Cell dataKey="isUsers" />
          </Column>

          <Column width={80} fixed="right">
            <HeaderCell>Edit Role</HeaderCell>

            <Cell style={{ padding: "6px" }}>
              {(rowData) => (
                <Button
                  appearance="link"
                  // onClick={() => alert(`id:${rowData.uid}`)}
                  onClick={() => editfunction(rowData.uid)}
                >
                  Edit
                </Button>
              )}
            </Cell>
          </Column>
          <Column width={120} fixed="right">
            <HeaderCell>Delete Role</HeaderCell>

            <Cell style={{ padding: "6px" }}>
              {(rowData) => (
                <Button
                  color="red"
                  appearance="ghost"
                  onClick={() =>
                    // eslint-disable-next-line no-restricted-globals
                    confirm(`Are you sure to delete ${rowData.role_Name}`) &&
                    DeleteRole(rowData.uid.toString())
                  }
                >
                  Delete
                </Button>
              )}
            </Cell>
          </Column>
        </Table>

        {/* <TableComponentUserList
          isFromModal={false}
          tableDetails={UserListConstants["UserListConstants"]}
          reportList={userList}
          iconColumn={""}
          onTableRowClick={() => {}}
          reportType={""}
          isTotalRowRequired={false}
        /> */}

        <Modal open={open} onClose={handleClose} size="md">
          <Modal.Header></Modal.Header>
          <Modal.Body>
            <div style={{ textAlign: "center" }}>
              <CreateRole
                uid={uid}
                userContext={userContext}
                handleSpinner={handleSpinner}
              />
            </div>
          </Modal.Body>
        </Modal>

        <Modal open={openUpdate} onClose={handleCloseUpdate} size="md">
          <Modal.Header></Modal.Header>
          <Modal.Body>
            <div style={{ textAlign: "center" }}>
              <UpdateRole
                roleData={updateUserData[0]}
                userContext={userContext}
                handleSpinner={handleSpinner}
              />
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  } else {
    return <div>You have to contact Admin</div>;
  }
};
export default RolesList;
