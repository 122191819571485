import { AxiosService } from "../utils/axiosService";
import { stringDecompression } from "./stringLZService";

const API_URL = {
  UserDataByEmail: "Account/GetByLoginUserEmail?username=",
  PagePrivilegesByRoleId: "DefaultPrivileges/Get_OnlyPg_ByRole?roleId=",
  UserFileType: "Account/GetUserFileType",
  UsersGroups: "Account/GetUsersGroups",
  DMStores: "Account/GetDMStores",
};

export const getUserDataByEmail = (email: string) => {
  const urlString = API_URL.UserDataByEmail + email;
  return AxiosService.Get(urlString);
};

export const getPagePrivilegesByRoleId = (roleId: string) => {
  const urlString = API_URL.PagePrivilegesByRoleId + roleId;
  return AxiosService.Get(urlString);
};

export const getUserFileType = (StoreOrGroupID: number, UserType?: string) => {
  const requestObject = { StoreOrGroupID: StoreOrGroupID, UserType: UserType };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.UserFileType, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

//#region Get Users Groups

export const getUsersGroups = (user_ID: number) => {
  const requestObject = { user_ID: user_ID };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.UsersGroups, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion


//#region Get District Manager ( DM ) Stores

export const getDMStores = (user_ID: number) => {
  const requestObject = { user_ID: user_ID };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.DMStores, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion
