import { useState, useRef } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useMediaQuery } from "usehooks-ts";
import * as ReportService from "services/reportServices";
import { Modal } from "rsuite";
import TableComponent from "components/common/tableComponent";
import { ReportConstants } from "components/reports/Placement/SectionsToScanReport/ReportSectionsToScanConstants";
import moment from "moment";
import Chart from "react-apexcharts";
import { Icon } from "@iconify/react";
const MainChartBar = (props: any) => {
  ChartJS.register(ArcElement, Tooltip, Legend);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const chartRef = useRef(null);
  const {
    value,
    totalValue,
    toDate,
    fromDate,
    labels,
    placementMainData,
    storeName,
  } = props;
  const [sectionsData, setSectionsData] = useState([]);
  const [storeID, setStoreID] = useState("");
  const charTextColor = [
    "rgba(20, 36, 89, 0.6)",
    "rgba(23, 107, 160, 0.6)",
    "rgba(25, 170, 222, 0.6)",
    "rgba(26, 201, 230, 0.6)",
    "rgba(27, 212, 212, 0.6)",
    "rgba(29, 228, 189, 0.6)",
    "rgba(199, 249, 238, 0.6)",
  ];
  var newValue = value.map((item) => {
    if (!item) {
      return (item = 0);
    } else return Number(item);
  });
  const options = {
    borderWidth: 0,
    hoverBorderWith: 1,
    borderRadius: 3,
    maintainAspectRatio: useMediaQuery("(max-width: 768px)") ? false : true,

    plugins: {
      datalabels: {
        display: false,
        color: "#575757;",
        font: { weight: 900, size: 10 },
      },
      legend: {
        position: "top" as const,
        display: false,
      },
      title: {
        display: false,
        text: ` Sections to Scan ${Date()} `,
      },

      tooltip: {
        callbacks: {
          label: (ttItem) => `${ttItem.parsed}%`,
        },
        displayColors: false,
      },
    },
  };
  const data = {
    labels: labels,
    datasets: [
      {
        data: newValue,
        backgroundColor: [
          "rgba(20, 36, 89, 0.6)",
          "rgba(23, 107, 160, 0.6)",
          "rgba(25, 170, 222, 0.6)",
          "rgba(26, 201, 230, 0.6)",
          "rgba(27, 212, 212, 0.6)",
          "rgba(29, 228, 189, 0.6)",
          "rgba(199, 249, 238, 0.6)",
        ],
        borderColor: [
          "#186BA1",
          "#19ABDE",
          "#1AC9E6",
          "#1CD4D4",
          "#1DE4BD",
          "#6DF0D1",
          "#C7F9EE",
        ],
        spacing: 20,
        hoverOffset: 2,
      },
    ],
  };

  const getSectionsToScanData = async (storeID: string) => {
    storeID = storeID.split(":")[1];
    setStoreID(storeID);
    ReportService.getSectionsToScan(storeID.toString(), 40)
      .then((result) => {
        modifyReportData(result.allRecords);
        setSectionsData(result.allRecords);
        result && setOpen(true);
        //result.allRecords.length === 0 ? setOpen(false) : setOpen(true);
      })
      .catch((err) => {
        console.error("Error: Get Orders - ", err);
        // toast.error("Error Loading Orders");
      })
      .finally(() => {});
  };
  const modifyReportData = (data) => {
    let modifiedData = data.map((item) => {
      item["need_To_Scan"] =
        item["need_To_Scan"] &&
        moment(item["need_To_Scan"]).format("MM-DD-YYYY");

      return item;
    });
    return modifiedData;
  };
  var colors1 = placementMainData.map((item) => {
    return item.color1;
  });

  colors1 = colors1.filter((item) => item !== "");
  const getUniqueTable = (dataset, value) => {
    if (dataset.length > 0) {
      var uniqueDepartments = new Set(dataset.map((obj) => obj[value]));
      var uniqueDepartmentsString = [...uniqueDepartments].join(",");
      return uniqueDepartmentsString;
    }
  };
  const getFullLabel = (code) => {
    code = code.split(":")[1];

    for (let i = 0; i < storeName.length; i++) {
      if (storeName[i].includes(`${code}`)) {
        return storeName[i];
      }
    }
    return "Label not found"; // Eğer eşleşen etiket bulunamazsa bir hata mesajı döndür
  };
  var optimalColumnWidthPercent =
    40 + 60 / (1 + 30 * Math.exp(-newValue.length / 3));
  const State = {
    series: [
      {
        name: "Placement Sections to Scan Percentage",
        data: newValue.map((item) => {
          return item;
        }),
      },
    ],
    options: {
      chart: {
        animations: {
          enabled: true,
          dynamicAnimation: {
            speed: 1000,
          },
        },
        type: "bar" as "bar",
        events: {
          click(event, chartContext, config) {
            let ID = config.config.xaxis.categories[config.dataPointIndex];
            getSectionsToScanData(ID.toString());
            // setChoosenDateData(grindDataFilteredDate[ID]);
            // setOpen(true);
          },
          mounted: (chart) => {
            chart.windowResizeHandler();
          },
        },
      },
      responsive: [
        {
          breakpoint: 700,

          options: {
            plotOptions: {
              bar: {
                distributed: true, // this line is mandatory
                horizontal: true,
                columnWidth: "90%",
                barHeight: "80%",
                barWidth: "50%",
                dataLabels: {
                  position: "top",
                },
              },
              tooltip: {
                shared: false,
                intersect: false,
                y: {
                  formatter: function (val) {
                    return val + "%"; // Veriyi yüzde formatına dönüştürme
                  },
                },

                x: {
                  formatter: function (val) {
                    return getFullLabel(val); // Özel x değerini bulma fonksiyonunu kullanma
                  },
                },
              },
              // width: 450,
            },
          },
        },
      ],

      // colors: colors1,
      colors: colors1,

      plotOptions: {
        bar: {
          distributed: true, // this line is mandatory
          horizontal: false,
          columnWidth: "90%",
          barHeight: "80%",
          barWidth: "50%",

          dataLabels: {
            position: "top",
          },
        },
      },

      dataLabels: {
        enabled: true,
        style: {
          colors: ["#111"],
          fontSize: "10px",
          fontWeight: 1000,
        },
        formatter: function (val) {
          return val + "%"; // Veriyi yüzde formatına dönüştürme
        },
        background: {
          enabled: false,
          foreColor: "#fff",
          borderWidth: 1,
        },
      },

      // dataLabels: {
      //   enabled: false,
      //   enabledOnSeries: labels.map((item) => {
      //     return item;
      //   }),
      // },
      legend: {
        show: false,
      },
      stroke: {
        show: true,
        width: 4,
      },
      toolbar: {
        show: false,
        offsetX: 0,
        offsetY: 0,
        autoSelected: "zoom",
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: [],
        },
        reset: "Reset Zoom",
      },
      tooltip: {
        shared: false,
        intersect: false,
        y: {
          formatter: function (val) {
            return val + "%"; // Veriyi yüzde formatına dönüştürme
          },
        },

        x: {
          formatter: function (val) {
            return getFullLabel(val); // Özel x değerini bulma fonksiyonunu kullanma
          },
        },
      },

      xaxis: {
        categories: labels.map((item) => {
          return item;
        }),
      },

      yaxis: {
        min: 0,
        max: 100,
        floating: false,
      },
    },
  };

  const startsOfWeek = () => {
    var today = new Date();
    // Haftanın başlangıcını hesapla
    var start = new Date(today);
    start.setDate(start.getDate() - ((start.getDay() + 6) % 7));

    var startsOfWeek =
      ("0" + (start.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + start.getDate()).slice(-2);
    return startsOfWeek;
  };
  return (
    <>
      <Chart
        options={State.options}
        series={State.series}
        type="bar"
        height={670}
        ref={chartRef}
      />
      {/* <div className="chart-heading">
        <div>&nbsp;</div>
      </div>
      <div className="programming-stats  main-chart-programming">
        <div className="chart-container">
          <Chart
            options={State.options}
            series={State.series}
            type="bar"
            //height={auto}
          />
        </div>
        <div className="details">
          <ul>
            {labels.map((label, index) => {
              return (
                <li className="chart-li">
                  <div
                    onClick={(e) => {
                      getSectionsToScanData(e.currentTarget.innerHTML);
                    }}
                    style={{
                      color: charTextColor[index],
                      fontWeight: "700",
                      filter: "brightness(30)",
                    }}
                    className="chart-label"
                  >
                    {label}

                    {""}
                  </div>
                  <span className="percantage">{`   %${newValue[index]}`}</span>
                </li>
              );
            })}
          </ul>
        </div>
      </div> */}

      <Modal
        size={sectionsData.length === 0 ? "lg" : "xl"}
        open={open}
        onClose={handleClose}
      >
        <Modal.Header>
          <span
            className="mx-2 filter"
            onClick={() => {
              setOpen(false);
            }}
          >
            <Icon icon="ep:back" />
          </span>
          {sectionsData.length !== 0 ? (
            <Modal.Title style={{ textAlign: "center" }}>
              {" "}
              {`Section Scan for the week of ${startsOfWeek()}`}
            </Modal.Title>
          ) : null}
        </Modal.Header>
        <Modal.Body>
          {sectionsData.length === 0 ? (
            <div
              style={{ fontSize: "20px", fontWeight: 700, textAlign: "center" }}
            >
              Good Work, No sections need to be scanned you're 100% caught up
              for this week!
            </div>
          ) : (
            <>
              <div className="bg-container row m-0 mt-3 p-3">
                <div className="col-2 p-2 reportDescItem">
                  <div className="label">
                    <p className="m-0">Store ID</p>
                    <p className="m-0">{storeID}</p>
                  </div>
                </div>

                <div className="col-2 p-2 reportDescItem">
                  <div className="label">
                    <p className="m-0">Date</p>
                    <p className="m-0">{moment(Date()).format("MM-DD-YYYY")}</p>
                  </div>
                </div>
              </div>
              <TableComponent
                isFromModal={false}
                tableDetails={ReportConstants["SectionsToScan"]}
                reportList={sectionsData}
                iconColumn={""}
                onTableRowClick={() => {}}
                reportType={""}
                totalTableRow={{}}
                isTotalRowRequired={false}
              />
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MainChartBar;
