/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import * as ReportService from "services/reportServices";
import moment from "moment";
import { TrackItConstants } from "../../reports/TrackItReport/reportTrackItConstants";
import { capitalCase } from "change-case";
import { ReportMaster, TableHeaderConfig } from "models";

import { InputPicker } from "rsuite";

import * as SettingsServices from "services/settingsServices";
import * as UMSService from "services/umsService";
import MainControllerPercentage from "./PlacementPercantage/MainControllerPercantage";
const PlacementContainer = (props: any) => {
  const {
    userContext = {},
    fromDate,
    toDate,
    userStore,
    setUserStore,
    groupTypeID,
  } = props;
  // Tab
  const [selectedTab, setSelectedTab] = useState({
    value: "1",
    label: "Placement Activity",
  });
  const tabsList: any[] = [{ value: "1", label: "Placement Activity" }];
  const [placementMainData, setPlacementMainData] = useState([]);
  const [groupTableTotals, setGroupTableTotals] = useState({});
  ///////////////////////////////
  const [storeID, setStoreID] = useState(userContext ? userContext.store : " ");

  const [storeName, setStoreName] = useState([]);

  const [groupAndGroupID, setGroupAndGroupID] = useState([
    { groupStore_uid: "", groupStore_desc: "" },
  ]);
  const [storeNameHeader, setStoreNameHeader] = useState([]);

  const [awgOrGot, setAwgOrGot] = useState([
    { groupStore_uid: "1", groupStore_desc: "GOT" },
    { groupStore_uid: "2", groupStore_desc: "AWG" },
  ]);
  const [awgOrGotSelect, setAwgOrGotSelect] = useState(1);
  useEffect(() => {
    if (userContext && userContext.roleName === "Super Admin") {
      getVendorStoreName(groupTypeID);
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId !== 0
    ) {
      getVendorStoreName(userContext.groupTypeId);
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0
    ) {
      getVendorStoreName(groupTypeID);
    }
  }, [groupTypeID, userContext]);

  useEffect(() => {
    if (
      userContext &&
      userContext.roleName &&
      userContext.roleName === "Super Admin"
    ) {
      GetGroupAndStoreByTypes(awgOrGotSelect, "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 1
    ) {
      GetGroupAndStoreByTypes("1", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 2
    ) {
      GetGroupAndStoreByTypes("2", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId &&
      userContext.groupType === "" &&
      userContext.store !== 0
    ) {
      getStoreName(userContext.store);
    }
  }, [props.userContext, awgOrGotSelect]);

  //#region Get Group and Store by Types
  const GetGroupAndStoreByTypes = async (IDType, operation) => {
    UMSService.GetGroupAndStoreByTypes(IDType, operation)
      .then((result) => {
        //console.log(result);
        setGroupAndGroupID(result);
      })
      .catch((err) => {
        console.error("Error:  - ", err);
        // toast.error("Error Loading Orders");
      });
  };

  //#region Vendor Store Name

  const getVendorStoreName = async (grouptypeID1 = "") => {
    const { handleSpinner, userContext = {} } = props;
    const { groupTypeId } = userContext;
    handleSpinner && handleSpinner(true);
    SettingsServices.getVendorStoreName(grouptypeID1, "")
      .then((result) => {
        //console.log("StoreName: Result - ", result.records);
        var setArrayID = [];
        var setArrayName = [];
        result.records.filter((item) => {
          if (!setArrayID.includes(item.store_ID)) {
            setArrayID.push(item.store_ID);
          }
        });
        result.records.filter((item) => {
          if (!setArrayName.includes(item.store_Name)) {
            setArrayName.push(item.store_Name);
          }
        });

        setStoreName(setArrayName);
        // setUserStore(setArrayID[0]);
        setStoreID(setArrayID);
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      })
      .finally(() => {
        handleSpinner && handleSpinner(false);
      });
  };

  const getStoreName = (storeID) => {
    SettingsServices.GetAdGroupandGroupID(storeID)
      .then((result) => {
        setStoreNameHeader(result.records[0].description);
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      });
  };

  //////////////////////////////

  const getData = async () => {
    const { groupType, groupTypeId, store, roleName } = userContext;

    if (roleName !== "DM" && roleName === "Super Admin" && userStore === "") {
      try {
        const [ReasonData] = await Promise.all([
          ReportService.getPlacementWeeklyPercentage(
            groupTypeID,
            2,
            moment(Date()).format("MM/DD/YYYY")
          ),
        ]);
        // ReasonData.allRecords=ReasonData.allRecords.filter((item) => {
        //   return item.wK1 !== "";
        // });
        setPlacementMainData(ReasonData.allRecords);
      } catch (err) {
        console.error("Error: Get Reports - ", err);
      } finally {
      }
    } else if (
      roleName !== "DM" &&
      store === 0 &&
      groupTypeID !== 0 &&
      userContext.groupTypeName !== "" &&
      !userStore
    ) {
      try {
        const [ReasonData] = await Promise.all([
          ReportService.getPlacementWeeklyPercentage(
            groupTypeId,
            2,
            moment(Date()).format("MM/DD/YYYY")
          ),
        ]);
        setPlacementMainData(ReasonData.allRecords);
      } catch (err) {
        console.error("Error: Get Reports - ", err);
      } finally {
      }
    } else if (
      roleName !== "DM" &&
      (roleName === "Super Admin" ||
        (store === 0 && groupTypeID !== 0 && userContext.groupTypeName === ""))
    ) {
      try {
        const [ReasonData] = await Promise.all([
          ReportService.getPlacementWeeklyPercentage(
            userStore,
            1,
            moment(Date()).format("MM/DD/YYYY")
          ),
        ]);
        setPlacementMainData(ReasonData.allRecords);
      } catch (err) {
        console.error("Error: Get Reports - ", err);
      } finally {
      }
    } else if (userStore) {
      try {
        const [ReasonData] = await Promise.all([
          ReportService.getPlacementWeeklyPercentage(
            userStore,
            1,
            moment(Date()).format("MM/DD/YYYY")
          ),
        ]);
        setPlacementMainData(ReasonData.allRecords);
      } catch (err) {
        console.error("Error: Get Reports - ", err);
      } finally {
      }
    } else if (roleName !== "DM") {
      const StoreIDUser = store !== 0 ? store : "";
      try {
        const [ReasonData] = await Promise.all([
          ReportService.getPlacementWeeklyPercentage(
            StoreIDUser,
            1,
            moment(Date()).format("MM/DD/YYYY")
          ),
        ]);
        setPlacementMainData(ReasonData.allRecords);
      } catch (err) {
        console.error("Error: Get Reports - ", err);
      } finally {
      }
    }
  };

  useEffect(() => {
    // Loading data

    getData();
  }, [props.userContext, selectedTab, userStore, groupTypeID]);

  useEffect(() => {
    handleDataChange();
  }, [placementMainData]);

  const handleDataChange = () => {
    let obj: { [key: number]: string } = {};

    placementMainData.map(function (order) {
      if (order.store_Group.length > 1) {
        obj["Store:" + order.store_ID] = order.wK1;
      }
    });

    let sort_arr = Object.entries(obj).sort((a, b) => {
      // Değerlerin boş olup olmadığını kontrol et ve sayısal değerleri karşılaştır
      if (
        (a[1] === "" || a[1] === undefined || a[1] === null) &&
        (b[1] === "" || b[1] === undefined || b[1] === null)
      ) {
        return 0;
      } else if (a[1] === "" || a[1] === undefined || a[1] === null) {
        return 1;
      } else if (b[1] === "" || b[1] === undefined || b[1] === null) {
        return -1;
      } else {
        return parseInt(b[1]) - parseInt(a[1]);
      }
    });

    let sort_obj: { [key: number]: string } = {};
    sort_arr.forEach(([key, value]) => {
      sort_obj[parseInt(key)] = value;
    });

    setGroupTableTotals(obj);
    //setDisplayNoDatatlabel(isDataloaded && data.length === 0 ? true : false);
  };

  const data = tabsList.map((item) => {
    return { label: item.label, value: item.value };
  });

  const startsOfWeek = () => {
    var today = new Date();
    // Haftanın başlangıcını hesapla
    var start = new Date(today);
    start.setDate(start.getDate() - ((start.getDay() + 6) % 7));

    var startsOfWeek =
      ("0" + (start.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + start.getDate()).slice(-2);
    return startsOfWeek;
  };
  return (
    <>
      <div className="MainDashboardPlacement">
        <InputPicker
          className="InputPicker"
          onChange={(e) => setSelectedTab({ ...selectedTab, value: String(e) })}
          size="lg"
          placeholder="Placement Activity"
          data={data}
          cleanable={false}
        />
        <div className="placement-items">{`Section Scan for the week of ${startsOfWeek()}`}</div>
      </div>
      {selectedTab.value === "1" && (
        <MainControllerPercentage
          toDate={toDate}
          storeName={storeName}
          groupTableTotals={groupTableTotals}
          fromDate={fromDate}
          placementMainData={placementMainData}
          userContext={userContext}
        />
      )}
    </>
  );
};

export default PlacementContainer;
