/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Dropdown } from "rsuite";
import * as UMSService from "../../../services/umsService";
import { addDays } from "date-fns";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Constants from "../../.././utils/constants";
import TableComponent from "./OrderTableComponent";
import { Icon } from "@iconify/react";
import moment from "moment";
import * as SettingsServices from "../../../services/settingsServices";
import { OrderReceiptConstants } from "./OrderReceiptConstants";
import PageHeaderComponent from "../../../components/common/pageHeaderComponent";

const OrderReceipt = (props: any) => {
  const { handleSpinner, userContext = {} } = props;

  const [VendorStoreName, setVendorStoreName] = useState([]);
  const [receiptData, setReceiptData] = useState([]);
  const [allVendorName, setAllVendorName] = useState([]);
  const [vendorID, setVendorID] = useState(allVendorName[1]);
  const [storeID, setStoreID] = useState(userContext ? userContext.store : " ");

  const [storeName, setStoreName] = useState([]);

  const [groupAndGroupID, setGroupAndGroupID] = useState([
    { groupStore_uid: "", groupStore_desc: "" },
  ]);
  const [storeNameHeader, setStoreNameHeader] = useState([]);

  const [awgOrGot, setAwgOrGot] = useState([
    { groupStore_uid: "1", groupStore_desc: "GOT" },
    { groupStore_uid: "2", groupStore_desc: "AWG" },
  ]);
  const [awgOrGotSelect, setAwgOrGotSelect] = useState(1);
  const [groupTypeID, setGroupTypeID] = useState(null);
  const [userStore, setUserStore] = useState("");
  const [fromDate, setFromDate] = useState<Date>(addDays(new Date(), -1));
  const [toDate, setToDate] = useState<Date>(addDays(new Date(), -1));
  useEffect(() => {
    if (userContext && userContext.roleName === "Super Admin") {
      getVendorStoreName(groupTypeID);
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId !== 0
    ) {
      getVendorStoreName(userContext.groupTypeId);
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0
    ) {
      getVendorStoreName(groupTypeID);
    }
  }, [groupTypeID, userContext]);

  useEffect(() => {
    if (
      userContext &&
      userContext.roleName &&
      userContext.roleName === "Super Admin"
    ) {
      GetGroupAndStoreByTypes(awgOrGotSelect, "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 1 &&
      userContext.store === 0
    ) {
      GetGroupAndStoreByTypes("1", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 2 &&
      userContext.store === 0
    ) {
      GetGroupAndStoreByTypes("2", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.store !== 0
    ) {
      getStoreName(userContext.store);
      setUserStore(userContext.store);
    }
  }, [props.userContext, awgOrGotSelect]);

  useEffect(() => {
    getOrderReceipt(vendorID, userStore, moment(fromDate).format("MM/DD/YYYY"));
  }, [vendorID, userStore, fromDate]);

  const calenderOnClick = (startDate, endDate) => {
    setFromDate(startDate);
    setToDate(endDate);
  };

  //#region Get Group and Store by Types
  const GetGroupAndStoreByTypes = async (IDType, operation) => {
    UMSService.GetGroupAndStoreByTypes(IDType, operation)
      .then((result) => {
        result.sort((a, b) => {
          const Store_A = a.groupStore_desc.toUpperCase();
          const Store_B = b.groupStore_desc.toUpperCase();

          if (Store_A < Store_B) {
            return -1;
          } else if (Store_A > Store_B) {
            return 1;
          } else {
            return 0;
          }
        });
        //console.log(result);
        setGroupAndGroupID(result);
      })
      .catch((err) => {
        console.error("Error:  - ", err);
        // toast.error("Error Loading Orders");
      });
  };

  const getVendorStoreName = async (grouptypeID1 = "") => {
    const { handleSpinner, userContext = {} } = props;
    const { groupTypeId, store } = userContext;
    handleSpinner && handleSpinner(true);
    if (store) {
      SettingsServices.getVendorStoreName(store, "Store")
        .then((result) => {
          //console.log("StoreName: Result - ", result.records);
          var setArrayID = [];
          var setArrayName = [];
          const uniqueVendor = new Set();

          result.records.filter((item) => {
            if (!setArrayID.includes(item.store_ID)) {
              setArrayID.push(item.store_ID);
            }
          });
          result.records.filter((item) => {
            if (!setArrayName.includes(item.store_Name)) {
              setArrayName.push(item.store_Name);
            }
          });
          result.records.forEach((item) => {
            const { vendor_ID, vendor_Name } = item;
            uniqueVendor.add(JSON.stringify({ vendor_ID, vendor_Name }));
          });
          const uniqueVendorData = Array.from(uniqueVendor).map((store) =>
            JSON.parse(store as string)
          );
          setAllVendorName(uniqueVendorData);
          setVendorID(uniqueVendorData[0].vendor_ID.toString());
          setStoreName(setArrayName);
          setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    } else {
      SettingsServices.getVendorStoreName(grouptypeID1, "")
        .then((result) => {
          result.records.sort((a, b) => {
            const Store_A = a.store_ID;
            const Store_B = b.store_ID;

            if (Store_A < Store_B) {
              return -1;
            } else if (Store_A > Store_B) {
              return 1;
            } else {
              return 0;
            }
          });
          //console.log("StoreName: Result - ", result.records);
          var setArrayID = [];
          var setArrayName = [];
          const uniqueVendor = new Set();

          result.records.filter((item) => {
            if (!setArrayID.includes(item.store_ID)) {
              setArrayID.push(item.store_ID);
            }
          });
          result.records.filter((item) => {
            if (!setArrayName.includes(item.store_Name)) {
              setArrayName.push(item.store_Name);
            }
          });
          result.records.forEach((item) => {
            const { vendor_ID, vendor_Name } = item;
            uniqueVendor.add(JSON.stringify({ vendor_ID, vendor_Name }));
          });
          const uniqueVendorData = Array.from(uniqueVendor).map((store) =>
            JSON.parse(store as string)
          );
          setAllVendorName(uniqueVendorData);
          setVendorID(uniqueVendorData[0].vendor_ID.toString());
          setStoreName(setArrayName);
          setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    }
  };

  const getStoreName = (storeID) => {
    SettingsServices.GetAdGroupandGroupID(storeID)
      .then((result) => {
        setStoreNameHeader(result.records[0].description);
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      });
  };

  const SelectGroupType = (event) => {
    setGroupTypeID(event);
    if (storeID) {
      setUserStore(storeID[0]);
    }
  };
  const SelectAwgOrGot = (event) => {
    //console.log(event.target.value);
    setAwgOrGotSelect(event);
  };
  const onOptionChangeHandler = (event) => {
    const VendorAndStore = event;
    setUserStore(storeID[VendorAndStore]);
  };

  const getOrderReceipt = async (Vendor_ID, Store_ID, Date2) => {
    const { handleSpinner, userContext = {} } = props;

    Store_ID = userContext && userContext.store ? userContext.store : Store_ID;
    handleSpinner && handleSpinner(true);
    SettingsServices.GetOrderReceipt(Vendor_ID, Store_ID, Date2)
      .then((result) => {
        result.records.map((record) => {
          record.deadLine_4 = moment(record.deadLine_4).format("MM-DD-YYYY");
          record.cost_Up_Start = moment(record.cost_Up_Start).format(
            "MM-DD-YYYY"
          );
          record.Net = record.cost_W_4 - record.bB_4;
          record.NetNext = record.cost_W_4 - record.bB_4;
          record.Savings = record.f_Qty_0 * record.bB_4;
        });
        setReceiptData(result.records);
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      })
      .finally(() => {
        handleSpinner && handleSpinner(false);
      });
  };

  //#region Excel Export
  const exportToExcel = (excelData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: Constants.fileType });
    FileSaver.saveAs(data, fileName + Constants.fileExtension);
  };
  //#endregion

  const onOptionChangeVendor = (event) => {
    const VendorAndStore = event.toString();
    setVendorID(VendorAndStore);
    //setShowAllData(true);
  };

  return (
    <>
      <div className="d-flex flex-row flex-nowrap justify-content-between align-items-center">
        <PageHeaderComponent
          setGroupTypeID={setGroupTypeID}
          setAwgOrGotSelect={setAwgOrGotSelect}
          setUserStore={setUserStore}
          userContext={userContext}
          groupAndGroupID={groupAndGroupID}
          awgOrGot={awgOrGot}
          storeName={storeName}
          storeNameHeader={storeNameHeader}
          storeID={storeID}
          fromDate={fromDate}
          toDate={toDate}
          onOptionChangeVendor={onOptionChangeVendor}
          setFromDate={setFromDate}
          setToDate={setToDate}
          isVendor={true}
          allVendorName={allVendorName}
          isCalendar={true}
          isDepartment={false}
        />
        <Dropdown
          renderToggle={(_props, _ref) => (
            <div className="filter" ref={_ref} {..._props}>
              <Icon icon="pajamas:export" /> Export
            </div>
          )}
          className="ms-2 me-0 button-fixed"
          appearance="link"
          placement="bottomEnd"
        >
          <Dropdown.Item
            icon={<FileDownloadIcon />}
            onClick={() => {
              exportToExcel(
                receiptData,
                `Order Receipt ${userStore} ${vendorID}`
              );
            }}
          >
            Export to Excel
          </Dropdown.Item>
        </Dropdown>
      </div>

      <TableComponent
        isFromModal={false}
        tableDetails={OrderReceiptConstants["OrderReceiptConstants"]}
        reportList={receiptData}
        iconColumn={"NA"}
        onTableRowClick={() => {}}
        reportType={""}
        totalTableRow={{}}
        isTotalRowRequired={false}
      />
    </>
  );
};

export default OrderReceipt;
