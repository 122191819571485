/* eslint-disable react-hooks/exhaustive-deps */
import { Dropdown, Input, InputGroup } from "rsuite";
import { useState, useEffect } from "react";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import { Icon } from "@iconify/react";
import SearchIcon from "@rsuite/icons/Search";
import TableComponent from "../../../common/tableComponent";

const ReportMarkdownDetailComponent = (props: any) => {
  const {
    clickedFilterData,
    clickedFilterDataMaster,
    tableDetails,
    reportList,
    exportToExcel,
    totalTableRow,
    DisplayNoDatatlabel,
    isTableCustomStriped,
    isTotalRowRequired,
  } = props;
  useEffect(() => {
    setAllFilteredData(reportList);
  }, [reportList]);
  const [allFilteredData, setAllFilteredData] = useState([]);
  const filterList = (value) => {
    var regex = /^[a-zA-Z]+$/;
    if (value) {
      if (!value.match(regex)) {
        setAllFilteredData(
          reportList.filter((item) =>
            item.upC_Scanned
              .toString()
              .toLowerCase()
              .includes(value.toString().toLowerCase())
          )
        );
      } else {
        setAllFilteredData(
          reportList.filter((item) =>
            item.Description.toLowerCase().includes(
              value.toString().toLowerCase()
            )
          )
        );
      }
    } else {
      setAllFilteredData(reportList);
    }
  };
  return (
    <>
      <div className="bg-container row m-0 mt-3 p-3">
        <div className="col-2 p-2 reportDescItem">
          <div className="icon">
            <Icon icon="material-symbols:storefront-outline" />
          </div>
          <div className="label">
            <p className="m-0">{"Store ID"}</p>
            <p className="m-0">
              {clickedFilterDataMaster
                ? clickedFilterDataMaster.store_ID ?? "All"
                : clickedFilterData
                ? clickedFilterData.store_ID === ""
                  ? "All"
                  : clickedFilterData.store_ID
                : "All"}
            </p>
          </div>
        </div>
        <div className="col-2 p-2 reportDescItem">
          <div className="icon">
            <Icon icon="material-symbols:store-outline" />
          </div>
          <div className="label">
            <p className="m-0">Department</p>
            <p className="m-0">
              {clickedFilterData &&
                (clickedFilterData.department ?? "All").trim()}
            </p>
          </div>
        </div>
        <div className="col-3 p-2 reportDescItem">
          <div className="label">
            <p className="m-0">
              <InputGroup
                onChange={(event) => {
                  filterList(event.target.value);
                }}
                style={{
                  width: 300,
                  height: 40,
                  fontWeight: 800,
                }}
              >
                <Input placeholder="Filter Description or UPC" />
                <InputGroup.Button>
                  <SearchIcon />
                </InputGroup.Button>
              </InputGroup>
            </p>
          </div>
        </div>

        <div className="col-1 p-2 reportDescItem">
          <div className="d-flex">
            <Dropdown
              className="mx-2 button-fixed"
              title="Export"
              appearance="primary"
              placement="bottomEnd"
            >
              <Dropdown.Item
                icon={<FileDownloadIcon />}
                onClick={() => {
                  exportToExcel(reportList, "Detailed Order Report");
                }}
              >
                Export to Excel
              </Dropdown.Item>
            </Dropdown>
          </div>
        </div>
      </div>
      <TableComponent
        isFromModal={true}
        tableDetails={tableDetails}
        reportList={allFilteredData.sort((a, b) => {
          return a["store_ID"]
            .toString()
            .localeCompare(b["store_ID"].toString(), undefined, {
              numeric: true,
            });
        })}
        iconColumn={""}
        onTableRowClick={() => {}}
        totalTableRow={totalTableRow}
        isTotalRowRequired={isTotalRowRequired}
        DisplayNoDatatlabel={DisplayNoDatatlabel}
        isTableCustomStriped={isTableCustomStriped}
      />
    </>
  );
};

export default ReportMarkdownDetailComponent;
