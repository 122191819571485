
import { TableHeaderConfig } from "../../../models"

export const OrderInvoiceConstants = {
    "OrderInvoiceConstants": [
        {...new TableHeaderConfig(), Header: "Conf#", DataKey: "confirmation_ID", Width: 100, isFixed: true, isSearch: true, searchGroup: [1, 2]},

        {...new TableHeaderConfig(), Header: "User ID", DataKey: "user_ID", Width: 100, isSearch: true, searchGroup: [1, 2]},
        {...new TableHeaderConfig(), Header: "Ordered Date", DataKey: "update_Date",TypeOfData: "Date", Width: 100, isSearch: true, searchGroup: [1, 2]},
        {...new TableHeaderConfig(), Header: "Delivery Date", DataKey: "delivery_Date",TypeOfData: "Date", Width: 100, isSearch: true, searchGroup: [1, 2]},
        {...new TableHeaderConfig(), Header: "Department", DataKey: "department_Store_Name", Width: 100, isSearch: true, searchGroup: [1, 2]},
        {...new TableHeaderConfig(), Header: "Vendor", DataKey: "vendor_Name", Width: 100, isSearch: true, searchGroup: [1, 2]},
        {...new TableHeaderConfig(), Header: "Items", DataKey: "lines_New", Width: 100, isSearch: true, searchGroup: [1, 2]},
        {...new TableHeaderConfig(), Header: "Pieces", DataKey: "pieces_New", Width: 100, isSearch: true, searchGroup: [1, 2]},

        {...new TableHeaderConfig(), Header: "Delivery Cost", DataKey: "", Width: 210, isSortable: false, TypeOfData: "", colSpan: 4 },
        {...new TableHeaderConfig(), Header: "Cost",TypeOfData: "Price", DataKey: "totalCost", Width: 50, isSubHeader: true},
        {...new TableHeaderConfig(), Header: "AMP",TypeOfData: "Price", DataKey: "totalAmp", Width: 70, isSubHeader: true},
        {...new TableHeaderConfig(), Header: "BB", TypeOfData: "Price",DataKey: "TotalBB", Width: 70,  isSubHeader: true},
        {...new TableHeaderConfig(), Header: "Net",TypeOfData: "Price", DataKey: "totalNet", Width: 70,  isSubHeader: true},


        {...new TableHeaderConfig(), Header: "Potential Savings",Align:"right", DataKey: "PotentialTrue", TypeOfData: "Price", Width: 100, isSearch: true, searchGroup: [1, 2]},
        {...new TableHeaderConfig(), Header: "True Savings",Align:"right", DataKey: "TotalTrue", TypeOfData: "Price", Width: 100, isSearch: true, searchGroup: [1, 2]},



    ],
     "OrderInvoiceConstantsDetail": [

        //{...new TableHeaderConfig(), Header: "GOT Conf#", DataKey: "confirmation_ID", Width: 100, isFixed: false, isSearch: true, searchGroup: [1, 2]},
       // {...new TableHeaderConfig(), Header: "Department", DataKey: "department_Store_Name", Width: 100, isSearch: true, searchGroup: [1, 2]},

       //{...new TableHeaderConfig(), Header: "UPC", DataKey: "upc", Width: 100, isSearch: true, searchGroup: [1, 2]},
       {...new TableHeaderConfig(), Header: "UPC", DataKey: "upc", Width: 100, isFixed: true, isSearch: true, searchGroup: [1, 2]},
        {...new TableHeaderConfig(), Header: "Item Code", DataKey: "item_ID",TypeOfData: "Number", Width: 100, isSearch: true, searchGroup: [1, 2]},
        {...new TableHeaderConfig(), Header: "Description", DataKey: "description", Width: 100, isSearch: true, searchGroup: [1, 2]},
        {...new TableHeaderConfig(), Header: "Size", DataKey: "size", Width: 50,TypeOfData: "Number", isSearch: true, searchGroup: [1, 2]},
        {...new TableHeaderConfig(), Header: "Pack", DataKey: "pack", Width: 50,TypeOfData: "Number", isSearch: true, searchGroup: [1, 2]},
        {...new TableHeaderConfig(), Header: "Ordered", DataKey: "quantity",TypeOfData: "Number", Width: 50, isSearch: true, searchGroup: [1, 2]},





        



        {...new TableHeaderConfig(), Header: "Potential Savings", DataKey: "", Width: 210, isSortable: false, TypeOfData: "", colSpan: 5 },
        {...new TableHeaderConfig(), Header: "Cost",TypeOfData: "Price", DataKey: "cost", Width: 50, isSubHeader: true},
        {...new TableHeaderConfig(), Header: "AMP",TypeOfData: "Price", DataKey: "amp", Width: 70, isSubHeader: true},
        {...new TableHeaderConfig(), Header: "BB", TypeOfData: "Price",DataKey: "bb", Width: 70,  isSubHeader: true},
        {...new TableHeaderConfig(), Header: "Net",TypeOfData: "Price", DataKey: "Net", Width: 70,  isSubHeader: true},
        {...new TableHeaderConfig(), Header: "Savings",TypeOfData: "Price", DataKey: "savings1", Width: 70,  isSubHeader: true},


         {...new TableHeaderConfig(), Header: "", DataKey: "", Width: 10, isFixed: false,isSortable: false, isSearch: false},

        {...new TableHeaderConfig(), Header: "True Savings", DataKey: "", Width: 210, isSortable: false, TypeOfData: "", colSpan: 7 },
        {...new TableHeaderConfig(), Header: "Qty Invoiced",TypeOfData: "Number", DataKey: "quantityInvoice", Width: 50, isSubHeader: true},
        {...new TableHeaderConfig(), Header: "Invoice Number",TypeOfData: "Number", DataKey: "invoice_Number", Width: 50, isSubHeader: true},
        {...new TableHeaderConfig(), Header: "Cost",TypeOfData: "Price", DataKey: "cost", Width: 50, isSubHeader: true},
        {...new TableHeaderConfig(), Header: "AMP",TypeOfData: "Price", DataKey: "trueamp", Width: 70, isSubHeader: true},
        {...new TableHeaderConfig(), Header: "BB",TypeOfData: "Price",  DataKey: "truebb" ,Width: 70,  isSubHeader: true},
        {...new TableHeaderConfig(), Header: "Net", DataKey: "netInvoice", Width: 70, TypeOfData: "Price", isSubHeader: true},
        {...new TableHeaderConfig(), Header: "Savings", DataKey: "savingtrue", Width: 70, TypeOfData: "Price", isSubHeader: true},




    ] 


}