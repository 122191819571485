import { useState, useRef } from "react";
import {
  Form,
  Button,
  Divider,
  Row,
  Col,
  Checkbox,
  ButtonToolbar,
} from "rsuite";
import * as UMSService from "../../../../services/umsService";
import { toast } from "react-toastify";

const CreateRole = (props: any) => {
  const { userContext } = props;
  const formRef = useRef<any>();
  const checkStatusRef = useRef<any>();
  const checkAdminsRef = useRef<any>();
  const checkUsersRef = useRef<any>();

  const initFormValue = {
    Role_Name: null,
    IsUsers: false,
    IsAdmin: false,
    Status: false,
  };

  const [formValue, setFormValue] = useState(initFormValue);
  const handleSubmit = () => {
    if (!formRef.current.check()) {
      console.error("Form error");
      return;
    }
    const obj = {
      ...initFormValue,
      ...formValue,
      Status: true,
      IsAdmin: checkAdminsRef.current.checked,
      IsUsers: checkUsersRef.current.checked,
    };

    //console.log(obj);

    InsertNewRole(obj);
  };

  const InsertNewRole = async (userData) => {
    UMSService.InsertNewRole(userData)
      .then((result) => {
        result && toast.success("Role Created Successfully");
        result && window.location.reload();
      })
      .catch((err) => {
        console.error("Error:  - ", err);
        // toast.error("Error Loading Orders");
      });
  };

  if (userContext) {
    var { roleName } = userContext;
  }

  if (roleName === "Admin" || roleName === "Super Admin") {
    return (
      <>
        <Row className="show-grid">
          <Col xs={24}>
            <Form
              ref={formRef}
              //layout={"inline"}
              onChange={setFormValue}
              onSubmit={handleSubmit}
            >
              <Form.Group controlId="Role_Name">
                <Form.ControlLabel>Role Name</Form.ControlLabel>
                <Form.Control name="Role_Name" style={{ width: 360 }} />
                <Form.HelpText tooltip>Required</Form.HelpText>
              </Form.Group>
              {/*               <CheckboxGroup inline name="checkboxList">
                <Checkbox name="isAdmin" value="A">
                  Item A
                </Checkbox>
                <Checkbox value="B">Item B</Checkbox>
                <Checkbox value="C">Item C</Checkbox>
                <Checkbox value="D" disabled>
                  Item D
                </Checkbox>
              </CheckboxGroup> */}
              <Form.Group controlId="IsAdmin">
                <Form.ControlLabel>Admin</Form.ControlLabel>
                <Form.Control
                  name="IsAdmin"
                  accepter={Checkbox}
                  inputRef={checkAdminsRef}
                  inline
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="IsUsers">
                <Form.ControlLabel>Users</Form.ControlLabel>
                <Form.Control
                  name="IsUsers"
                  inputRef={checkUsersRef}
                  accepter={Checkbox}
                  inline
                ></Form.Control>
              </Form.Group>{" "}
              <Form.Group controlId="Status">
                <Form.ControlLabel>Status</Form.ControlLabel>
                <Form.Control
                  name="Status"
                  inputRef={checkStatusRef}
                  accepter={Checkbox}
                  checked
                  value={"true"}
                  inline
                ></Form.Control>
              </Form.Group>
              <ButtonToolbar>
                <Button
                  style={{ width: 360, marginLeft: 190 }}
                  appearance="primary"
                  color="green"
                  type="submit"
                >
                  Create Role
                </Button>
              </ButtonToolbar>
            </Form>
            <Divider />
          </Col>
        </Row>
      </>
    );
  } else {
    return <div>You have to contact Admin</div>;
  }
};

export default CreateRole;
