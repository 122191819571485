/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import {
  Button,
  CheckPicker,
  Dropdown,
  IconButton,
  Modal,
  PickerHandle,
  SelectPicker,
  InputPicker,
} from "rsuite";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import * as ReportService from "../../../../services/reportServices";
import Constants from "../../../.././utils/constants";
import TableComponent from "../../../common/tableComponent";
import Calendar from "../../../common/CalendarComponent";
import { Icon } from "@iconify/react";
import moment from "moment";
import { TrackItConstants } from "../reportTrackItConstants";
import ReportTrackItDetailComponent from "./reportTrackItDetailComponent";
import { capitalCase } from "change-case";
import { ReportMaster, TableHeaderConfig } from "../../../../models";
import { addDays } from "date-fns";
import * as AuthService from "../../../../services/authService";
import * as SettingsServices from "../../../../services/settingsServices";
import * as UMSService from "../../../../services/umsService";

const ReportTrackItByGroupComponent = (props: any) => {
  //#region Props
  const { handleSpinner, userContext = {} } = props;

  const { groupType } = userContext ?? {};
  //#endregion

  //#region Report API Filters
  // Tab
  const [selectedTab, setSelectedTab] = useState({
    value: "1",
    label: "Shrink",
  });

  // Calendar
  const [fromDate, setFromDate] = useState<Date>(addDays(new Date(), -1));
  const [toDate, setToDate] = useState<Date>(addDays(new Date(), -1));

  // Switch
  const [reportTypeSelected, setReportTypeSelected] = useState("Reason");

  // Show Details
  const [isDetailView, setDetailView] = useState(false);
  const scrollref = useRef<HTMLDivElement>();

  //#endregion

  //#region Reports Master Lists
  const [reasonReportData, setReasonReportData] = useState(new ReportMaster());
  const [reasonDetailReportData, setReasonDetailReportData] = useState(
    new ReportMaster()
  );
  const [weekReportData, setWeekReportData] = useState(new ReportMaster());
  const [donationReportData, setDonationReportData] = useState(
    new ReportMaster()
  );
  //#endregion
  //#region Reports Tables Config
  // Headers
  const [groupTableHeaders, setGroupTableHeaders] = useState([]);
  const [listTableHeaders, setListTableHeaders] = useState([]);
  const [detailTableHeaders, setDetailTableHeaders] = useState([]);
  // Totals
  const [groupTableTotals, setGroupTableTotals] = useState([]);
  const [listTableTotals, setListTableTotals] = useState([]);
  const [detailTableTotals, setDetailTableTotals] = useState([]);
  // reports List
  const [groupTableReports, setGroupTableReports] = useState([]);
  const [listTableReports, setListTableReports] = useState([]);
  const [detailTableReports, setDetailTableReports] = useState([]);
  // No Data found
  console.log(detailTableReports);
  const [DisplayNoDatatlabel, setDisplayNoDatatlabel] = useState(false);
  const [isDataloaded, setIsDataloaded] = useState(false);
  //#endregion
  //#region Filters
  const [columnFilters, setColumnFilters] = useState([]);
  const [filterCount, setFilterCount] = useState({});
  const [isfilterSelected, setFilterSelected] = useState(false);
  const [clickedFilterData, setClickedFilterData] = useState(null);
  const [clickedFilterDataMaster, setClickedFilterDataMaster] = useState(null);
  const [selectedColumnFilters, setSelectedColumnFilters] = useState({});
  const [isCollapsed, setIsCollapsed] = useState(true);

  const picker = useRef<PickerHandle>();
  const [open, setOpen] = useState(false);
  const [size, setSize] = useState();
  //#endregion

  ///////////////////////////////
  const [storeID, setStoreID] = useState(userContext ? userContext.store : " ");

  const [storeName, setStoreName] = useState([]);

  const [groupAndGroupID, setGroupAndGroupID] = useState([
    { groupStore_uid: "", groupStore_desc: "" },
  ]);
  const [storeNameHeader, setStoreNameHeader] = useState([]);

  const [awgOrGot, setAwgOrGot] = useState([
    { groupStore_uid: "1", groupStore_desc: "GOT" },
    { groupStore_uid: "2", groupStore_desc: "AWG" },
  ]);
  const [awgOrGotSelect, setAwgOrGotSelect] = useState(1);
  const [groupTypeID, setGroupTypeID] = useState(null);
  const [userStore, setUserStore] = useState("");
  useEffect(() => {
    if (userContext && userContext.roleName === "Super Admin") {
      getVendorStoreName(groupTypeID);
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId !== 0
    ) {
      getVendorStoreName(userContext.groupTypeId);
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0
    ) {
      getVendorStoreName(groupTypeID);
    }
  }, [groupTypeID, userContext]);

  useEffect(() => {
    if (
      userContext &&
      userContext.roleName &&
      userContext.roleName === "Super Admin"
    ) {
      GetGroupAndStoreByTypes(awgOrGotSelect, "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 1
    ) {
      GetGroupAndStoreByTypes("1", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 2
    ) {
      GetGroupAndStoreByTypes("2", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId &&
      userContext.groupType === "" &&
      userContext.store !== 0
    ) {
      getStoreName(userContext.store);
    }
  }, [props.userContext, awgOrGotSelect]);

  useEffect(() => {
    if (
      props.userContext &&
      props.userContext.store &&
      props.userContext.store !== 0
    ) {
      getStoreName(props.userContext.store);
    }
  }, [props.userContext]);

  //#region Get Group and Store by Types
  const GetGroupAndStoreByTypes = async (IDType, operation) => {
    UMSService.GetGroupAndStoreByTypes(IDType, operation)
      .then((result) => {
        if (IDType === "2") {
          result.map((item) => {
            return (item.groupStore_desc =
              item.groupStore_uid + " / " + item.groupStore_desc);
          });
        }
        result.sort((a, b) => {
          const Store_A = a.groupStore_desc.toUpperCase();
          const Store_B = b.groupStore_desc.toUpperCase();

          if (Store_A < Store_B) {
            return -1;
          } else if (Store_A > Store_B) {
            return 1;
          } else {
            return 0;
          }
        });
        setGroupAndGroupID(result);
      })
      .catch((err) => {
        console.error("Error:  - ", err);
        // toast.error("Error Loading Orders");
      });
  };

  //#region Vendor Store Name

  const getVendorStoreName = async (grouptypeID1 = "") => {
    const { handleSpinner, userContext = {} } = props;
    const { groupTypeId } = userContext;
    handleSpinner && handleSpinner(true);
    SettingsServices.getVendorStoreName(grouptypeID1, "")
      .then((result) => {
        result.records.sort((a, b) => {
          const Store_A = a.store_ID;
          const Store_B = b.store_ID;

          if (Store_A < Store_B) {
            return -1;
          } else if (Store_A > Store_B) {
            return 1;
          } else {
            return 0;
          }
        });
        //console.log("StoreName: Result - ", result.records);
        var setArrayID = [];
        var setArrayName = [];
        result.records.filter((item) => {
          if (!setArrayID.includes(item.store_ID)) {
            setArrayID.push(item.store_ID);
          }
        });
        result.records.filter((item) => {
          if (!setArrayName.includes(item.store_Name)) {
            setArrayName.push(item.store_Name);
          }
        });

        setStoreName(setArrayName);
        //setUserStore(setArrayID[0]);
        setStoreID(setArrayID);
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      })
      .finally(() => {
        handleSpinner && handleSpinner(false);
      });
  };

  const getStoreName = (storeID) => {
    SettingsServices.GetAdGroupandGroupID(storeID)
      .then((result) => {
        setStoreNameHeader(result.records[0].description);
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      });
  };

  const SelectGroupType = (event) => {
    setGroupTypeID(event);
    setUserStore("");
    // if (storeID) {
    //   setUserStore(storeID[0]);
    // }
  };
  const SelectAwgOrGot = (event) => {
    //console.log(event.target.value);
    setAwgOrGotSelect(event);
  };
  const onOptionChangeHandler = (event) => {
    const VendorAndStore = event;
    setUserStore(storeID[VendorAndStore]);
  };

  //////////////////////////////

  const tabsList: any[] = [
    { value: "1", label: "Shrink" },
    { value: "3", label: "Store Use" },
    { value: "6", label: "Store Transfer" },
    // { value: "5", label: "Discounts" },
    { value: "2", label: "Recall" },
    { value: "4", label: "Reclaim" },
    { value: "7", label: "Insurance Claim" },
    { value: "8", label: "Donation" },
  ];
  useEffect(() => {
    // Reset before loading data
    setDetailView(false);
    setGroupTableHeaders([]);
    setGroupTableTotals([]);
    setGroupTableReports([]);
    setDisplayNoDatatlabel(false);
    // Loading data
    if (selectedTab.value !== "8") {
      getData();
    } else {
      getDonationReportData();
    }
  }, [
    props.userContext,
    selectedTab,
    fromDate,
    toDate,
    userStore,
    groupTypeID,
  ]);

  useEffect(() => {
    handleDataChange();
  }, [
    reasonReportData,
    weekReportData,
    donationReportData,
    reportTypeSelected,
  ]);

  useEffect(() => {
    setIsCollapsed(false);
  }, []);

  useEffect(() => {
    columnFiltersData();
  }, [groupTableHeaders, selectedTab, reportTypeSelected]);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };
  const getData = async () => {
    const { groupType, groupTypeId, store, roleName, user_ID } = userContext;
    handleSpinner && handleSpinner(true);

    if (roleName === "Super Admin" && userStore === "") {
      try {
        const [ReasonData, WeekData] = await Promise.all([
          ReportService.getReportTrackIt(
            selectedTab.value,
            groupTypeID,
            1,
            moment(fromDate).format("YYYY-MM-DD"),
            moment(toDate).format("YYYY-MM-DD"),
            ""
          ),
          ReportService.getReportTrackItWeek(
            selectedTab.value,
            groupTypeID,
            1,
            moment(fromDate).format("YYYY-MM-DD"),
            moment(toDate).format("YYYY-MM-DD"),
            ""
          ),
        ]);
        const _reasonData = handleReasonData(ReasonData);

        const _weekData = handleWeekData(WeekData);
        setReasonReportData(_reasonData);
        setWeekReportData(_weekData);
        setIsDataloaded(true);
      } catch (err) {
        console.error("Error: Get Reports - ", err);
      } finally {
        handleSpinner && handleSpinner(false);
      }
    } else if (
      roleName === "Super Admin" ||
      (store === 0 && groupTypeID !== 0 && userContext.groupTypeName === "")
    ) {
      try {
        const [ReasonData, WeekData] = await Promise.all([
          ReportService.getReportTrackIt(
            selectedTab.value,
            groupTypeId,
            groupType,
            moment(fromDate).format("YYYY-MM-DD"),
            moment(toDate).format("YYYY-MM-DD"),
            userStore
          ),
          ReportService.getReportTrackItWeek(
            selectedTab.value,
            groupTypeId,
            groupType,
            moment(fromDate).format("YYYY-MM-DD"),
            moment(toDate).format("YYYY-MM-DD"),
            userStore
          ),
        ]);

        const _reasonData = handleReasonData(ReasonData);
        const _weekData = handleWeekData(WeekData);

        setReasonReportData(_reasonData);
        setWeekReportData(_weekData);
        setIsDataloaded(true);
      } catch (err) {
        console.error("Error: Get Reports - ", err);
      } finally {
        handleSpinner && handleSpinner(false);
      }
    } else {
      const StoreIDUser = store !== 0 ? store : "";
      var storesArray = [];
      AuthService.getDMStores(user_ID)
        .then((result1) => {
          result1.records.map((item) => storesArray.push(item.stores));
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        });
      try {
        const [ReasonData, WeekData] = await Promise.all([
          ReportService.getReportTrackIt(
            selectedTab.value,
            groupTypeId,
            groupType,
            moment(fromDate).format("YYYY-MM-DD"),
            moment(toDate).format("YYYY-MM-DD"),
            StoreIDUser
          ),
          ReportService.getReportTrackItWeek(
            selectedTab.value,
            groupTypeId,
            groupType,
            moment(fromDate).format("YYYY-MM-DD"),
            moment(toDate).format("YYYY-MM-DD"),
            StoreIDUser
          ),
        ]);
        if (storesArray.length >= 1) {
          ReasonData.records = ReasonData.records.filter((item) =>
            storesArray.includes(item.store_ID.toString())
          );
          ReasonData.groupedMetadata = ReasonData.groupedMetadata.filter(
            (item) => storesArray.includes(item.store_ID.toString())
          );
        }
        if (storesArray.length >= 1) {
          WeekData.recordsByWeek = WeekData.recordsByWeek.filter((item) =>
            storesArray.includes(item.store_ID.toString())
          );
          WeekData.groupedMetadata = WeekData.groupedMetadata.filter((item) =>
            storesArray.includes(item.store_ID.toString())
          );
        }

        const _reasonData = handleReasonData(ReasonData);
        const _weekData = handleWeekData(WeekData);
        setReasonReportData(_reasonData);
        setWeekReportData(_weekData);
        setIsDataloaded(true);
      } catch (err) {
        console.error("Error: Get Reports - ", err);
      } finally {
        handleSpinner && handleSpinner(false);
      }
    }
  };

  const handleDataChange = () => {
    let headers = [];
    let data = [];
    let totals = [];
    if (selectedTab.value !== "8") {
      if (reportTypeSelected === "Reason") {
        headers = getReasonHeaders(reasonReportData.headers, true);
        data = reasonReportData.groupedList;
        totals = getTotalRowData(data, headers);
      } else {
        headers = getWeekHeaders(weekReportData.headers, true);
        data = weekReportData.groupedList;
        totals = getTotalRowData(data, headers);
      }
    } else {
      headers = TrackItConstants["Donation"];
      data = donationReportData.groupedList;
      totals = getTotalRowData(data, headers);
    }

    setGroupTableHeaders(headers);
    setGroupTableTotals(totals);
    setGroupTableReports(data);
    setDisplayNoDatatlabel(isDataloaded && data.length === 0 ? true : false);
  };

  //#region Common Functions
  const getTotalRowData = (reportList, headers: Array<any>) => {
    const totalObj: any = {};
    headers.forEach((res) => {
      const total = reportList?.reduce(
        (acc, item) => acc + (Number(item[res.DataKey]) || 0),
        0
      );
      totalObj[res.DataKey] =
        res.TypeOfData === "Number" || res.TypeOfData === "Price"
          ? res.TypeOfData === "Price"
            ? Number(total)
            : Number(total.toFixed(2))
          : "";
    });

    return totalObj;
  };

  const scroll = (scrollOffset) => {
    scrollref.current.scrollLeft += scrollOffset;
  };

  const calenderOnClick = (startDate, endDate) => {
    setFromDate(startDate);
    setToDate(endDate);
  };
  //#endregion

  //#region Reason Data
  const handleReasonData = (reasonResponse: any) => {
    const {
      groupedMetadata = [],
      reasonHeaders = [],
      records = [],
    } = reasonResponse;
    const _reasonMaster = { ...reasonReportData };
    _reasonMaster.headers = reasonHeaders;
    _reasonMaster.groupedList = getReasonGroupData(
      reasonHeaders,
      groupedMetadata
    );
    _reasonMaster.reportList = records.map((item) => {
      item["FormatedDate"] =
        item.date && moment(item.date).format("MM-DD-YYYY");
      item["Badge_Type"] =
        item.price_Adj !== 0 ? "Manual Price Keyed" : "POS Price entered";
      item.reasonPrice.forEach((value) => {
        item[value.reason_Description] = value.price ?? 0;
      });
      item["total"] = Number(
        item.reasonPrice.reduce((acc, item) => acc + item.price, 0).toFixed(2)
      );
      return item;
    });

    return _reasonMaster;
  };

  const getReasonHeaders = (reasonHeaders, isGroup = false) => {
    const headers = reasonHeaders.map((data) => {
      return {
        ...new TableHeaderConfig(),
        Header: capitalCase(data),
        DataKey: data,
        TypeOfData: "Price",
      };
    });
    const headerStart =
      selectedTab.value === "6"
        ? TrackItConstants[isGroup ? "GroupStoreTransfer" : "Store Transfer"]
        : TrackItConstants[isGroup ? "GroupHeaderStart" : "CommonHeaderStart"];
    return [...headerStart, ...headers, ...TrackItConstants["CommomHeaderEnd"]];
  };

  const getReasonGroupData = (reasonHeaders, _groupedMetaData) => {
    return _groupedMetaData.map((grpData) => {
      const _grpData = {
        store_ID: grpData.store_ID,
        store_ID1: grpData.store_ID1,
        dm: grpData.dm,
        Badge_Type: grpData.isAdjusted
          ? "Manual Price Keyed"
          : "POS Price entered",
        total: grpData.total,
        price_Adj: grpData.price_Adj,
      };
      reasonHeaders.forEach((hdr, hdrIdx) => {
        _grpData[hdr] = grpData.sums[hdrIdx];
      });
      return _grpData;
    });
  };

  const getDetailedReportData = async (storeId, departmentId, isAll) => {
    const { groupType, groupTypeId } = userContext;
    handleSpinner && handleSpinner(true);
    const _nID_Type = storeId > 0 ? 1 : groupType;
    const _nID =
      storeId > 0 ? storeId : groupTypeID ? groupTypeID : groupTypeId;
    const _storeIds = [
      ...new Set(listTableReports.map((result) => result.store_ID)),
    ].join(",");
    ReportService.getDetailedReportTrackIt(
      selectedTab.value,
      _nID,
      _nID_Type,
      moment(fromDate).format("YYYY-MM-DD"),
      moment(toDate).format("YYYY-MM-DD"),
      departmentId,
      isAll,
      _storeIds
    )
      .then((result) => {
        const _detailReportData = handleReasonDetailData(result);

        // Add Retail Lost Total
        _detailReportData.reportList.map((list) =>
          Number(
            (list["retail_Loss"] =
              (list["total"] !== null || list["total"] !== undefined
                ? list["total"]
                : 0 * 60) / 100)
          )
        );
        _detailReportData.reportList.map((list) => Number(list.retail_Loss));

        _detailReportData.reportList.map((list) => {
          if (list.reclaim === true) {
            list.reclaim = "Y";
          } else {
            list.reclaim = "N";
          }
        });

        /* const newHeader = _detailReportData.headers.concat("retail_Loss"); */
        setReasonDetailReportData(_detailReportData);
        const _headers = getReasonDetailHeaders(_detailReportData.headers);
        const _totals = getTotalRowData(_detailReportData.reportList, _headers);

        setDetailTableReports(_detailReportData.reportList);
        setDetailTableTotals(_totals);
        _headers.map((header) => {
          if (header.Header === "Delivery") {
            header.Header = "Date";
          }
        });
        setDetailTableHeaders(_headers);
      })
      .catch((err) => {
        console.error("Error: Get Detailed Report - ", err);
      })
      .finally(() => {
        handleSpinner && handleSpinner(false);
      });
  };

  const handleReasonDetailData = (reasonResponse: any) => {
    const { reasonHeaders = [], records = [] } = reasonResponse;
    const _reasonMaster = { ...reasonDetailReportData };
    _reasonMaster.headers = reasonHeaders;
    _reasonMaster.reportList = records.map((item) => {
      item["FormatedDate"] =
        item.date && moment(item.date).format("MM-DD-YYYY");
      item["Badge_Type"] =
        item.price_Adj !== 0 ? "Manual Price Keyed" : "POS Price entered";
      item.reasonPrice.forEach((value) => {
        item[value.reason_Description] = value.price ?? 0;
      });
      item["total"] = Number(
        item.reasonPrice.reduce((acc, item) => acc + item.price, 0).toFixed(2)
      );
      item["price"] = Number(item["price"] / 2);
      return item;
    });

    return _reasonMaster;
  };

  const getReasonDetailHeaders = (reasonHeaders) => {
    let headers = reasonHeaders.map((data) => {
      return {
        ...new TableHeaderConfig(),
        Header: capitalCase(data),
        DataKey: data,
        TypeOfData: "Price",
      };
    });
    return [
      ...TrackItConstants["DetailCommonHeaderStart"],
      ...headers,
      ...TrackItConstants["CommomHeaderEnd"],
    ];
  };
  //#endregion

  //#region Week Data
  const handleWeekData = (weekResponse: any) => {
    const {
      groupedMetadata = [],
      weekHeaders = [],
      recordsByWeek = [],
    } = weekResponse;
    const _weekMaster = { ...weekReportData };
    _weekMaster.headers = weekHeaders;
    _weekMaster.groupedList = getWeekGroupData(weekHeaders, groupedMetadata);
    _weekMaster.reportList = recordsByWeek.map((item) => {
      (item["weekTotals"] ?? []).forEach((_weekTtl, _weekTtlIdx) => {
        item[`Week_${_weekTtl.weekNumber}`] = _weekTtl.price;
      });
      return item;
    });
    return _weekMaster;
  };

  const getWeekHeaders = (weekHeaders, isGroup = false) => {
    const headers = weekHeaders
      .sort((a, b) => {
        return a.weekNumber - b.weekNumber;
      })
      .map((_weekHdr, _weekHdrIdx) => {
        return {
          ...new TableHeaderConfig(),
          Header: `Week ${_weekHdrIdx + 1} (${moment(_weekHdr.startDate).format(
            "MM-DD-YYYY"
          )} to ${moment(_weekHdr.endDate).format("MM-DD-YYYY")})`,
          DataKey: `Week_${_weekHdr.weekNumber}`,
          Width: 150,
          TypeOfData: "Price",
        };
      });
    headers.push({
      ...new TableHeaderConfig(),
      Header: "Total",
      DataKey: "total",
      Width: 150,
      TypeOfData: "Price",
    });
    const headerPrefix = isGroup
      ? TrackItConstants["GroupWeekDataTable"]
      : TrackItConstants["WeekDataTable"];
    return [...headerPrefix, ...headers];
  };

  const getWeekGroupData = (_weekHeaders, _groupedMetaData) => {
    return _groupedMetaData.map((grpData) => {
      const _grpData = {
        store_ID: grpData.store_ID,
        store_ID1: grpData.store_ID1,
        dm: grpData.dm,
        total: grpData.total,
      };
      _weekHeaders.forEach((hdr, hdrIdx) => {
        _grpData[`Week_${hdr.weekNumber}`] = grpData.sums[hdrIdx];
      });
      return _grpData;
    });
  };
  //#endregion

  //#region Donation Data
  const getDonationReportData = async () => {
    const { handleSpinner, userContext = {} } = props;
    const { groupType, groupTypeId, store } = userContext;
    handleSpinner && handleSpinner(true);
    if (store !== 0) {
      ReportService.getReportDonation(
        store,
        1,
        moment(fromDate).format("YYYY-MM-DD"),
        moment(toDate).format("YYYY-MM-DD")
      )
        .then((result) => {
          const _donationData = handleDonationData(result);
          setDonationReportData(_donationData);
          setIsDataloaded(true);
        })
        .catch((err) => {
          console.error("Error: Get Orders - ", err);
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    } else {
      ReportService.getReportDonation(
        groupTypeId,
        2,
        moment(fromDate).format("YYYY-MM-DD"),
        moment(toDate).format("YYYY-MM-DD")
      )
        .then((result) => {
          const _donationData = handleDonationData(result);
          setDonationReportData(_donationData);
          setIsDataloaded(true);
        })
        .catch((err) => {
          console.error("Error: Get Orders - ", err);
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    }
  };

  const handleDonationData = (donationResponse: any) => {
    const { groupedRecords = [], records = [] } = donationResponse;
    const _donationMaster = { ...donationReportData };
    _donationMaster.groupedList = getDonationGroupData(groupedRecords);
    _donationMaster.reportList = records.map((item) => {
      item["formatedDonationDate"] =
        item.date_Printed && moment(item.date_Printed).format("MM-DD-YYYY");
      item["description"] = item.description !== "" && item.description.trim();
      item["total"] =
        Number((item.quantity * item.retail_Price).toFixed(2)) ?? 0.0;
      return item;
    });
    return _donationMaster;
  };

  const getDonationGroupData = (_groupedRecords) => {
    return _groupedRecords.map((grpData) => {
      return {
        store_ID: grpData.store_ID,
        total: grpData.total,
      };
    });
  };
  //#endregion

  //#region Table Functions
  const onGroupReportRowClick = (data: any, isShowAll = false) => {
    const { groupType } = userContext;
    setClickedFilterDataMaster(data);
    if (selectedTab.value !== "8") {
      const _reportsList =
        reportTypeSelected === "Reason"
          ? reasonReportData.reportList
          : weekReportData.reportList;
      const _filteredList = isShowAll
        ? _reportsList.filter((_x) => {
            let selected = true;
            for (let prop in selectedColumnFilters) {
              if (selected && _x[prop]) {
                selected =
                  selectedColumnFilters[prop] &&
                  selectedColumnFilters[prop].length > 0
                    ? selectedColumnFilters[prop].some(
                        (item) => item.toString() === _x[prop].toString()
                      )
                    : true;
              }
            }
            return selected;
          })
        : _reportsList.filter((_x) => _x.store_ID === data.store_ID);
      const _data = { ...data };
      delete _data.dm;
      const _filteredTotalRow = isShowAll ? groupTableTotals : _data;
      const _headers =
        reportTypeSelected === "Reason"
          ? getReasonHeaders(reasonReportData.headers)
          : getWeekHeaders(weekReportData.headers);
      const _filteredList2 = _filteredList.filter(
        (item) => item.department !== ""
      );
      setListTableReports(_filteredList2);
      setListTableTotals(_filteredTotalRow);
      setListTableHeaders(_headers);
      setDetailView(false);
    } else {
      if (groupType === 1) onDetailReportRowClick(data, isShowAll);
    }
    handleOpen("full");
  };

  const onDetailReportRowClick = (data: any, isShowAll = false) => {
    if (reportTypeSelected === "Reason") {
      setClickedFilterData(data);
      setDetailView(true);
      setDetailTableReports([]);
      setDetailTableTotals([]);
      setDetailTableHeaders([]);
      if (selectedTab.value !== "8") {
        if (reportTypeSelected === "Reason") {
          const _storeId = data ? data.store_ID ?? 0 : 0;
          const _deptId = data ? data.poS_Department_ID ?? 0 : 0;
          getDetailedReportData(_storeId, _deptId, isShowAll);
        }
      } else {
        const _filteredList = isShowAll
          ? donationReportData.reportList
          : donationReportData.reportList.filter(
              (_x) => _x.store_ID === data.store_ID
            );
        _filteredList.map((list) =>
          Number(
            (list["retail_Loss"] =
              (list["total"] !== null || list["total"] !== undefined
                ? list["total"]
                : 0 * 60) / 100)
          )
        );

        const _headers = TrackItConstants["DonationDetail"];
        const _totals = getTotalRowData(_filteredList, _headers);

        setDetailTableReports(_filteredList);
        setDetailTableTotals(_totals);
        setDetailTableHeaders(_headers);
      }
    }
  };

  const handleShowAll = () => {
    onGroupReportRowClick(null, true);
  };
  const handleShowAllDetails = () => {
    onDetailReportRowClick(groupTableTotals, true);
  };
  //#endregion
  //#region Filters
  const handleOpen = (value) => {
    setSize(value);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setClickedFilterData(null);
    setClickedFilterDataMaster(null);
  };

  const columnFiltersData = () => {
    let filtersArr = [];
    let data = [];
    if (selectedTab.value !== "8") {
      if (reportTypeSelected === "Reason") data = reasonReportData.groupedList;
      else data = weekReportData.groupedList;
    } else data = donationReportData.groupedList;
    let header = groupTableHeaders;

    header &&
      header?.forEach((value) => {
        value.isSearch &&
          value.searchGroup.some((x) => x === groupType) &&
          filtersArr.push({
            key: value.DataKey,
            label: value.Header,
            data: [...new Set(data?.map((_) => _[value.DataKey]))].sort(
              (a, b) => {
                let x = a ? a : "";
                let y = b ? b : "";
                return x
                  .toString()
                  .localeCompare(y.toString(), undefined, { numeric: true });
              }
            ),
          });
      });
    setColumnFilters(filtersArr);
    columnFiltersOnSelect();
  };

  const columnFiltersOnSelect = () => {
    let selectedFiltersArr = {};
    const header = groupTableHeaders;
    header &&
      header.forEach((value) => {
        if (value.isSearch && value.searchGroup.some((x) => x === groupType)) {
          selectedFiltersArr[value.DataKey] = [];
        }
      });
    setFilterCount(selectedFiltersArr);
    setSelectedColumnFilters(selectedFiltersArr);
    filterSelected(selectedFiltersArr, selectedFiltersArr);
  };

  const filterSelected = (filterCount, selectedData) => {
    let isSelected = false;
    for (const data in filterCount) {
      if (
        filterCount[data] &&
        selectedData[data] &&
        filterCount[data].length !== selectedData[data].length
      )
        isSelected = true;
    }
    setFilterSelected(isSelected);
  };

  const getCheckPickerData = (result) => {
    let valueArr = [];
    if (result.data) {
      valueArr = result.data.map((res) => {
        return { label: res !== "" ? res : "(Empty)", value: res };
      });
    }
    return valueArr;
  };

  const onFilterClick = (selectedColumnFilters) => {
    let _reportData = [];
    if (selectedTab.value !== "8") {
      if (reportTypeSelected === "Reason")
        _reportData = reasonReportData.groupedList;
      else _reportData = weekReportData.groupedList;
    } else _reportData = donationReportData.groupedList;
    const _data = _reportData.filter((result) => {
      let selected = true;
      for (let prop in selectedColumnFilters) {
        if (selected && result[prop]) {
          selected =
            selectedColumnFilters[prop] &&
            selectedColumnFilters[prop].length > 0
              ? selectedColumnFilters[prop].some(
                  (item) => item.toString() === result[prop].toString()
                )
              : true;
        }
      }
      return selected;
    });
    const _totals = getTotalRowData(_data, groupTableHeaders);
    setGroupTableReports(_data);
    setGroupTableTotals(_totals);
    setDisplayNoDatatlabel(_data.length === 0);
  };

  const clearFilter = () => {
    filterSelected(filterCount, filterCount);
    columnFiltersOnSelect();
    handleDataChange();
  };

  useEffect(() => {
    if (
      userContext &&
      userContext.groupType === 2 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("2", "StoreType");

    if (
      userContext &&
      userContext.groupType === 1 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("1", "StoreType");

    if (
      userContext &&
      userContext.groupType === 3 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("3", "StoreType");
  }, [userContext]);

  //#region Get Group and Store by Types
  const GetFirstAllStore = async (IDType, operation) => {
    userContext &&
      !userContext.store &&
      UMSService.GetGroupAndStoreByTypes(IDType, operation)
        .then((result) => {
          var setArrayName = [];
          var setArrayID = [];
          if (IDType === "2") {
            result.map((item) => {
              return (item.groupStore_desc =
                item.groupStore_uid + " / " + item.groupStore_desc);
            });
          }
          result.sort((a, b) => {
            const Store_A = a.groupStore_uid;
            const Store_B = b.groupStore_uid;

            if (Store_A < Store_B) {
              return -1;
            } else if (Store_A > Store_B) {
              return 1;
            } else {
              return 0;
            }
          });

          result.filter((item) => {
            if (!setArrayID.includes(item.groupStore_uid)) {
              setArrayID.push(item.groupStore_uid);
            }
          });
          result.filter((item) => {
            if (!setArrayName.includes(item.groupStore_desc)) {
              setArrayName.push(item.groupStore_desc);
            }
          });
          setStoreName(setArrayName);
          setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
        })
        .catch((err) => {
          console.error("Error:  - ", err);
          // toast.error("Error Loading Orders");
        });
  };
  //#endregion
  //#region Excel Export
  const exportToExcel = (excelData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: Constants.fileType });
    FileSaver.saveAs(data, fileName + Constants.fileExtension);
  };
  //#endregion
  const data = tabsList.map((item) => {
    return { label: item.label, value: item.value };
  });
  return (
    <>
      <div className="TrackITReportHeader">
        <>
          <div className="TrackITReportHeaderItems">
            <InputPicker
              className="InputPicker"
              onChange={(e) =>
                setSelectedTab({ ...selectedTab, value: String(e) })
              }
              size="md"
              placeholder="Shrink"
              data={data}
              cleanable={false}
            />
          </div>

          <div className="TrackITReportHeaderItems">
            <div className="d-flex justify-content-between align-items-center ml-auto">
              {selectedTab.label !== "Donation" && (
                <>
                  <Button
                    appearance={
                      reportTypeSelected === "Reason" ? "primary" : "ghost"
                    }
                    onClick={() => {
                      setReportTypeSelected("Reason");
                      clearFilter();
                    }}
                  >
                    Total By Reason
                  </Button>
                  <Button
                    appearance={
                      reportTypeSelected === "Week" ? "primary" : "ghost"
                    }
                    onClick={() => {
                      setReportTypeSelected("Week");
                      clearFilter();
                    }}
                  >
                    Total By Week
                  </Button>
                </>
              )}
            </div>
          </div>
          <>
            {userContext &&
              userContext.roleName &&
              (userContext.roleName === "Super Admin" ||
                (userContext.groupTypeId !== "0" &&
                  userContext.groupTypeName === "")) && (
                <div className="TrackITReportHeaderItems">
                  {" "}
                  {userContext && userContext.roleName === "Super Admin" ? (
                    <div className="GroupStoreSide">
                      <SelectPicker
                        placeholder="GOT"
                        className="header-bar-item"
                        onChange={SelectAwgOrGot}
                        label="Group Type"
                        data={awgOrGot.map((item) => ({
                          label: item.groupStore_desc,
                          value: item.groupStore_uid,
                        }))}
                      />
                      <SelectPicker
                        onChange={SelectGroupType}
                        label="Group"
                        className="header-bar-item"
                        data={groupAndGroupID.map((item) => ({
                          label: item.groupStore_desc,
                          value: item.groupStore_uid,
                        }))}
                      />
                      <SelectPicker
                        onChange={onOptionChangeHandler}
                        label="Store"
                        className="header-bar-item"
                        placeholder={"All"}
                        virtualized
                        data={storeName.map((item, index) => ({
                          label: item,
                          value: index,
                        }))}
                      />
                    </div>
                  ) : userContext && userContext.store !== 0 ? (
                    <>
                      <label
                        style={{ height: "30px", fontWeight: 700 }}
                        className=" alert"
                      >
                        Store: {storeNameHeader}
                      </label>{" "}
                    </>
                  ) : userContext &&
                    userContext.groupTypeId &&
                    userContext.groupTypeId !== "" ? (
                    <>
                      {" "}
                      <SelectPicker
                        onChange={onOptionChangeHandler}
                        label="Store"
                        className="header-bar-item"
                        placeholder={
                          storeName && storeName[0] ? storeName[0] : "All"
                        }
                        data={storeName.map((item, index) => ({
                          label: item,
                          value: index,
                        }))}
                      />
                    </>
                  ) : (
                    <>
                      <SelectPicker
                        onChange={SelectGroupType}
                        label="Group"
                        virtualized
                        className="header-bar-item"
                        data={groupAndGroupID.map((item) => ({
                          label: item.groupStore_desc,
                          value: item.groupStore_uid,
                        }))}
                      />
                      {
                        <>
                          {" "}
                          <SelectPicker
                            onChange={onOptionChangeHandler}
                            label="Store"
                            className="header-bar-item"
                            placeholder={
                              storeName && storeName[0] ? storeName[0] : "All"
                            }
                            virtualized
                            data={storeName.map((item, index) => ({
                              label: item,
                              value: index,
                            }))}
                          />
                        </>
                      }
                    </>
                  )}
                </div>
              )}
          </>
          <div className="TrackITReportHeaderItems">
            <div>
              <div className="d-flex flex-nowrap justify-content-between align-items-center ml-auto">
                <Calendar
                  value={[fromDate, toDate]}
                  calenderOnClick={calenderOnClick}
                />
              </div>
            </div>
          </div>
        </>
      </div>

      <div className="mt-2 d-flex justify-content-end align-items-end">
        {groupTableReports.length > 0 &&
          selectedTab.label !== "Donation" &&
          reportTypeSelected === "Reason" && (
            <div className="">
              <span className="badge-legend">
                {" "}
                Note: <Icon className="flag-icon2" icon="ep:flag" /> Manual
                Price Entered
              </span>{" "}
            </div>
          )}
        {columnFilters && columnFilters.length > 0 && (
          <div
            id="filterdiv"
            className="mx-3 filter"
            data-bs-toggle="collapse"
            data-bs-target="#collapseExample"
            onClick={toggleCollapse}
          >
            <Icon icon="material-symbols:filter-alt-outline-sharp" /> Filters
          </div>
        )}
        <Dropdown
          renderToggle={(_props, _ref) => (
            <div className="filter" ref={_ref} {..._props}>
              <Icon icon="pajamas:export" /> Export
            </div>
          )}
          className="ms-2 me-0 button-fixed"
          appearance="link"
          placement="bottomEnd"
        >
          <Dropdown.Item
            icon={<FileDownloadIcon />}
            onClick={() => {
              exportToExcel(groupTableReports, "Markdown Report");
            }}
          >
            Export to Excel
          </Dropdown.Item>
        </Dropdown>
      </div>

      {columnFilters && columnFilters.length > 0 && (
        <div
          className={`collapse p-2 mt-3 boxallign ${isCollapsed ? "" : "show"}`}
          id="collapseExample"
        >
          <div className="card card-body totalCard">
            {columnFilters.map((result) => {
              return (
                <div className="collapsedData pe-2 ">
                  {/* <label className="px-3 pb-1 w-100">{result.label}</label> */}
                  <CheckPicker
                    sticky
                    className="w-100 filtersPicker"
                    menuMaxHeight={190}
                    ref={picker}
                    appearance="default"
                    size="xs"
                    renderValue={(value, items) => {
                      const _value = [...value].sort((a, b) => {
                        let x = a ? a : "";
                        let y = b ? b : "";
                        return x
                          .toString()
                          .localeCompare(y.toString(), undefined, {
                            numeric: true,
                          });
                      });
                      _value[0] === "" && _value.splice(0, 1, "(Empty)");
                      return (
                        <>
                          {_value.length < 3
                            ? _value.splice(0, 2).join(", ")
                            : _value[0] !== "(Empty)"
                            ? _value.splice(0, 2).join(", ")
                            : _value.splice(1, 2).join(", ")}{" "}
                          {_value.length > 0 ? (
                            <span className="roundOff">
                              {" "}
                              {`+${_value.length}`}
                            </span>
                          ) : (
                            ``
                          )}
                        </>
                      );
                    }}
                    data={getCheckPickerData(result)}
                    style={{ width: 224 }}
                    value={selectedColumnFilters[result.key]}
                    placeholder={result.label}
                    renderMenuItem={(label, item) => {
                      return (
                        <div className="d-flex flex-column">
                          <i className="rs-icon rs-icon-user" /> {label}
                        </div>
                      );
                    }}
                    onChange={(value, event) => {
                      let selectedData = { ...selectedColumnFilters };
                      selectedData[result.key] = value;
                      setSelectedColumnFilters(selectedData);
                      filterSelected(filterCount, selectedData);
                      onFilterClick(selectedData);
                    }}
                  />
                </div>
              );
            })}
          </div>

          <div className="footer text-end boxdetails">
            <Button
              appearance="ghost"
              className="mx-2"
              size="xs"
              disabled={!isfilterSelected}
              onClick={() => {
                clearFilter();
              }}
            >
              Clear All
            </Button>
            <IconButton
              appearance="ghost"
              style={{ padding: "0.35rem" }}
              icon={<Icon icon="ic:twotone-close" />}
              size="xs"
              data-bs-toggle="collapse"
              data-bs-target="#collapseExample"
            />
          </div>
        </div>
      )}

      <TableComponent
        isFromModal={false}
        tableDetails={groupTableHeaders} //{selectedTab.value !== "8" ? reportTypeSelected === "Reason" ? groupHeaderData : weekGroupHeaderData : groupType == 2 ? TrackItConstants["Donation"] : TrackItConstants["DonationDetail"]}
        reportList={groupTableReports} //{selectedTab.value !== "8" ? Filterdata : (groupType == 2 ? reportList : detailedReportList)}
        iconColumn={
          selectedTab.value === "8" && groupType !== 2 ? "" : "store_ID"
        }
        onTableRowClick={onGroupReportRowClick}
        reportType={reportTypeSelected}
        totalTableRow={groupTableTotals} //{reportTypeSelected === "Reason" ? totalTableRow : totalWeekTableRow}
        isTotalRowRequired={true}
        DisplayNoDatatlabel={DisplayNoDatatlabel}
        showAll={true}
        handleShowAll={handleShowAll}
        selectedtab={selectedTab.value}
      />
      <Modal size={size} open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>
            {selectedTab.value !== "8" && isDetailView ? (
              <span
                className="mx-2 filter"
                onClick={() => {
                  setDetailView(false);
                }}
              >
                <Icon icon="ep:back" />
              </span>
            ) : (
              <span
                className="mx-2 filter"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <Icon icon="ep:back" />
              </span>
            )}
            Track IT Record
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isDetailView ? (
            <ReportTrackItDetailComponent
              clickedFilterData={clickedFilterData}
              clickedFilterDataMaster={clickedFilterDataMaster}
              tableDetails={detailTableHeaders}
              reportList={detailTableReports}
              exportToExcel={exportToExcel}
              totalTableRow={detailTableTotals}
              selectedTab={selectedTab}
              isDonation={selectedTab.value !== "8" ? false : true}
              isTableCustomStriped={
                groupType === 2
                  ? clickedFilterDataMaster
                    ? clickedFilterDataMaster["store_ID"] === ""
                    : clickedFilterData
                    ? clickedFilterData["store_ID"] === ""
                      ? true
                      : false
                    : true
                  : false
              }
            />
          ) : (
            <TableComponent
              isFromModal={false}
              tableDetails={listTableHeaders}
              reportList={listTableReports}
              iconColumn={reportTypeSelected === "Reason" ? "store_ID" : ""}
              onTableRowClick={onDetailReportRowClick}
              reportType={reportTypeSelected}
              totalTableRow={listTableTotals}
              isTotalRowRequired={
                reportTypeSelected === "Reason" ? true : false
              }
              DisplayNoDatatlabel={DisplayNoDatatlabel}
              showAll={true}
              handleShowAll={handleShowAllDetails}
              //handleShowAll={{}}
              selectedtab={selectedTab.value}
              isTableCustomStriped={
                groupType === 2
                  ? clickedFilterDataMaster == null
                    ? true
                    : false
                  : false
              }
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ReportTrackItByGroupComponent;
