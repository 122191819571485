/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useState, useRef, useEffect } from "react";
import {
  Form,
  Button,
  Divider,
  Row,
  Col,
  SelectPicker,
  ButtonToolbar,
  Message,
} from "rsuite";
import * as UMSService from "../../../../../services/umsService";
import { toast } from "react-toastify";

import { NumberType, SchemaModel, StringType } from "schema-typed";
const UpdateUser = (props: any) => {
  const { userContext, userData } = props;
  //console.log(userData);
  const formRef = useRef<any>();
  const model = SchemaModel({
    // First_Name: StringType().isRequired("Please enter first name."),
    //Last_Name: StringType().isRequired("Please enter last name."),
    /* Email: StringType()
      .isEmail("Email must be valid.")
      .isRequired("Email is required"), */
    //Mobile: NumberType().isRequired("Mobile number should be of 10 digits."),
    //Address1: StringType().isRequired("Please enter Address Line 1."),
    //City: StringType().isRequired("Please enter City."),
    Role_ID: NumberType().isRequired("Please choose Role."),
    //Group_Type_ID: NumberType().isRequired("Please choose Group."),

    //Zip: NumberType().isRequired("Zip code should be length of 5 digits"),
    //Login_User_Name: StringType().isRequired("Please enter User Name."),
    //Password: StringType().isRequired("Please enter Password."),
    //confirmpassword: StringType().isRequired("Please enter Confirm Password."),
    //Status: BooleanType("Choose Active or Deactive"),
    //group_type: NumberType().isRequired("Choose Group Type"),
  });
  const initFormValue = {
    First_Name: "",
    Last_Name: "",
    Email: "",
    Mobile: "",
    City: "",
    Address1: "",
    Address2: "",
    state: "",
    Zip: "",
    Status: true,
    web_login_uid: "",
    User_ID: "",
    Role_ID: "",
    Group_Type_ID: "",
    group_type: "",
    Login_User_Name: "",
    Password: "",
    confirmpassword: "",
  };

  const selectGroupType1 = [
    {
      id: 2,
      name: "GOT",
    },
  ].map((item) => ({
    label: item.name,
    value: item.id,
  }));
  const selectGroupType = [
    {
      id: 1,
      name: "GOT",
      operation: "",
    },
    {
      id: 2,
      name: "AWG",
      operation: "",
    },
    {
      id: 3,
      name: "MDI",
      operation: "",
    },
    {
      id: 1,
      name: "GOT Group",
      operation: "GroupType",
    },
    {
      id: 3,
      name: "MDI Group",
      operation: "GroupType",
    },
    {
      id: 2,
      name: "AWG Group",
      operation: "GroupType",
    },
    {
      id: 1,
      name: "GOT Store",
      operation: "StoreType",
    },
    {
      id: 2,
      name: "AWG Store",
      operation: "StoreType",
    },
    {
      id: 3,
      name: "MDI Store",
      operation: "StoreType",
    },

  ].map((item) => ({
    label: item.name,
    value: item.operation
      ? item.id + " " + item.operation + " " + item.name
      : item.id,
  }));

  /*   const selectRole = [
    {
      id: 1,
      name: "Admin",
    },
    {
      id: 2,
      name: "User",
    },
    {
      id: 3,
      name: "Super Admin",
    },
    {
      id: 6,
      name: "AWG",
    },
  ].map((item) => ({
    label: item.name,
    value: item.id,
  })); */
  const [formValue, setFormValue] = useState(userData[0]);
  const [isShowGroupOrStore, setIsShowGroupOrStore] = useState(false);
  const [groupAndGroupID, setGroupAndGroupID] = useState([
    { groupStore_UID: "", groupStore_desc: "" },
  ]);
  const [isAWG, setISAWG] = useState(false);
  const [storeID, setStoreID] = useState("");
  const [rolesList, setRolesList] = useState([]);
  useEffect(() => {
    GetRolesList();
  }, []);
  const GetRolesList = async () => {
    UMSService.GetRolesList()
      .then((result) => {
        //console.log(result);

        /*         result.map((item) => {
          if (item.isAdmin === true) {
            item.isAdmin = "True";
          }
          if (item.isUsers === true) {
            item.isUsers = "True";
          }
        }); */
        setRolesList(result);
      })
      .catch((err) => {
        console.error("Error:  - ", err);
        // toast.error("Error Loading Orders");
      });
  };

  const handleSubmit = () => {
    if (!formRef.current.check()) {
      console.error("Form error");
      return;
    }
    if (formValue.Role_ID.toString() === "6") {
      const obj = {
        ...userData,
        ...formValue,

        operation: "EditUser",
        UID: userData.uid,
        role_Name: rolesList.filter((item) => {
          return item.value === formValue.Role_ID;
        }),
        //group_type: formValue.group_type.split("-"),
        group_type:
          formValue.group_type !== ""
            ? formValue.group_type.toString().includes(" ")
              ? Number(formValue.group_type.toString().split(" ")[0])
              : formValue.group_type
            : 0,

        store_id: formValue.group_type.toString().includes(" ")
          ? formValue.group_type.toString().split(" ")[1] === "StoreType"
            ? formValue.Group_Type_ID
              ? formValue.Group_Type_ID
              : 0
            : 0
          : 0,
        group_id: 0,
        group_type_desc: storeID !== "" ? storeID : "",
        Group_Type_ID: null,
      };
      //console.log("ROLEID = 6 AWG", obj);
      UpdateUser(obj);
    } else {
      //console.log(formValue);

      const obj = {
        ...userData,
        ...formValue,
        role_Name: rolesList.filter((item) => {
          return item.value === formValue.Role_ID;
        }),
        operation: "EditUser",
        //group_type: formValue.group_type.split(" ")[0],
        UID: userData.uid,
        group_type:
          formValue.group_type !== ""
            ? formValue.group_type.toString().includes(" ")
              ? Number(formValue.group_type.toString().split(" ")[0])
              : formValue.group_type
            : 0,

        //group_id: formValue.Group_Type_ID,

        store_id: formValue.group_type.toString().includes(" ")
          ? formValue.group_type.toString().split(" ")[1] === "StoreType"
            ? formValue.Group_Type_ID
              ? formValue.Group_Type_ID
              : 0
            : 0
          : 0,
        group_id: formValue.group_type.toString().includes(" ")
          ? formValue.group_type.toString().split(" ")[1] === "StoreType"
            ? 0
            : formValue.Group_Type_ID
          : 0,
        Group_Type_ID: formValue.group_type.toString().includes(" ")
          ? formValue.group_type.toString().split(" ")[1] === "StoreType"
            ? null
            : formValue.Group_Type_ID
          : null,
        group_type_desc: storeID !== " " ? storeID : "",
      };
      UpdateUser(obj);
    }
  };
  if (userContext) {
    var { roleName } = userContext;
  }

  /*   const GetGroupAndGroupID = async () => {
    UMSService.GetGroupandGroupID()
      .then((result) => {
        setGroupAndGroupID(result.records);
      })
      .catch((err) => {
        console.error("Error:  - ", err);
        // toast.error("Error Loading Orders");
      });
  }; */

  const GetGroupAndStoreByTypes = async (IDType, operation) => {
    UMSService.GetGroupAndStoreByTypes(IDType, operation)
      .then((result) => {
        //console.log(result);
        setGroupAndGroupID(result);
      })
      .catch((err) => {
        console.error("Error:  - ", err);
        // toast.error("Error Loading Orders");
      });
  };
  const UpdateUser = async (userData) => {
    UMSService.InsertNewUser(userData)
      .then((result) => {
        result && toast.success("User Updated Successfully");
        result && window.location.reload();
      })
      .catch((err) => {
        console.error("Error:  - ", err);
        // toast.error("Error Loading Orders");
      });
  };
  const SelectGroupType = (event) => {
    if (event.toString().includes(" ")) {
      var IDType = event.toString().split(" ")[0];
      var operation = event.toString().split(" ")[1];
      var groupDesc =
        event.toString().split(" ")[2] + " " + event.toString().split(" ")[3];
      setStoreID(groupDesc);
      GetGroupAndStoreByTypes(IDType, operation);
    } else {
      //console.log(event.toString());
      setIsShowGroupOrStore(false);

      if (event.toString() === "1") {
        setStoreID("GOT");
      }
      if (event.toString() === "2") {
        setStoreID("AWG");
      }
      if (event.toString() === "3") {
        setStoreID("MDI");
      }
    }

    setIsShowGroupOrStore(true);
  };

  const SelectRoleType = (event) => {
    if (event === 6) {
      setISAWG(true);
    }
    //
  };

  if (roleName === "Admin" || roleName === "Super Admin") {
    return (
      <>
        <Row className="show-grid">
          <Col xs={24}>
            <Form
              ref={formRef}
              model={model}
              //layout={"inline"}
              onChange={setFormValue}
              onSubmit={handleSubmit}
            >
              <Form.Group controlId="First_Name">
                <Form.ControlLabel>First Name</Form.ControlLabel>
                <Form.Control
                  placeholder={userData.first_Name}
                  value={userData.first_Name}
                  name="First_Name"
                  disabled
                  style={{ width: 360 }}
                />
                <Form.HelpText tooltip>Required</Form.HelpText>
              </Form.Group>
              <Form.Group controlId="Last_Name">
                <Form.ControlLabel>Last Name</Form.ControlLabel>
                <Form.Control
                  placeholder={userData.last_Name}
                  value={userData.last_Name}
                  name="Last_Name"
                  disabled
                  style={{ width: 360 }}
                />
                <Form.HelpText tooltip>Required</Form.HelpText>
              </Form.Group>
              <Form.Group controlId="Email">
                <Form.ControlLabel>{"Email"}</Form.ControlLabel>
                <Form.Control
                  value={userData.email}
                  placeholder={userData.email}
                  disabled
                  name="Email"
                  type="email"
                  style={{ width: 360 }}
                />
                <Form.HelpText tooltip>Required</Form.HelpText>
              </Form.Group>
              <Message>
                {userData ? "Role Name: " + userData.role_Name : ""}
              </Message>
              <Message>
                {userData ? "Group Type: " + userData.group_type_desc : ""}
              </Message>
              <Message>
                {userData ? "Group Name: " + userData.group_ID_Desc : ""}
              </Message>
              {userData.store_id !== "" ? (
                <Message>{"Store ID: " + userData.store_id}</Message>
              ) : null}

              {/*               <Form.Group controlId="Mobile">
                <Form.ControlLabel>Mobile</Form.ControlLabel>
                <Form.Control
                  placeholder={userData.last_Name}
                  name="Mobile"
                  style={{ width: 360 }}
                />
                <Form.HelpText tooltip>Required</Form.HelpText>
              </Form.Group> */}
              {/*               <Form.Group controlId="Address1">
                <Form.ControlLabel>Address line 1</Form.ControlLabel>
                <Form.Control name="Address1" style={{ width: 360 }} />
                <Form.HelpText tooltip>Required</Form.HelpText>
              </Form.Group>{" "}
              <Form.Group controlId="City">
                <Form.ControlLabel>City</Form.ControlLabel>
                <Form.Control name="City" style={{ width: 360 }} />
                <Form.HelpText tooltip>Required</Form.HelpText>
              </Form.Group>
              <Form.Group controlId="Zip">
                <Form.ControlLabel>Zip</Form.ControlLabel>
                <Form.Control name="Zip" type="number" style={{ width: 360 }} />
                <Form.HelpText tooltip>Required</Form.HelpText>
              </Form.Group>
              <Form.Group controlId="Address2">
                <Form.ControlLabel>Address line 2</Form.ControlLabel>
                <Form.Control name="Address2" style={{ width: 360 }} />
              </Form.Group>{" "} */}
              {/*               <Form.Group controlId="Login_User_Name">
                <Form.ControlLabel>Username</Form.ControlLabel>
                <Form.Control
                  placeholder="Username"
                  name="Login_User_Name"
                  style={{ width: 360 }}
                />
                <Form.HelpText tooltip>Required</Form.HelpText>
              </Form.Group>
              <Form.Group controlId="Password">
                <Form.ControlLabel>Password</Form.ControlLabel>
                <Form.Control
                  style={{ width: 360 }}
                  placeholder="Password"
                  name="Password"
                  type="password"
                  autoComplete="off"
                />
                <Form.HelpText tooltip>Required</Form.HelpText>
              </Form.Group>
              <Form.Group controlId="confirmpassword">
                <Form.ControlLabel>Confirm Password</Form.ControlLabel>
                <Form.Control
                  style={{ width: 360 }}
                  placeholder="Confirm Password"
                  name="confirmpassword"
                  type="password"
                  autoComplete="off"
                />
              </Form.Group> */}
              <Form.Group controlId="Role_ID">
                <Form.ControlLabel>Select Role:</Form.ControlLabel>
                <Form.Control
                  style={{ width: 360 }}
                  name="Role_ID"
                  data={rolesList.map((item) => ({
                    label: item.role_Name,
                    value: item.uid,
                  }))}
                  accepter={SelectPicker}
                  placeholder={userData.role_Name}
                  onChange={SelectRoleType}
                />
                <Form.HelpText tooltip>Required</Form.HelpText>
              </Form.Group>
              <Form.Group controlId="group_type">
                <Form.ControlLabel>Select Group Type:</Form.ControlLabel>
                <Form.Control
                  style={{ width: 360 }}
                  name="group_type"
                  placeholder={userData.group_type_desc}
                  data={selectGroupType}
                  accepter={SelectPicker}
                  onChange={SelectGroupType}
                />
                <Form.HelpText tooltip>Required</Form.HelpText>
              </Form.Group>
              {isShowGroupOrStore && !isAWG && (
                <Form.Group controlId="Group_Type_ID">
                  <Form.ControlLabel>Select Group:</Form.ControlLabel>
                  <Form.Control
                    style={{ width: 360 }}
                    name="Group_Type_ID"
                    //placeholder={userData.nID_Type}
                    valueKey="groupStore_uid"
                    labelKey="groupStore_desc"
                    data={groupAndGroupID}
                    accepter={SelectPicker}
                  />
                  <Form.HelpText tooltip>Required</Form.HelpText>
                </Form.Group>
              )}
              <ButtonToolbar>
                {/*                 <Button
                  style={{ width: 100, marginLeft: 20 }}
                  appearance="primary"
                  color="red"
                  type="reset"
                >
                  Reset
                </Button> */}
                <Button
                  style={{ width: 360, marginLeft: 190 }}
                  appearance="primary"
                  color="blue"
                  type="submit"
                >
                  Update User
                </Button>
              </ButtonToolbar>
            </Form>
            <Divider />
          </Col>
        </Row>
      </>
    );
  } else {
    return <div>You have to contact Admin</div>;
  }
};

export default UpdateUser;
