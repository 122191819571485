/* eslint-disable no-restricted-globals */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useMemo } from "react";
import { Button, Table } from "rsuite";
// import TableComponent from "./tableComponent";
import TableComponent from "./TableCompMarkdownSetup";
import * as SettingsServices from "services/settingsServices";
import * as UMSService from "services/umsService";
import * as AuthService from "services/authService";
import PageHeaderComponent from "components/common/pageHeaderComponent";
import { MarkdownDiscountConstants } from "./MarkdownConstants";

const MarkdownSetup = (props: any) => {
  const { Cell } = Table;
  const { userContext = {}, handleSpinner } = props;

  const [discountData, setDiscountData] = useState([]);
  const [labelData, setLabelData] = useState([]);
  const [storeID, setStoreID] = useState(userContext ? userContext.store : " ");
  const [storeName, setStoreName] = useState([]);
  const [isOpenSave, setIsOpenSave] = useState(false);

  const [groupAndGroupID, setGroupAndGroupID] = useState([
    { groupStore_uid: "", groupStore_desc: "" },
  ]);
  const [storeNameHeader, setStoreNameHeader] = useState([]);

  const awgOrGot = [
    { groupStore_uid: "1", groupStore_desc: "GOT" },
    { groupStore_uid: "2", groupStore_desc: "AWG" },
  ];
  const [awgOrGotSelect, setAwgOrGotSelect] = useState(1);
  const [groupTypeID, setGroupTypeID] = useState(null);
  const [userStore, setUserStore] = useState("");
  useEffect(() => {
    if (userContext && userContext.roleName === "Super Admin") {
      getVendorStoreName(groupTypeID);
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId !== 0
    ) {
      getVendorStoreName(userContext.groupTypeId);
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0
    ) {
      getVendorStoreName(groupTypeID);
    }
  }, [groupTypeID, userContext]);

  useEffect(() => {
    if (
      userContext &&
      userContext.roleName &&
      userContext.roleName === "Super Admin"
    ) {
      GetGroupAndStoreByTypes(awgOrGotSelect, "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 1 &&
      userContext.store === 0
    ) {
      GetGroupAndStoreByTypes("1", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 2 &&
      userContext.store === 0
    ) {
      GetGroupAndStoreByTypes("2", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.store !== 0
    ) {
      getStoreName(userContext.store);
      setUserStore(userContext.store);
    }
  }, [props.userContext, awgOrGotSelect]);

  useEffect(() => {
    getDiscountData(userStore, "Discount");
    getLabelData(userStore, "Label");
  }, [userStore]);

  const getDiscountData = async (StoreID, Operation: "Discount") => {
    const { handleSpinner } = props;
    handleSpinner && handleSpinner(true);
    SettingsServices.GetMarkdownDiscountSetup(StoreID, Operation)
      .then((result) => {
        //setScheduleTime(result.records.map(item => item["department_Store_Name"]));
        //console.log(combinedData);
        //setScheduleTime(combinedData);
        setDiscountData(result.records);
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      })
      .finally(() => {
        handleSpinner && handleSpinner(false);
      });
  };

  const getLabelData = async (StoreID, Operation: "Label") => {
    const { handleSpinner } = props;
    handleSpinner && handleSpinner(true);
    SettingsServices.GetMarkdownLabelSetup(StoreID, Operation)
      .then((result) => {
        setLabelData(result.records);
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      })
      .finally(() => {
        handleSpinner && handleSpinner(false);
      });
  };

  /*   useEffect(() => {
    setScheduleTimeVendor(VendorStoreName[0].vendor_ID);
    setScheduleTimeStore(VendorStoreName[0].store_ID);
  }, [VendorStoreName]); */

  useEffect(() => {
    if (
      userContext &&
      userContext.groupType === 2 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("2", "StoreType");

    if (
      userContext &&
      userContext.groupType === 1 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("1", "StoreType");

    if (
      userContext &&
      userContext.groupType === 3 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("3", "StoreType");
  }, [userContext]);

  //#region Get Group and Store by Types
  const GetFirstAllStore = async (IDType, operation) => {
    userContext &&
      !userContext.store &&
      UMSService.GetGroupAndStoreByTypes(IDType, operation)
        .then((result) => {
          var setArrayName = [];
          var setArrayID = [];
          if (IDType === "2") {
            result.map((item) => {
              return (item.groupStore_desc =
                item.groupStore_uid + " / " + item.groupStore_desc);
            });
          }
          result.sort((a, b) => {
            const Store_A = a.groupStore_uid;
            const Store_B = b.groupStore_uid;

            if (Store_A < Store_B) {
              return -1;
            } else if (Store_A > Store_B) {
              return 1;
            } else {
              return 0;
            }
          });

          result.filter((item) => {
            if (!setArrayID.includes(item.groupStore_uid)) {
              setArrayID.push(item.groupStore_uid);
            }
          });
          result.filter((item) => {
            if (!setArrayName.includes(item.groupStore_desc)) {
              setArrayName.push(item.groupStore_desc);
            }
          });
          setStoreName(setArrayName);
          setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
        })
        .catch((err) => {
          console.error("Error:  - ", err);
          // toast.error("Error Loading Orders");
        });
  };

  //#region Get Group and Store by Types
  const GetGroupAndStoreByTypes = async (IDType, operation) => {
    UMSService.GetGroupAndStoreByTypes(IDType, operation)
      .then((result) => {
        result.sort((a, b) => {
          const Store_A = a.groupStore_desc.toUpperCase();
          const Store_B = b.groupStore_desc.toUpperCase();

          if (Store_A < Store_B) {
            return -1;
          } else if (Store_A > Store_B) {
            return 1;
          } else {
            return 0;
          }
        });
        //console.log(result);
        setGroupAndGroupID(result);
      })
      .catch((err) => {
        console.error("Error:  - ", err);
        // toast.error("Error Loading Orders");
      });
  };

  //#region Vendor Store Name

  const getVendorStoreName = async (grouptypeID1 = "") => {
    const { handleSpinner, userContext = {} } = props;
    const { groupType, store, user_ID } = userContext;
    handleSpinner && handleSpinner(true);
    if (store) {
      SettingsServices.getVendorStoreName(store, "Store")
        .then((result) => {
          //console.log("StoreName: Result - ", result.records);
          var setArrayID = [];
          var setArrayName = [];

          result.records.filter((item) => {
            if (!setArrayID.includes(item.store_ID)) {
              setArrayID.push(item.store_ID);
            }
          });
          result.records.filter((item) => {
            if (!setArrayName.includes(item.store_Name)) {
              setArrayName.push(item.store_Name);
            }
          });

          setStoreName(setArrayName);
          setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    } else {
      var storesArray = [];
      AuthService.getDMStores(user_ID)
        .then((result1) => {
          result1.records.map((item) => storesArray.push(item.stores));
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        });
      SettingsServices.getVendorStoreName(grouptypeID1, "")
        .then((result) => {
          if (groupType === 2) {
            result.records.map((item) => {
              return (item.store_Name =
                item.store_ID + " / " + item.store_Name);
            });
          }
          result.records.sort((a, b) => {
            const Store_A = a.store_ID;
            const Store_B = b.store_ID;

            if (Store_A < Store_B) {
              return -1;
            } else if (Store_A > Store_B) {
              return 1;
            } else {
              return 0;
            }
          });
          if (storesArray.length >= 1) {
            result.records = result.records.filter((item) =>
              storesArray.includes(item.store_ID.toString())
            );
          }
          //console.log("StoreName: Result - ", result.records);
          var setArrayID = [];
          var setArrayName = [];

          result.records.filter((item) => {
            if (!setArrayID.includes(item.store_ID)) {
              setArrayID.push(item.store_ID);
            }
          });
          result.records.filter((item) => {
            if (!setArrayName.includes(item.store_Name)) {
              setArrayName.push(item.store_Name);
            }
          });

          setStoreName(setArrayName);
          setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    }
  };

  const getStoreName = (storeID) => {
    SettingsServices.GetAdGroupandGroupID(storeID)
      .then((result) => {
        setStoreNameHeader(result.records[0].description);
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      });
  };

  // //#region Excel Export
  // const exportToExcel = (excelData, fileName) => {
  //   const ws = XLSX.utils.json_to_sheet(excelData);
  //   const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  //   const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  //   const data = new Blob([excelBuffer], { type: Constants.fileType });
  //   FileSaver.saveAs(data, fileName + Constants.fileExtension);
  // };
  // //#endregion

  // const DeleteDiscountSetup = (uid) => {
  //   console.log(uid);

  //   SettingsServices.DeleteMarkdownDiscountSetup(uid?.uid)
  //     .then((result) => {
  //       console.log(result);
  //       getDiscountData(userStore, "Discount");
  //       setOpenDiscountSetup(false);
  //     })
  //     .catch((err) => {
  //       console.error("Error:  - ", err);
  //       // toast.error("Error Loading Orders");
  //     });
  // };

  const EditableCell = ({ rowData, dataKey, onChange, ...props }) => {
    const editing = true;
    const inputElement = useMemo(() => {
      return (
        <input
          //onKeyUp={(e) => e.preventDefault()}
          type="text"
          style={{
            width: `${
              dataKey === "title1" || dataKey === "title2" ? "130px" : "40px"
            }`,
            margin: "auto",
            textAlign: "center",
          }}
          value={rowData[dataKey]}
          //max={rowData["credit_Qty"]}
          className=" py-1 text-center outline-none text-center"
          onChange={(event) => {
            onChange && onChange(rowData.uid, dataKey, event.target.value);
          }}
        />
      );
    }, [rowData, dataKey, onChange]);
    return (
      <Cell {...props} className={editing ? "table-content-editing" : ""}>
        {editing && inputElement}
      </Cell>
    );
  };
  // const handleChange = (id, key, value) => {
  //   if (key === "coupon_Percent") {
  //     value = Number(value) || "0";
  //     const nextData = discountData.map((item) =>
  //       item.uid === id ? { ...item, [key]: value } : item
  //     );
  //     setDiscountData(nextData);
  //   }
  //   if (key === "title1" || key === "title2") {
  //     value = value || "";
  //     const nextData = labelData.map((item) =>
  //       item.uid === id ? { ...item, [key]: value } : item
  //     );
  //     setLabelData(nextData);
  //   }
  // };

  const handleChange = (id, key, value) => {
    value = value ? value : "0";
    value ? setIsOpenSave(true) : setIsOpenSave(false);
    function updateOrAddObjectByUID(array, newObj) {
      // UID'ye göre mevcut objeyi bul
      const existingIndex = array.findIndex((obj) => obj.uid === newObj.uid);

      // Eğer mevcut obje bulunursa, onu güncelle
      if (existingIndex !== -1) {
        array[existingIndex] = newObj;
      } else {
        // Eğer mevcut obje bulunmazsa, yeni objeyi ekle
        array.push(newObj);
      }

      return array;
    }
    if (key === "coupon_Percent") {
      var nextDataDiscount = Object.assign([], discountData);
      var activeItemDisc = Array(
        nextDataDiscount.find((item) => item.uid === id)
      );
      activeItemDisc.find((item) => item.uid === id)[key] = value;

      const combinedDisc = updateOrAddObjectByUID(
        nextDataDiscount,
        activeItemDisc[0]
      );
      setDiscountData(combinedDisc);
    } else {
      var nextDataLabel = Object.assign([], labelData);
      var activeItemLabel = Array(
        nextDataLabel.find((item) => item.uid === id)
      );
      activeItemLabel.find((item) => item.uid === id)[key] = value;

      const combinedLabel = updateOrAddObjectByUID(
        nextDataLabel,
        activeItemLabel[0]
      );
      setLabelData(combinedLabel);
    }
  };

  // const sendDataLabel = (value) => {
  //   for (let index = 0; index < value.length; index++) {
  //     const element = value[index];
  //     getCreateData(
  //       element &&
  //         (Number(element.user_Ord_Adj_Qty) >= 1 ||
  //           Number(element.user_Credit_Adj_Qty) >= 1)
  //         ? element
  //         : null
  //     );
  //   }
  // };
  const sendDataDiscount = (value) => {
    for (let index = 0; index < value.length; index++) {
      const element = value[index];
      sendDiscountData(element);
    }
  };

  const sendDiscountData = (value) => {
    SettingsServices.InsertMarkdownDiscountSetup(value)
      .then((result) => {})
      .catch((err) => {
        console.error("Error:  - ", err);
        // toast.error("Error Loading Orders");
      });
  };

  const sendDataLabel = (value) => {
    for (let index = 0; index < value.length; index++) {
      const element = value[index];
      sendLabelData(element);
    }
  };

  const sendLabelData = (value) => {
    SettingsServices.InsertMarkdownLabelSetup(value)
      .then((result) => {})
      .catch((err) => {
        console.error("Error:  - ", err);
        // toast.error("Error Loading Orders");
      });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    handleSpinner && handleSpinner(true);
    sendDataLabel(labelData);
    sendDataDiscount(discountData);
    setTimeout(() => {
      getDiscountData(userStore, "Discount");
      getLabelData(userStore, "Label");
      setIsOpenSave(false);
      handleSpinner && handleSpinner(false);
    }, 2000);
  };

  return (
    <>
      <div className="d-flex flex-row justify-content-start align-items-center">
        <PageHeaderComponent
          setGroupTypeID={setGroupTypeID}
          setAwgOrGotSelect={setAwgOrGotSelect}
          setUserStore={setUserStore}
          userContext={userContext}
          groupAndGroupID={groupAndGroupID}
          awgOrGot={awgOrGot}
          storeName={storeName}
          storeNameHeader={storeNameHeader}
          storeID={storeID}
          isVendor={false}
          isCalendar={false}
          isDepartment={false}
        />
        {/* 
        <Dropdown
          renderToggle={(_props, _ref) => (
            <div className="filter" ref={_ref} {..._props}>
              <Icon icon="pajamas:export" /> Export
            </div>
          )}
          className="ms-2 me-0 button-fixed"
          appearance="link"
          placement="bottomEnd"
        >
          <Dropdown.Item
            icon={<FileDownloadIcon />}
            onClick={() => {
              exportToExcel(discountData, `Markdown Setup ${userStore}`);
            }}
          >
            Export to Excel
          </Dropdown.Item>
        </Dropdown> */}
      </div>
      <span className="d-flex flex-column justify-content-center  align-items-center mb-2">
        <div className="d-flex justify-content-center  align-items-center">
          <h4
            style={{
              textAlign: "center",
              color: "green",
              marginRight: "100px",
            }}
          >
            Label Setup
          </h4>
        </div>

        <h6
          style={{
            textAlign: "center",
            color: "red",
            marginRight: "100px",
            marginTop: "10px",
          }}
        >
          Store will override Group
        </h6>

        {/* <div>
          {" "}
          {userStore && (
            <Button
              color="green"
              appearance="primary"
              onClick={(e) => MarkdownLabelSetupFunc()}
            >
              Setup
            </Button>
          )}
        </div> */}
      </span>
      <div className="d-flex justify-content-end align-items-center mb-2">
        {isOpenSave ? (
          <Button
            color="green"
            appearance="primary"
            onClick={(e) => handleSubmit(e)}
          >
            Save Changes
          </Button>
        ) : (
          <div></div>
        )}
      </div>

      <TableComponent
        isFromModal={false}
        tableDetails={MarkdownDiscountConstants["MarkdownLabel"]}
        reportList={labelData}
        iconColumn={""}
        EditableCell={EditableCell}
        handleChange={handleChange}
        isTableCustomStriped={false}
        onTableRowClick={() => {}}
        reportType={""}
        totalTableRow={{}}
        isTotalRowRequired={false}
      />
      <span className="d-flex justify-content-center  align-items-center mb-2 mt-4">
        <h4
          style={{ textAlign: "center", color: "green", marginRight: "100px" }}
        >
          Discount Setup
        </h4>
        {/* <div>
          {userStore && (
            <>
              <Button
                className="mx-2"
                color="green"
                appearance="primary"
                onClick={(e) => MarkdownDiscountSetupFunc()}
              >
                Insert
              </Button>
              <Button
                className="mx-2"
                color="green"
                appearance="primary"
                onClick={(e) => console.log(e)}
              >
                Edit
              </Button> 
              <Button
                className="mx-2"
                color="red"
                appearance="primary"
                onClick={(e) => console.log(e)}
              >
                Delete
              </Button>
            </>
          )}
        </div> */}
      </span>
      <TableComponent
        isFromModal={false}
        tableDetails={MarkdownDiscountConstants["MarkdownDiscount"]}
        reportList={discountData}
        iconColumn={""}
        onTableRowClick={() => {}}
        onTableRowClickEye={(e) => {}}
        EditableCell={EditableCell}
        handleChange={handleChange}
        reportType={""}
        totalTableRow={{}}
        isTotalRowRequired={false}
      />

      {/* Mark Down Label Setup Modal */}
      {/* <Modal
        style={{ overflow: "hidden" }}
        size={"md"}
        open={openLabelSetup}
        onClose={handleCloseLabelSetup}
      >
        <Modal.Header>
          <Modal.Title>
            <div className="text-center">Markdown Label Setup</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflow: "hidden" }}>
          <MarkdownLabelSetup
            uIDGroup={uIDGroup}
            uIDStore={uIDStore}
            labelData={labelData}
            userStore={userStore}
            getLabelData={getLabelData}
            setOpenLabelSetup={setOpenLabelSetup}
          />
        </Modal.Body>
      </Modal> */}

      {/* Mark Down Discount Setup Modal */}
      {/* <Modal
        style={{ overflow: "hidden" }}
        size={"md"}
        open={openDiscountSetup}
        onClose={handleCloseDiscountSetup}
      >
        <Modal.Header>
          <Modal.Title>
            <div className="text-center">Markdown Discount Setup</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflow: "hidden" }}>
          <MarkdownDiscountSetup
            uIDGroup={uIDGroup}
            uIDStore={uIDStore}
            labelData={labelData}
            userStore={userStore}
            userContext={userContext}
            getDiscountData={getDiscountData}
            setOpenDiscountSetup={setOpenDiscountSetup}
          />
        </Modal.Body>
      </Modal> */}
    </>
  );
};

export default MarkdownSetup;
