import { useEffect, useState } from "react";
import { Dropdown,SelectPicker } from "rsuite";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Constants from "../../.././utils/constants";
import TableComponent from "../../common/tableComponent";
import { Icon } from "@iconify/react";
import * as SettingsServices from "../../../services/settingsServices";
import * as UMSService from "../../../services/umsService";
import * as AuthService from "../../../services/authService";
import { DeliveryScheduleConstansts } from "./DeliveryScheduleConstants";
import PageHeaderComponent from "../../../components/common/pageHeaderComponent";

const DeliverySchedule = (props: any) => {
  const { handleSpinner, userContext = {} } = props;

  const [VendorStoreName, setVendorStoreName] = useState([]);
  const [scheduleTime, setScheduleTime] = useState([]);
  const [scheduleTimeVendor, setScheduleTimeVendor] = useState(" ");
  const [scheduleTimeStore, setScheduleTimeStore] = useState(" ");
  const [allStoreName, setAllStoreName] = useState([]);
  const [allVendorName, setAllVendorName] = useState([]);
  const [vendorID, setVendorID] = useState(allVendorName[1]);
  const [storeID, setStoreID] = useState(userContext ? userContext.store : " ");

  const [storeName, setStoreName] = useState([]);

  const [groupAndGroupID, setGroupAndGroupID] = useState([
    { groupStore_uid: "", groupStore_desc: "" },
  ]);
  const [storeNameHeader, setStoreNameHeader] = useState([]);

  const [awgOrGot, setAwgOrGot] = useState([
    { groupStore_uid: "1", groupStore_desc: "GOT" },
    { groupStore_uid: "2", groupStore_desc: "AWG" },
  ]);
  const [awgOrGotSelect, setAwgOrGotSelect] = useState(1);
  const [groupTypeID, setGroupTypeID] = useState(null);
  const [userStore, setUserStore] = useState("");
  useEffect(() => {
    if (userContext && userContext.roleName === "Super Admin") {
      getVendorStoreName(groupTypeID);
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId !== 0
    ) {
      getVendorStoreName(userContext.groupTypeId);
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0
    ) {
      getVendorStoreName(groupTypeID);
    }
  }, [groupTypeID, userContext]);

  useEffect(() => {
    if (
      userContext &&
      userContext.roleName &&
      userContext.roleName === "Super Admin"
    ) {
      GetGroupAndStoreByTypes(awgOrGotSelect, "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 1 &&
      userContext.store === 0
    ) {
      GetGroupAndStoreByTypes("1", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 2 &&
      userContext.store === 0
    ) {
      GetGroupAndStoreByTypes("2", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.store !== 0
    ) {
      getStoreName(userContext.store);
      setUserStore(userContext.store);
    }
  }, [props.userContext, awgOrGotSelect]);

  useEffect(() => {
    getScheduleTime(vendorID, userStore);
  }, [vendorID, userStore]);

  /*   useEffect(() => {
    setScheduleTimeVendor(VendorStoreName[0].vendor_ID);
    setScheduleTimeStore(VendorStoreName[0].store_ID);
  }, [VendorStoreName]); */

  useEffect(() => {
    if (
      userContext &&
      userContext.groupType === 2 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("2", "StoreType");

    if (
      userContext &&
      userContext.groupType === 1 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("1", "StoreType");

    if (
      userContext &&
      userContext.groupType === 3 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("3", "StoreType");
  }, [userContext]);

  useEffect(() => {
    if (userContext && !userContext.store) {
      SettingsServices.getVendorStoreName(userStore, "Store")
        .then((result) => {
          //console.log("StoreName: Result - ", result.records);
          const uniqueVendor = new Set();
          result.records.forEach((item) => {
            const { vendor_ID, vendor_Name } = item;
            uniqueVendor.add(JSON.stringify({ vendor_ID, vendor_Name }));
          });
          const uniqueVendorData = Array.from(uniqueVendor).map((store) =>
            JSON.parse(store as string)
          );

          uniqueVendorData.sort((a, b) => {
            const Store_A = a.vendor_Name;
            const Store_B = b.vendor_Name;

            if (Store_A < Store_B|| Store_A.includes("AWG KC")) {
              return -1;
            } else if (Store_A > Store_B ) {
              return 1;
            } else {
              return 0;
            }
          });
          setAllVendorName(uniqueVendorData);
          setVendorID(uniqueVendorData[0].vendor_ID.toString());
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {});
    }
  }, [userStore, userContext]);
  //#region Get Group and Store by Types
  const GetFirstAllStore = async (IDType, operation) => {
    userContext &&
      !userContext.store &&
      UMSService.GetGroupAndStoreByTypes(IDType, operation)
        .then((result) => {
          var setArrayName = [];
          var setArrayID = [];
          if (IDType === "2") {
            result.map((item) => {
              return (item.groupStore_desc =
                item.groupStore_uid + " / " + item.groupStore_desc);
            });
          }
          result.sort((a, b) => {
            const Store_A = a.groupStore_uid;
            const Store_B = b.groupStore_uid;

            if (Store_A < Store_B) {
              return -1;
            } else if (Store_A > Store_B) {
              return 1;
            } else {
              return 0;
            }
          });

          result.filter((item) => {
            if (!setArrayID.includes(item.groupStore_uid)) {
              setArrayID.push(item.groupStore_uid);
            }
          });
          result.filter((item) => {
            if (!setArrayName.includes(item.groupStore_desc)) {
              setArrayName.push(item.groupStore_desc);
            }
          });
          setStoreName(setArrayName);
          setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
        })
        .catch((err) => {
          console.error("Error:  - ", err);
          // toast.error("Error Loading Orders");
        });
  };

  //#region Get Group and Store by Types
  const GetGroupAndStoreByTypes = async (IDType, operation) => {
    UMSService.GetGroupAndStoreByTypes(IDType, operation)
      .then((result) => {
        result.sort((a, b) => {
          const Store_A = a.groupStore_desc.toUpperCase();
          const Store_B = b.groupStore_desc.toUpperCase();

          if (Store_A < Store_B) {
            return -1;
          } else if (Store_A > Store_B) {
            return 1;
          } else {
            return 0;
          }
        });
        //console.log(result);
        setGroupAndGroupID(result);
      })
      .catch((err) => {
        console.error("Error:  - ", err);
        // toast.error("Error Loading Orders");
      });
  };

  //#region Vendor Store Name

  const getVendorStoreName = async (grouptypeID1 = "") => {
    const { handleSpinner, userContext = {} } = props;
    const { groupTypeId, groupType, store,user_ID } = userContext;
    handleSpinner && handleSpinner(true);
    if (store) {
      SettingsServices.getVendorStoreName(store, "Store")
        .then((result) => {
          //console.log("StoreName: Result - ", result.records);
          var setArrayID = [];
          var setArrayName = [];
          const uniqueVendor = new Set();

          result.records.filter((item) => {
            if (!setArrayID.includes(item.store_ID)) {
              setArrayID.push(item.store_ID);
            }
          });
          result.records.filter((item) => {
            if (!setArrayName.includes(item.store_Name)) {
              setArrayName.push(item.store_Name);
            }
          });
          result.records.forEach((item) => {
            const { vendor_ID, vendor_Name } = item;
            uniqueVendor.add(JSON.stringify({ vendor_ID, vendor_Name }));
          });
          const uniqueVendorData = Array.from(uniqueVendor).map((store) =>
            JSON.parse(store as string)
          );
          uniqueVendorData.sort((a, b) => {
            const Store_A = a.vendor_Name;
            const Store_B = b.vendor_Name;

            if (Store_A < Store_B|| Store_A.includes("AWG KC")) {
              return -1;
            } else if (Store_A > Store_B ) {
              return 1;
            } else {
              return 0;
            }
          });
          setAllVendorName(uniqueVendorData);
          setVendorID(uniqueVendorData[0].vendor_ID.toString());
          setStoreName(setArrayName);
          setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    } else {

      var storesArray = [];
      AuthService.getDMStores(user_ID)
        .then((result1) => {
          result1.records.map((item) => storesArray.push(item.stores));
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        });
      SettingsServices.getVendorStoreName(grouptypeID1, "")
        .then((result) => {
          if (groupType === 2) {
            result.records.map((item) => {
              return (item.store_Name =
                item.store_ID + " / " + item.store_Name);
            });
          }
          result.records.sort((a, b) => {
            const Store_A = a.store_ID;
            const Store_B = b.store_ID;

            if (Store_A < Store_B) {
              return -1;
            } else if (Store_A > Store_B) {
              return 1;
            } else {
              return 0;
            }
          });
          if (storesArray.length >= 1) {
            result.records = result.records.filter((item) =>
              storesArray.includes(item.store_ID.toString())
            );
          }
          //console.log("StoreName: Result - ", result.records);
          var setArrayID = [];
          var setArrayName = [];
          const uniqueVendor = new Set();

          result.records.filter((item) => {
            if (!setArrayID.includes(item.store_ID)) {
              setArrayID.push(item.store_ID);
            }
          });
          result.records.filter((item) => {
            if (!setArrayName.includes(item.store_Name)) {
              setArrayName.push(item.store_Name);
            }
          });
          result.records.forEach((item) => {
            const { vendor_ID, vendor_Name } = item;
            uniqueVendor.add(JSON.stringify({ vendor_ID, vendor_Name }));
          });
          const uniqueVendorData = Array.from(uniqueVendor).map((store) =>
            JSON.parse(store as string)
          );

          uniqueVendorData.sort((a, b) => {
            const Store_A = a.vendor_Name;
            const Store_B = b.vendor_Name;

            if (Store_A < Store_B|| Store_A.includes("AWG KC")) {
              return -1;
            } else if (Store_A > Store_B ) {
              return 1;
            } else {
              return 0;
            }
          });
          setAllVendorName(uniqueVendorData);
          setVendorID(uniqueVendorData[0].vendor_ID.toString());
          setStoreName(setArrayName);
          setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    }
  };

  const getStoreName = (storeID) => {
    SettingsServices.GetAdGroupandGroupID(storeID)
      .then((result) => {
        setStoreNameHeader(result.records[0].description);
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      });
  };

  const getScheduleTime = async (scheduleTimeVendor, scheduleTimeStore) => {
    const { handleSpinner } = props;
    handleSpinner && handleSpinner(true);
    SettingsServices.getScheduleTime(scheduleTimeVendor, scheduleTimeStore)
      .then((result) => {
        //setScheduleTime(result.records.map(item => item["department_Store_Name"]));

        //console.log(result.records)
        // const combinedData = result.records.reduce((acc, current) => {
        //   const storeName = current.department_Store_Name;
        //   console.log(acc)
        //   if (!acc[storeName]) {
        //     acc[storeName] = { ...current }; // Create a new entry for the store
        //   } else {
        //     // Combine properties of existing entry and current object
        //     acc[storeName] = {
        //       ...acc[storeName],
        //       ...current,
        //     };
        //   }
        //   return acc;
        // }, {});

        let mergedData = {};
        result.records.forEach((item) => {
          if (!mergedData[item.department_Store_Name]) {
            mergedData[item.department_Store_Name] = { ...item };
          } else {
            Object.keys(item).forEach((key) => {
              if (key !== "department_Store_Name") {
                if (!mergedData[item.department_Store_Name][key]) {
                  mergedData[item.department_Store_Name][key] = item[key];
                }
              }
            });
          }
        });
        setScheduleTime(Object.values(mergedData));

        //console.log(combinedData);
        //setScheduleTime(combinedData);
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      })
      .finally(() => {
        handleSpinner && handleSpinner(false);
      });
  };

  //#region Excel Export
  const exportToExcel = (excelData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: Constants.fileType });
    FileSaver.saveAs(data, fileName + Constants.fileExtension);
  };
  //#endregion
  const onOptionChangeStore = (event) => {
    const VendorAndStore = event.target.value.split(",");
    setStoreID(VendorAndStore[0]);
    //setShowAllData(true);
  };
  const onOptionChangeVendor = (event) => {
    const VendorAndStore = event;
    setVendorID(VendorAndStore);
    //setShowAllData(true);
  };
  return (
    <>
      <div className="d-flex flex-row justify-content-start align-items-center">
        <PageHeaderComponent
          setGroupTypeID={setGroupTypeID}
          setAwgOrGotSelect={setAwgOrGotSelect}
          setUserStore={setUserStore}
          userContext={userContext}
          groupAndGroupID={groupAndGroupID}
          awgOrGot={awgOrGot}
          storeName={storeName}
          storeNameHeader={storeNameHeader}
          storeID={storeID}
          onOptionChangeVendor={onOptionChangeVendor}
          isVendor={true}
          allVendorName={allVendorName}
          isCalendar={false}
          isDepartment={false}
        />
        {/* <SelectPicker
          style={{ margin: "0 10px" }}
          onChange={onOptionChangeVendor}
          label="Vendor"
          virtualized
          placeholder={
            allVendorName && allVendorName[0]
              ? allVendorName[0].vendor_Name
              : "All"
          }
          // placeholder="All"
          data={allVendorName.map((item, index) => ({
            label: item.vendor_Name,
            value: item.vendor_ID,
          }))}
        /> */}
        <Dropdown
          renderToggle={(_props, _ref) => (
            <div className="filter" ref={_ref} {..._props}>
              <Icon icon="pajamas:export" /> Export
            </div>
          )}
          className="ms-2 me-0 button-fixed"
          appearance="link"
          placement="bottomEnd"
        >
          <Dropdown.Item
            icon={<FileDownloadIcon />}
            onClick={() => {
              exportToExcel(
                scheduleTime,
                `Delivery Schedule ${userStore}  ${vendorID}`
              );
            }}
          >
            Export to Excel
          </Dropdown.Item>
        </Dropdown>
      </div>

      <TableComponent
        isFromModal={false}
        tableDetails={DeliveryScheduleConstansts["DeliverySchedule"]}
        reportList={scheduleTime}
        iconColumn={""}
        onTableRowClick={() => {}}
        reportType={""}
        totalTableRow={{}}
        isTotalRowRequired={false}
      />
    </>
  );
};

export default DeliverySchedule;
