/* eslint-disable array-callback-return */
import { useEffect, useRef, useState } from "react";
import { Pagination, Tooltip, Whisper } from "rsuite";
import Constants from "../../.././utils/constants";
import { Icon } from "@iconify/react";

const DetailLastTableComponent = (props: any) => {
  const {
    isFromModal,
    tableDetails,
    reportList,
    iconColumn,
    onTableRowClick,
    totalTableRow,
    isTotalRowRequired,
    DisplayNoDatatlabel,
    isAWG,
    showAll,
    handleShowAll,
    selectedtab,
    isTableCustomStriped,
  } = props;
  const [sortColumn, setSortColumn] = useState("");
  const [sortType, setSortType] = useState("asc");
  const [limit, setLimit] = useState(200);
  const [page, setPage] = useState(1);
  const scrollRef = useRef(null);
  useEffect(() => {
    setSortColumn("");
    setSortType("asc");
    setPage(1);
  }, [tableDetails]);

  useEffect(() => {
    setPage(1);
  }, [reportList]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
      scrollRef.current.scrollLeft = 0;
    }
  }, [tableDetails, page]);

  const data =
    reportList &&
    reportList.filter((v, i) => {
      const start = limit * (page - 1);
      const end = start + limit;
      return i >= start && i < end;
    });
  //#region  Table Sort
  const getData = () => {
    if (sortColumn && sortType) {
      return data.sort((a, b) => {
        let x = a[sortColumn] ? a[sortColumn] : "";
        let y = b[sortColumn] ? b[sortColumn] : "";
        if (sortType === "asc") {
          return x
            .toString()
            .localeCompare(y.toString(), undefined, { numeric: true });
        } else {
          return y
            .toString()
            .localeCompare(x.toString(), undefined, { numeric: true });
        }
      });
    }
    return data;
  };

  const handleSortColumn = (_sortColumn) => {
    setSortColumn(_sortColumn);
    const _sortType =
      _sortColumn === sortColumn
        ? sortType === "asc"
          ? "desc"
          : "asc"
        : "asc";
    setSortType(_sortType);
    // ? why timeout?
    // setLoading(true);
    setTimeout(() => {
      // setLoading(false);
    }, 500);
  };
  //#endregion

  //#region Pagination
  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };
  //#endregion

  const hoverData = (hData) => {
    switch (hData) {
      case "D":
        return "Default";
      case "M":
        return "Manual Price";

      default:
        return "Percent";
    }
  };

  const getRowColor = (_result, _Idx, colorToggle, setClrToggle) => {
    let classname = "";
    if (_Idx === 0) {
      classname = "table-row-blue";
    } else {
      if (_result["store_ID"] !== getData()[_Idx - 1]["store_ID"]) {
        classname = "table-row-blue";
        setClrToggle && setClrToggle(!colorToggle);
      } else {
        classname = colorToggle ? "table-row-gray" : "";
      }
    }
    return classname;
  };

  const RenderTableHeaders = () => {
    let currentColWidth = 0;
    let subHeaderColWidth = 0;
    return (
      <>
        {" "}
        <tr>
          {tableDetails &&
            tableDetails.map((item, _idx) => {
              //console.log(item);
              if (item.isHeader && !item.isSubHeader) {
                const thCell = (
                  <th
                    rowSpan={item.colSpan > 1 ? 1 : 2}
                    colSpan={item.colSpan}
                    className={`filterHeader ${
                      item.TypeOfData === "Price"
                        ? "text-end pe-4"
                        : item.TypeOfData === "Number" ||
                          item.TypeOfData === "text-center" ||
                          item.TypeOfData === "text-center-heading"
                        ? "text-center pe-4"
                        : ""
                    } ${
                      item.colSpan > 1
                        ? "text-center pe-4 table-border-header"
                        : ""
                    } ${item.isFixed ? "fixed-column" : ""}`}
                    style={{
                      minWidth: item.Width,
                      left: item.isFixed ? currentColWidth : "inherit",
                    }}
                  >
                    <span
                      onClick={() => {
                        item.isSortable && handleSortColumn(item.DataKey);
                      }}
                      title={
                        item.Header === "Theft"
                          ? "Product was stolen and package was found empty"
                          : item.Header === "Out Of Date"
                          ? "Product thrown out due to being past expiration date"
                          : item.Header === "Recovery"
                          ? "Extra Product used to make product sold by weight"
                          : null
                      }
                    >
                      {}
                      {item.Header}
                      {item.isSortable && sortColumn !== item.DataKey && (
                        <Icon className="ms-1" icon="ep:sort" />
                      )}
                      {item.isSortable && sortColumn === item.DataKey && (
                        <>
                          {sortType === "asc" && (
                            <Icon
                              className="ms-1"
                              icon="material-symbols:arrow-upward-rounded"
                            />
                          )}
                          {sortType === "desc" && (
                            <Icon
                              className="ms-1"
                              icon="material-symbols:arrow-downward-rounded"
                            />
                          )}
                        </>
                      )}
                    </span>
                  </th>
                );
                currentColWidth += item.Width;
                return thCell;
              }
            })}
        </tr>
        {tableDetails &&
          tableDetails.some((_items) => {
            return _items.isHeader;
          }) && (
            <tr>
              {tableDetails &&
                tableDetails.map((item, _idx) => {
                  if (item.isSubHeader) {
                    const thCell = (
                      <th
                        className={`filterHeader table-border-subheader ${
                          item.TypeOfData === "Price"
                            ? "text-end pe-4"
                            : "text-center pe-4"
                        } ${item.isFixed ? "fixed-column" : ""}`}
                        style={{
                          minWidth: item.Width,
                          left: item.isFixed ? subHeaderColWidth : "inherit",
                        }}
                      >
                        <span
                          onClick={() => {
                            item.isSortable && handleSortColumn(item.DataKey);
                          }}
                          title={
                            item.Header === "Theft"
                              ? "Product was stolen and package was found empty"
                              : item.Header === "Out Of Date"
                              ? "Product thrown out due to being past expiration date"
                              : item.Header === "Recovery"
                              ? "Extra Product used to make product sold by weight"
                              : null
                          }
                        >
                          {item.Header}
                          {item.isSortable && sortColumn !== item.DataKey && (
                            <Icon icon="ep:sort" />
                          )}
                          {item.isSortable && sortColumn === item.DataKey && (
                            <>
                              {sortType === "asc" && (
                                <Icon icon="material-symbols:arrow-upward-rounded" />
                              )}
                              {sortType === "desc" && (
                                <Icon icon="material-symbols:arrow-downward-rounded" />
                              )}
                            </>
                          )}
                        </span>
                      </th>
                    );
                    subHeaderColWidth += item.Width;
                    return thCell;
                  }
                })}
            </tr>
          )}
      </>
    );
  };

  const RenderTableRows = () => {
    if (reportList && reportList.length > 0) {
      let colorToggle = true;
      return getData().map((_result, _Idx) => {
        let currentColWidth = 0;
        return (
          <>
            <tr
              className={
                isTableCustomStriped &&
                getRowColor(_result, _Idx, colorToggle, (res) => {
                  colorToggle = res;
                })
              }
              style={{
                fontWeight:
                  _result["isbold"] && _result["isbold"] ? "bold" : "normal",
                borderTop:
                  isTableCustomStriped && _Idx === 0
                    ? "2px solid #3298e9"
                    : isTableCustomStriped &&
                      _result["store_ID"] !== getData()[_Idx - 1]["store_ID"]
                    ? "2px solid #3298e9"
                    : "",
              }}
              key={`TableRows${_Idx}`}
              onClick={() => onTableRowClick(_result)}
            >
              {tableDetails &&
                tableDetails.map((item) => {
                  if (item.isHeader && item.colSpan === 1) {
                    const tdCell = (
                      <td
                        colSpan={1}
                        className={`${
                          item.TypeOfData === "Price"
                            ? "text-end pe-4"
                            : item.TypeOfData === "Number" ||
                              item.TypeOfData === "text-center"
                            ? "text-center pe-4"
                            : ""
                        } ${item.isFixed ? "fixed-column" : ""}`}
                        style={{
                          minWidth: item.Width,
                          left: item.isFixed ? currentColWidth : "inherit",
                        }}
                      >
                        {iconColumn &&
                          iconColumn !== "" &&
                          iconColumn === item.DataKey && (
                            <Icon
                              className="icon-size"
                              icon="ic:outline-remove-red-eye"
                            />
                          )}
                        <span>
                          {item.Icon !== "" &&
                            item.DataKey === "price_Adj" &&
                            _result[item.Icon] > 0 && (
                              <Whisper
                                placement="bottom"
                                controlId="control-id-hover"
                                trigger="hover"
                                speaker={
                                  <Tooltip>Manual Price Entered </Tooltip>
                                }
                              >
                                <Icon
                                  className=" ms-3 flag-icon2 on-hover "
                                  icon="ep:flag"
                                />
                              </Whisper>
                            )}
                        </span>
                        <span>
                          {item.Icon !== "" &&
                            (item.DataKey === "reg_Price" ||
                              item.DataKey === "total") &&
                            _result[item.Icon] > 0 && (
                              <Whisper
                                placement="bottom"
                                controlId="control-id-hover"
                                trigger="hover"
                                speaker={<Tooltip>Regular Price Keyed</Tooltip>}
                              >
                                <Icon
                                  className="flag-icon2 on-hover"
                                  icon="ep:flag"
                                />
                              </Whisper>
                            )}
                        </span>

                        <span>
                          {item.Icon !== "" &&
                            item.DataKey === "CleanWithFlag" &&
                            _result[item.Icon] > 0 && (
                              <Whisper
                                placement="bottom"
                                controlId="control-id-hover"
                                trigger="hover"
                                speaker={<Tooltip>Quick Cleaned</Tooltip>}
                              >
                                <Icon
                                  className="flag-icon3 on-hover "
                                  icon="ep:flag"
                                />
                              </Whisper>
                            )}
                        </span>

                        <span>
                          {item.Icon !== "" &&
                            item.DataKey === "days_Old" &&
                            _result[item.Icon] > 0 &&
                            _result["Badge_Type"] &&
                            _result["Badge_Type"] !== "Without Badge" &&
                            _result["Badge_Type"] !== "No" && (
                              <Whisper
                                placement="bottom"
                                controlId="control-id-hover"
                                trigger="hover"
                                speaker={<Tooltip>Previously Used </Tooltip>}
                              >
                                <Icon
                                  className=" flag-icon3 on-hover "
                                  icon="ep:flag"
                                />
                              </Whisper>
                            )}
                        </span>

                        {item.DataKey === "trueamp" && isAWG ? (
                          <span>
                            {item.DataKey !== "label_Type" ? (
                              item.TypeOfData === "Price" ? (
                                "$" +
                                (_result[item.DataKey]
                                  ? Number(_result[item.DataKey])
                                      .toFixed(2)
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                                    "*"
                                  : "0.00")
                              ) : (
                                _result[item.DataKey]
                              )
                            ) : (
                              <Whisper
                                followCursor
                                placement="bottom"
                                controlId="control-id-hover"
                                trigger="hover"
                                speaker={
                                  <Tooltip>
                                    {hoverData(_result[item.DataKey])}
                                  </Tooltip>
                                }
                              >
                                <span>
                                  {item.TypeOfData === "Price"
                                    ? "$" +
                                      (_result[item.DataKey]
                                        ? Number(_result[item.DataKey])
                                            .toFixed(2)
                                            .toString()
                                            .replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ","
                                            )
                                        : "0.00")
                                    : _result[item.DataKey]}
                                </span>
                              </Whisper>
                            )}
                          </span>
                        ) : (
                          <span>
                            {item.DataKey !== "label_Type" ? (
                              item.TypeOfData === "Price" ? (
                                "$" +
                                (_result[item.DataKey]
                                  ? Number(_result[item.DataKey])
                                      .toFixed(2)
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  : "0.00")
                              ) : (
                                _result[item.DataKey]
                              )
                            ) : (
                              <Whisper
                                followCursor
                                placement="bottom"
                                controlId="control-id-hover"
                                trigger="hover"
                                speaker={
                                  <Tooltip>
                                    {hoverData(_result[item.DataKey])}
                                  </Tooltip>
                                }
                              >
                                <span>
                                  {item.TypeOfData === "Price"
                                    ? "$" +
                                      (_result[item.DataKey]
                                        ? Number(_result[item.DataKey])
                                            .toFixed(2)
                                            .toString()
                                            .replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ","
                                            )
                                        : "0.00")
                                    : _result[item.DataKey]}
                                </span>
                              </Whisper>
                            )}
                          </span>
                        )}
                      </td>
                    );

                    currentColWidth += item.Width;
                    return tdCell;
                  }
                })}
            </tr>
          </>
        );
      });
    }
  };

  return (
    <>
      {reportList && reportList.length >= 0 ? ( // && reportList.length > 0
        <div>
          <div
            ref={scrollRef}
            className={`mt-3 tableContainer table-scroll ${
              isFromModal ? "modal-table" : ""
            }`}
          >
            <table
              className={`table ${isTableCustomStriped ? "" : "table-striped"}`}
            >
              <thead>{RenderTableHeaders()}</thead>
              <tbody>{RenderTableRows()}</tbody>
              {isTotalRowRequired && (
                <tfoot>
                  <tr
                    className={`${showAll ? "showAll" : ""}`}
                    onClick={() => {
                      if (showAll) handleShowAll && handleShowAll();
                    }}
                  >
                    {selectedtab !== "8" ? (
                      showAll ? (
                        <th>
                          {" "}
                          {
                            <Icon
                              className="icon-size"
                              icon="ic:outline-remove-red-eye"
                            />
                          }{" "}
                          All
                        </th>
                      ) : (
                        <th>Total</th>
                      )
                    ) : (
                      ""
                    )}
                    {tableDetails &&
                      tableDetails.map((item, idx) => {
                        if (item.isHeader && item.colSpan === 1) {
                          return (
                            <>
                              {showAll && selectedtab === "8" && idx === 3 && (
                                <td>
                                  {" "}
                                  {
                                    <Icon
                                      className="icon-size"
                                      icon="ic:outline-remove-red-eye"
                                    />
                                  }{" "}
                                  All
                                </td>
                              )}
                              {item.isHeader && idx > 0 && (
                                <td
                                  className={`${
                                    item.TypeOfData === "Price"
                                      ? "text-end pe-4"
                                      : item.TypeOfData === "Number" ||
                                        item.TypeOfData === "text-center"
                                      ? "text-center pe-4"
                                      : ""
                                  } ${item.isFixed ? "fixed-column" : ""}`}
                                >
                                  <span>
                                    {item.TypeOfData === "Price"
                                      ? "$" +
                                        (totalTableRow[item.DataKey]
                                          ? Number(totalTableRow[item.DataKey])
                                              .toFixed(2)
                                              .toString()
                                              .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              )
                                          : "0.00")
                                      : totalTableRow[item.DataKey]}
                                  </span>
                                </td>
                              )}
                            </>
                          );
                        }
                      })}
                  </tr>
                </tfoot>
              )}
            </table>
          </div>
          <div style={{ padding: 20 }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={5}
              size="xs"
              layout={["total", "-", "limit", "|", "pager", "skip"]}
              total={reportList.length}
              limitOptions={Constants.pageLimit}
              limit={limit}
              activePage={page}
              onChangePage={setPage}
              onChangeLimit={handleChangeLimit}
            />
          </div>
        </div>
      ) : // :
      // <></>
      DisplayNoDatatlabel ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "70vh" }}
        >
          <h5>NO DATA FOUND</h5>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default DetailLastTableComponent;
