import { useEffect } from "react";
import TableComponent from "../../common/tableComponent";
import moment from "moment";
import * as SettingsServices from "../../../services/settingsServices";
import { MilkOrderBookConstants } from "./MilkOrderBookConstants";

const MilkOrderDetail = (props: any) => {
  const { itemID, milkOrderBookDetailData,userContext, setMilkOrderBookDetailData } = props;

  //const [milkOrderBookDetailData, setMilkOrderBookDetailData] = useState([]);

  useEffect(() => {
    getOrderMilkDetail();
  }, [itemID]);
  const getOrderMilkDetail = async () => {
    const { handleSpinner, fromDate, Store, Vendor } = props;
    handleSpinner && handleSpinner(true);

    var Store1 = userContext && userContext.store ? userContext.store : Store;
    SettingsServices.GetMilkOrderBookDetail(Store1, Vendor, fromDate, itemID)
      .then((result) => {
        result.records.map((item) => {
          if (item.date) {
            item.date = moment(item.date).format("MM-DD-YYYY");
          }
        });
        setMilkOrderBookDetailData(result.records);
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      })
      .finally(() => {
        handleSpinner && handleSpinner(false);
      });
  };

  return (
    <div>
      <TableComponent
        tableDetails={MilkOrderBookConstants["MilkOrderBookDetail"]}
        reportList={milkOrderBookDetailData}
        iconColumn={""}
        onTableRowClick={() => {}}
        reportType={""}
        totalTableRow={{}}
      />
    </div>
  );
};

export default MilkOrderDetail;
