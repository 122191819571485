export class TableHeaderConfig {
  Header: string; //  "Source";
  DataKey: string; //  "";
  Width: number; //  550;
  isFixed: boolean; //  false;
  isSortable: boolean; //  false;
  Align: "right" | "left" | "center"; //  "left";
  TypeOfData: "Text" | "Price" | "Number" | "text-center" | "text-center-heading"; //  "";
  isSearch: boolean; //  false;
  isHeader: boolean; //  true;
  Icon: string; //  "";
  isSubHeader: boolean; //  false;
  colSpan: number; //  3;
  searchGroup: Array<any>;

  constructor() {
    this.Header = "";
    this.DataKey = "";
    this.Width = 10;
    this.isFixed = false;
    this.isSortable = true;
    this.Align = "left";
    this.TypeOfData = "Text";
    this.isSearch = false;
    this.isHeader = true;
    this.Icon = "";
    this.isSubHeader = false;
    this.colSpan = 1;
    this.searchGroup = [];
  }
}
