/* eslint-disable array-callback-return */
import { useState } from "react";
import { SelectPicker } from "rsuite";
import ReportTrackItByGroupComponent from "./StoreLayer/reportTrackItByGroupComponent";
import TrackITDepartmentMain from "./DepartmentLayer/TrackITDepartmentMain";

const TrackITMain = (props: any) => {
  const { userContext, handleSpinner } = props;

  const [isDepOrStore, setIsDepOrStore] = useState("1");

  const SelectDepOrStore = (event) => {
    setIsDepOrStore(event);
  };
  const DepOrStore = [
    { value: "1", label: "Store" },
    { value: "2", label: "Department" },
  ];
  return (
    <>
      <>
        <SelectPicker
          placement="auto"
          size="sm"
          cleanable={false}
          style={{ marginBottom: "20px" }}
          className="header-bar-item"
          placeholder={DepOrStore.map((item) => {
            if (item.value === isDepOrStore) {
              return item.label;
            }
          })}
          onChange={SelectDepOrStore}
          data={DepOrStore.map((item) => ({
            label: item.label,
            value: item.value,
          }))}
        />
      </>
      {isDepOrStore === "1" ? (
        <ReportTrackItByGroupComponent
          userContext={userContext}
          handleSpinner={handleSpinner}
        />
      ) : (
        <TrackITDepartmentMain
          userContext={userContext}
          handleSpinner={handleSpinner}
        />
      )}
    </>
  );
};

export default TrackITMain;
