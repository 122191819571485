/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Dropdown } from "rsuite";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Constants from "../../.././utils/constants";
import TableComponent from "../../common/tableComponent";
import { Icon } from "@iconify/react";
import moment from "moment";
import * as SettingsServices from "../../../services/settingsServices";
import { OrderDetailAMPConstansts } from "./OrderDetailAMPConstansts";
import Storeandgroupcomponent from "../../storeandgroup/storeandgroupcomponent";

const OrderDetailAmp = (props: any) => {
  const { handleSpinner, userContext = {} } = props;
  const [storeName, setStoreName] = useState([]);
  const [storeID, setStoreID] = useState([]);
  const [orderDetailAmp, setOrderDetailAmp] = useState([]);
  const [userStore, setUserStore] = useState("");
  const [storeNameHeader, setStoreNameHeader] = useState([]);

  // useEffect(() => {
  //   getVendorStoreName();
  // }, [props.userContext]);

  useEffect(() => {
    if (userStore) {
      if (props.userContext && props.userContext.store !== 0) {
        getOrderDetailAmp(props.userContext.store);
      } else {
        getOrderDetailAmp(userStore);
      }
    }
  }, [userStore, props.userContext]);

  // const getVendorStoreName = async () => {
  //   const { handleSpinner, userContext = {} } = props;
  //   const { groupTypeId, store } = userContext;
  //   handleSpinner && handleSpinner(true);
  //   if (store !== 0) {
  //     SettingsServices.getVendorStoreName(store, "Store")
  //       .then((result) => {
  //         //console.log("StoreName: Result - ", result.records);
  //         var setArrayID = [];
  //         var setArrayName = [];
  //         result.records = result.records.filter(
  //           (item) => item.store_ID === store
  //         );
  //         result.records.filter((item) => {
  //           if (!setArrayID.includes(item.store_ID)) {
  //             setArrayID.push(item.store_ID);
  //           }
  //         });
  //         result.records.filter((item) => {
  //           if (!setArrayName.includes(item.store_Name)) {
  //             setArrayName.push(item.store_Name);
  //           }
  //         });

  //         setStoreName(setArrayName);
  //         setStoreID(setArrayID);
  //       })
  //       .catch((err) => {
  //         console.error("Error: Get Data - ", err);
  //         // toast.error("Error Loading Orders");
  //       })
  //       .finally(() => {
  //         handleSpinner && handleSpinner(false);
  //       });
  //   } else {
  //     SettingsServices.getVendorStoreName(groupTypeId, "")
  //       .then((result) => {
  //         //console.log("StoreName: Result - ", result.records);
  //         var setArrayID = [];
  //         var setArrayName = [];
  //         result.records.filter((item) => {
  //           if (!setArrayID.includes(item.store_ID)) {
  //             setArrayID.push(item.store_ID);
  //           }
  //         });
  //         result.records.filter((item) => {
  //           if (!setArrayName.includes(item.store_Name)) {
  //             setArrayName.push(item.store_Name);
  //           }
  //         });

  //         setStoreName(setArrayName);
  //         setStoreID(setArrayID);
  //       })
  //       .catch((err) => {
  //         console.error("Error: Get Data - ", err);
  //         // toast.error("Error Loading Orders");
  //       })
  //       .finally(() => {
  //         handleSpinner && handleSpinner(false);
  //       });
  //   }
  // };
  //console.log(storeName);
  const getOrderDetailAmp = async (Store_ID) => {
    const { handleSpinner, userContext = {} } = props;
    handleSpinner && handleSpinner(true);
    SettingsServices.GetOrderDetailAmp(Store_ID.toString())
      .then((result) => {
        result.records.map(
          (item) =>
            (item.delivery_Date = moment(item.delivery_Date).format(
              "MM-DD-YYYY"
            ))
        );
        result.records.map((item) => {
          item.update_Date = moment(item.update_Date).format("MM-DD-YYYY");
        });
        setOrderDetailAmp(result.records);
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      })
      .finally(() => {
        handleSpinner && handleSpinner(false);
      });
  };

  const onOptionChangeHandler = (event) => {
    const VendorAndStore = event.target.selectedIndex;
    setUserStore(storeID[VendorAndStore]);
  };
  //#region Excel Export
  const exportToExcel = (excelData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: Constants.fileType });
    FileSaver.saveAs(data, fileName + Constants.fileExtension);
  };
  //#endregion

  return (
    <>
      <div className="d-flex flex-nowrap justify-content-between align-items-center">
          {" "}
          {
            <Storeandgroupcomponent
              storeNameHeader={storeNameHeader}
              setStoreNameHeader={setStoreNameHeader}
              userContext={userContext}
              userStore={userStore}
              setUserStore={setUserStore}
              storeName={storeName}
              setStoreName={setStoreName}
              storeID={storeID}
              setStoreID={setStoreID}
            />
          }

        <>
          {" "}
          <Dropdown
            renderToggle={(_props, _ref) => (
              <div className="filter" ref={_ref} {..._props}>
                <Icon icon="pajamas:export" /> Export
              </div>
            )}
            className="ms-2 me-0 button-fixed"
            appearance="link"
            placement="bottomEnd"
          >
            <Dropdown.Item
              icon={<FileDownloadIcon />}
              onClick={() => {
                exportToExcel(
                  orderDetailAmp,
                  `Order Detail AMP  ${userStore} `
                );
              }}
            >
              Export to Excel
            </Dropdown.Item>
          </Dropdown>
        </>
      </div>

      <div>
        {" "}
        <TableComponent
          userContext={userContext}
          userStore={userStore}
          isFromModal={false}
          tableDetails={OrderDetailAMPConstansts["OrderDetailAMPConstansts"]}
          reportList={orderDetailAmp}
          iconColumn={"NA"}
          onTableRowClick={() => {}}
          reportType={""}
          totalTableRow={{}}
          isTotalRowRequired={false}
        />
      </div>
    </>
  );
};

export default OrderDetailAmp;
