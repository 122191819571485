export const GrindConstants = {
    "Grind": [
        
        //{ Header: "UID", DataKey: "uid", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        //{ Header: "Store ID", DataKey: "store_ID", Width: 150, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Store ID", DataKey: "store_ID", Width: 100, isFixed: true, isSortable: true, Align: "right", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        
        { Header: "Datetime", DataKey: "rebuild_DateTime", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Comments", DataKey: "comments", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Weigth", DataKey: "loaded_Quantity_Case", Width: 100, isFixed: false, isSortable: true, Align: "right", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
       
    ],


    "GrindDetail": [
        
        //{ Header: "UID", DataKey: "uid", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        //{ Header: "Store ID", DataKey: "store_ID", Width: 150, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Store ID", DataKey: "store_ID", Width: 100, isFixed: true, isSortable: true, Align: "right", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "Comments", DataKey: "comments", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Weigth", DataKey: "loaded_Quantity_Case", Width: 100, isFixed: false, isSortable: true, Align: "right", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
       
    ]
}