/* eslint-disable react-hooks/exhaustive-deps */
import "rsuite/dist/rsuite.min.css";
import "./App.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import RoutingConstants from "./utils/routingConstants";
import { useEffect, useState } from "react";

//#region Components
import SigninCallbackComponent from "./components/auth/signin-callback";
import ProtectedRoute from "./components/auth/protectedRoute";
import PageLayoutComponent from "./components/common/pageLayoutComponent";
import ReportOrdersComponent from "./components/reports/reportOrderComponent";
import MarkdownMain from "./components/reports/MarkdownReport/MarkdownMain";
import ReportGrindLogComponent from "./components/reports/GrindLogReport/reportGrindLogComponent";
import ReportTrackITMain from "./components/reports/TrackItReport/TrackITMain";
import MainDashboard from "./components/MainDashboard";
import ReportCreditsComponent from "./components/reports/CreditsReport/reportCreditsCompontent";
import DashboardComponent from "./components/dashboardComponent";
import DeliverySchedule from "./components/settingsParts/deliveryschedule/DeliverySchedule";
import ReportDonationComponent from "./components/reports/DonationReport/reportDonationComponent";
import EquipmentByStore from "./components/settingsParts/equipmentbystore/EquipmentByStore";
import MilkOrderBook from "./components/settingsParts/milkorderbook/MilkOrderBook";
import MilkDeliverySchedule from "./components/settingsParts/milkdeliveryschedule/MilkDeliverySchedule";
import OrderReceipt from "./components/settingsParts/orderreceipt/OrderReceipt";
import OrderDetailAmp from "./components/settingsParts/orderdetailamp/OrderDetailAmp";
import OrderInvoice from "./components/reports/orderinvoice/OrderInvoice";
import ReportProductMixOptComponent from "./components/reports/ProductMixOptReport/reportProductMixOptComponent";
import ReportInventoryComponent from "./components/reports/InventoryReport/reportInventoryComponent";
import ReportOutOfStockComp from "./components/reports/OutOfStockReport/reportOutOfStockComp";
import MarkdownSetup from "./components/settingsParts/markdownsetup/MarkdownSetup";
import ReportLastDayToSave from "./components/reports/LastDayToSave/reportLastDayToSave";

//#endregion

//#region Services
import { StorageService } from "./utils/sessionService";
import NotFoundComponent from "./components/common/notFoundComponent";
import DoesNotExistComponent from "./components/auth/doesNotExistComponent";
import UserList from "./components/ums/Users/UserList";
//import ViewOrdersByDepartment from "./components/orders/ViewOrderByDepartment/ViewOrdersByDepartment";
import ConfirmationListMain from "./components/orders/ConfirmationList/ConfirmationListMain";
import RolesList from "././components/ums/Roles/RolesList";
import LoginHistory from "././components/ums/LoginHistory/LoginHistory";
import DepartmentMain from "./components/orders/ConfirmationList/ConfirmationDepartment/DepartmentMain";
import BadItemsMain from "./components/orders/BadItems/BadItemsMain";
import ViewOrderMain from "./components/orders/ViewOrderReport/ViewOrderMain";
import Checkgroups from "./components/auth/checkgroups";
import ReportSectionsToScan from "./components/reports/Placement/SectionsToScanReport/reportSectionsToScan";
//#endregion

const App = (props) => {
  const [userContext, setUserContext] = useState(null);
  const [userFileType, setUserFileType] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const [spinnerMessage, setSpinnerMessage] = useState("Loading");
  const handleSpinner = (showSpinner: boolean, message: string = "Loading") => {
    setShowSpinner(showSpinner);
    setSpinnerMessage(message);
  };
  useEffect(() => {
    const _context = StorageService.getSLSItem("UserContext");
    if (_context) setUserContext(_context);

    const _context11 = StorageService.getSLSItem("UserType");
    if (_context11) setUserFileType(_context11);
  }, []);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path={RoutingConstants.signinCallback}
            element={
              <SigninCallbackComponent
                azureAuth={props.azureAuth}
                setUserContext={setUserContext.bind(this)}
                setUserFileType={setUserFileType.bind(this)}
              />
            }
          />
          <Route
            path={RoutingConstants.userDoesNotExist}
            element={<DoesNotExistComponent />}
          />
          <Route
            path={RoutingConstants.default}
            element={
              <ProtectedRoute
                isAllowed={true}
                redirectPath={RoutingConstants.signinCallback}
              >
                <PageLayoutComponent
                  userFileType={userFileType}
                  userContext={userContext}
                  azureAuth={props.azureAuth}
                  showSpinner={showSpinner}
                  spinnerMessage={spinnerMessage}
                />
              </ProtectedRoute>
            }
          >
            {/* Reports */}
            <Route
              path={RoutingConstants.default}
              element={
                <MainDashboard
                  userContext={userContext}
                  handleSpinner={handleSpinner.bind(this)}
                />
              }
            />
            <Route
              path={RoutingConstants.reportOrders}
              element={
                <ReportOrdersComponent
                  userContext={userContext}
                  handleSpinner={handleSpinner.bind(this)}
                />
              }
            />
            <Route
              path={RoutingConstants.reportTrackItByGroup}
              element={
                <ReportTrackITMain
                  userContext={userContext}
                  handleSpinner={handleSpinner.bind(this)}
                />
              }
            />
            <Route
              path={RoutingConstants.reportMarkdown}
              element={
                <MarkdownMain
                  userContext={userContext}
                  handleSpinner={handleSpinner.bind(this)}
                />
              }
            />
            <Route
              path={RoutingConstants.reportGrindLog}
              element={
                <ReportGrindLogComponent
                  userContext={userContext}
                  handleSpinner={handleSpinner.bind(this)}
                />
              }
            />

            <Route
              path={RoutingConstants.userGroups}
              element={
                <Checkgroups
                  userContext={userContext}
                  setUserContext={setUserContext}
                  handleSpinner={handleSpinner.bind(this)}
                />
              }
            />
            <Route
              path={RoutingConstants.reportDonation}
              element={
                <ReportDonationComponent
                  userContext={userContext}
                  handleSpinner={handleSpinner.bind(this)}
                />
              }
            />
            <Route
              path={RoutingConstants.reportCredits}
              element={
                <ReportCreditsComponent
                  userContext={userContext}
                  handleSpinner={handleSpinner.bind(this)}
                />
              }
            />
            <Route
              path={RoutingConstants.dashboard}
              element={
                <DashboardComponent
                  userContext={userContext}
                  handleSpinner={handleSpinner.bind(this)}
                />
              }
            />
            <Route
              path={RoutingConstants.delivery_schedule}
              element={
                <DeliverySchedule
                  userContext={userContext}
                  handleSpinner={handleSpinner.bind(this)}
                />
              }
            />

            <Route
              path={RoutingConstants.equipment_by_store}
              element={
                <EquipmentByStore
                  userContext={userContext}
                  handleSpinner={handleSpinner.bind(this)}
                />
              }
            />

            <Route
              path={RoutingConstants.markdown_setup}
              element={
                <MarkdownSetup
                  userContext={userContext}
                  handleSpinner={handleSpinner.bind(this)}
                />
              }
            />
            <Route
              path={RoutingConstants.milk_order_book}
              element={
                <MilkOrderBook
                  userContext={userContext}
                  handleSpinner={handleSpinner.bind(this)}
                />
              }
            />
            <Route
              path={RoutingConstants.milk_delivery_schedule}
              element={
                <MilkDeliverySchedule
                  userContext={userContext}
                  handleSpinner={handleSpinner.bind(this)}
                />
              }
            />
            <Route
              path={RoutingConstants.order_receipt}
              element={
                <OrderReceipt
                  userContext={userContext}
                  handleSpinner={handleSpinner.bind(this)}
                />
              }
            />
            <Route
              path={RoutingConstants.order_detail_amp}
              element={
                <OrderDetailAmp
                  userContext={userContext}
                  handleSpinner={handleSpinner.bind(this)}
                />
              }
            />
            <Route
              path={RoutingConstants.order_savings}
              element={
                <OrderInvoice
                  userContext={userContext}
                  handleSpinner={handleSpinner.bind(this)}
                />
              }
            />
            <Route
              path={RoutingConstants.reportInventory}
              element={
                <ReportInventoryComponent
                  userContext={userContext}
                  handleSpinner={handleSpinner.bind(this)}
                />
              }
            />

            <Route
              path={RoutingConstants.reportProductMixOptimization}
              element={
                <ReportProductMixOptComponent
                  userContext={userContext}
                  handleSpinner={handleSpinner.bind(this)}
                />
              }
            />
            <Route
              path={RoutingConstants.ordersOutOfStock}
              element={
                <ReportOutOfStockComp
                  userContext={userContext}
                  handleSpinner={handleSpinner.bind(this)}
                />
              }
            />
            <Route
              path={RoutingConstants.reportPlacement}
              element={
                <ReportSectionsToScan
                  userContext={userContext}
                  handleSpinner={handleSpinner.bind(this)}
                />
              }
            />
            <Route
              path={RoutingConstants.reportLastDayToSave}
              element={
                <ReportLastDayToSave
                  userContext={userContext}
                  handleSpinner={handleSpinner.bind(this)}
                />
              }
            />
            {/* <Route
              path={RoutingConstants.reportOrdersByDepartment}
              element={
                <ViewOrdersByDepartment
                  userContext={userContext}
                  handleSpinner={handleSpinner.bind(this)}
                />
              }
            /> */}
            <Route
              path={RoutingConstants.reportOrdersByDepartment}
              element={
                <ViewOrderMain
                  userContext={userContext}
                  handleSpinner={handleSpinner.bind(this)}
                />
              }
            />
            <Route
              path={RoutingConstants.ordersConfirmationListPortal}
              element={
                <ConfirmationListMain
                  userContext={userContext}
                  handleSpinner={handleSpinner.bind(this)}
                />
              }
            />
            <Route
              path={RoutingConstants.ordersConfirmationListDepartmentPortal}
              element={
                <DepartmentMain
                  userContext={userContext}
                  handleSpinner={handleSpinner.bind(this)}
                />
              }
            />

            <Route
              path={RoutingConstants.ordersBadItems}
              element={
                <BadItemsMain
                  userContext={userContext}
                  handleSpinner={handleSpinner.bind(this)}
                />
              }
            />
            <Route
              path={RoutingConstants.userManagementUsers}
              element={
                <UserList
                  userContext={userContext}
                  handleSpinner={handleSpinner.bind(this)}
                />
              }
            />
            <Route
              path={RoutingConstants.userManagementRoles}
              element={
                <RolesList
                  userContext={userContext}
                  handleSpinner={handleSpinner.bind(this)}
                />
              }
            />
            <Route
              path={RoutingConstants.userManagementLoginHistory}
              element={
                <LoginHistory
                  userContext={userContext}
                  handleSpinner={handleSpinner.bind(this)}
                />
              }
            />

            {/* Not Found */}
            <Route path={"*"} Component={NotFoundComponent} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
