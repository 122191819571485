/* eslint-disable no-template-curly-in-string */
import React, { useState, useRef, useEffect } from "react";
import * as UMSService from "services/umsService";
import {
  Button,
  ButtonGroup,
  Modal,
  Table,
  Input,
  InputGroup,
  Whisper,
  Tooltip,
  Tag,
} from "rsuite";
import SearchIcon from "@rsuite/icons/Search";
import CreateUser from "../ForAdminUsers/CreateUserForAdmin/CreateUserForAdmin";
import UpdateUser from "../ForAdminUsers/UpdateUserForAdmin/UpdateUserForAdmin";
import { LoginHistoryConstants } from "../LoginHistoryConstants";
import { Icon } from "@iconify/react";
import moment from "moment";
import TableComponent from "../../../../components/common/tableComponent";

const UserListForAdmin = (props: any) => {
  const { userContext, handleSpinner } = props;
  const { Column, HeaderCell, Cell } = Table;

  const styles = {
    width: 300,
    marginBottom: 10,
    backgroundColor: "blue",
  };
  const [userList, setUserList] = useState([]);
  const [updateUserData, setUpdateUserData] = useState([]);
  const [filteredUserList, setFilteredUserList] = useState(null);
  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);

  const [loginHistoryData, setLoginHistoryData] = useState([]);

  const [loginHistoryUserRowData, setLoginHistoryUserRowData] = useState({});
  const [openLoginHistory, setOpenLoginHistory] = useState(false);

  const handleCloseLoginHistory = () => {
    setOpenLoginHistory(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };

  useEffect(() => {
    if (userContext && userContext.groupTypeId) {
      GetUserList(userContext.groupTypeId);
    }
  }, [userContext]);

  const GetUserList = async (groupTypeId) => {
    handleSpinner && handleSpinner(true);
    UMSService.GetUserListForAdmin(groupTypeId)
      .then((result) => {
        result.map((item) => {
          if (item.store_id === 0) {
            return (item.store_id = "");
          }
        });
        result.sort(function (a, b) {
          return b.uid - a.uid;
        });
        modifyUserList(result);
        setUserList(result);

        result && handleSpinner && handleSpinner(false);
      })
      .catch((err) => {
        console.error("Error:  - ", err);
        err && handleSpinner && handleSpinner(false);
        // toast.error("Error Loading Orders");
      });
  };

  const DeleteUser = (userID) => {
    UMSService.DeleteUser(userID)
      .then((result) => {
        //console.log(result);
        result && window.location.reload();
      })
      .catch((err) => {
        console.error("Error:  - ", err);
        // toast.error("Error Loading Orders");
      });
    return console.log(userID.toString());
  };
  const filterUserList = (value) => {
    if (value) {
      setFilteredUserList(
        userList.filter((item) =>
          String(item.email)
            .toLowerCase()
            .includes(value.toString().toLowerCase())
        )
      );
    }
  };
  const GetLoginHistory = async (userID) => {
    UMSService.GetLoginHistoryPortal(userID)
      .then((result) => {
        modifyLoginHistory(result.records);

        setLoginHistoryData(result.records);
        setOpenLoginHistory(true);
      })
      .catch((err) => {
        console.error("Error:  - ", err);
        err && handleSpinner && handleSpinner(false);
        // toast.error("Error Loading Orders");
      });
  };
  // Modify Login History Data
  const modifyLoginHistory = (data) => {
    return data.map((item) => {
      if (item["login_DateTime"]) {
        const loginDate = moment.utc(item["login_DateTime"]).local();
        if (loginDate.isValid()) {
          item["login_DateTime"] = loginDate.format("YYYY-MM-DD LT");
        }
      }
      if (item["logout_DateTime"]) {
        const logoutDate = moment.utc(item["logout_DateTime"]).local();
        if (logoutDate.isValid()) {
          item["logout_DateTime"] = logoutDate.format("YYYY-MM-DD LT");
        }
      }
      return item;
    });
  };
  const GetLoginHistoryFunc = (rowData) => {
    setLoginHistoryUserRowData(rowData);
    const { user_ID } = rowData ?? null;
    GetLoginHistory(user_ID);
  };
  const updateUser = (rowdataUID) => {
    setUpdateUserData(userList.filter((user) => user.uid === rowdataUID));
    setOpenUpdate(true);
  };
  const modifyUserList = (data) => {
    return data.map((item) => {
      if (item["last_Logout_Time"]) {
        if (item["last_Logout_Time"].includes("1900")) {
          item["last_Logout_Time"] = "";
        } else {
          const logoutDate = moment.utc(item["last_Logout_Time"]).local();
          if (logoutDate.isValid()) {
            item["last_Logout_Time"] = logoutDate.format("YYYY-MM-DD LT");
          }
        }
      }
      return item;
    });
  };
  if (userContext) {
    var { roleName } = userContext;
  }
  if (roleName === "Admin" || roleName === "Super Admin") {
    return (
      <>
        <div className="User-Management">
          <Button appearance="primary" onClick={() => setOpen(true)}>
            Create New User
          </Button>

          <InputGroup
            onChange={(event) => {
              filterUserList(event.target.value);
            }}
            style={{
              width: 200,
              height: 40,
            }}
          >
            <Input placeholder="Filter email" />
            <InputGroup.Button>
              <SearchIcon />
            </InputGroup.Button>
          </InputGroup>

          {/* <InputGroup
            onChange={(event) => {
              filterUserList(event.target.value);
            }}
            style={styles}
          >
              <Tag  color="blue">Filter email</Tag>
              <Input />
            <InputGroup.Button>
              <SearchIcon />
            </InputGroup.Button>
          </InputGroup> */}
        </div>

        <Table
          wordWrap="break-word"
          height={800}
          //onRowClick={(rowData) => GetLoginHistoryFunc(rowData)}
          data={filteredUserList ? filteredUserList : userList}
          /* onRowClick={(rowData) => {
            //console.log(rowData);
          }} */
        >
          <Column width={100} align="center" fixed>
            <HeaderCell>Login History</HeaderCell>
            <Cell>
              {(rowData) => (
                <Icon
                  onClick={() => GetLoginHistoryFunc(rowData)}
                  className="icon-size"
                  icon="ic:outline-remove-red-eye"
                />
              )}
            </Cell>
          </Column>
          {/* <Column width={50} align="center" fixed>
            <HeaderCell>ID</HeaderCell>
            <Cell dataKey="user_ID" />
          </Column> */}
          <Column width={200} fixed>
            <HeaderCell>Logout Time</HeaderCell>
            <Cell
              style={{ fontSize: "14px", fontWeight: 600, overflow: "wrap" }}
              dataKey="last_Logout_Time"
            />
          </Column>

          <Column width={150}>
            <HeaderCell>First Name</HeaderCell>
            <Cell dataKey="first_Name" />
          </Column>

          <Column width={150}>
            <HeaderCell>Last Name</HeaderCell>
            <Cell dataKey="last_Name" />
          </Column>
          <Column width={400}>
            <HeaderCell>Email</HeaderCell>
            <Cell style={{ fontWeight: 600 }} dataKey="email" />
          </Column>

          <Column width={120}>
            <HeaderCell>Role Name</HeaderCell>
            <Cell dataKey="role_Name" />
          </Column>
          <Column width={150}>
            <HeaderCell>Group Type</HeaderCell>
            <Cell dataKey="group_type_desc" />
          </Column>

          <Column width={150}>
            <HeaderCell>Group Name</HeaderCell>
            <Cell dataKey="group_ID_Desc" />
          </Column>
          <Column width={100}>
            <HeaderCell>Store ID</HeaderCell>
            <Cell dataKey="store_id" />
          </Column>

          <Column width={80} fixed="right">
            <HeaderCell>Edit User</HeaderCell>

            <Cell style={{ padding: "6px" }}>
              {(rowData) => (
                <Button
                  appearance="link"
                  // onClick={() => alert(`id:${rowData.uid}`)}
                  onClick={() => updateUser(rowData.uid)}
                >
                  Edit
                </Button>
              )}
            </Cell>
          </Column>
          <Column width={120} fixed="right">
            <HeaderCell>Delete User</HeaderCell>

            <Cell style={{ padding: "6px" }}>
              {(rowData) => (
                <Button
                  color="red"
                  appearance="ghost"
                  onClick={() =>
                    // eslint-disable-next-line no-restricted-globals
                    confirm(
                      `Are you sure to delete ${rowData.first_Name}  ${rowData.last_Name} `
                    ) && DeleteUser(rowData.uid.toString())
                  }
                >
                  Delete
                </Button>
              )}
            </Cell>
          </Column>
        </Table>

        {/* <TableComponentUserList
          isFromModal={false}
          tableDetails={UserListConstants["UserListConstants"]}
          reportList={userList}
          iconColumn={""}
          onTableRowClick={() => {}}
          reportType={""}
          isTotalRowRequired={false}
        /> */}

        <Modal open={open} onClose={handleClose} size="md">
          <Modal.Header></Modal.Header>
          <Modal.Body>
            <div style={{ textAlign: "center" }}>
              <CreateUser
                userContext={userContext}
                handleSpinner={handleSpinner}
              />
            </div>
          </Modal.Body>
        </Modal>

        <Modal open={openUpdate} onClose={handleCloseUpdate} size="md">
          <Modal.Header></Modal.Header>
          <Modal.Body>
            <div style={{ textAlign: "center" }}>
              <UpdateUser
                userData={updateUserData[0]}
                userContext={userContext}
                handleSpinner={handleSpinner}
              />
            </div>
          </Modal.Body>
        </Modal>
        {/* Login History Side */}
        <Modal
          open={openLoginHistory}
          onClose={handleCloseLoginHistory}
          size={"calc(100% - 120px"}
        >
          <Modal.Header>
            {" "}
            <div style={{ textAlign: "center" }}>
              <Modal.Title>Login History</Modal.Title>
            </div>
            <div className="bg-container row m-0 mt-3 p-3 ">
              <div className="col-2 p-2 reportDescItem">
                <>
                  <div className="icon">
                    <Icon icon="mdi:user-outline" />
                  </div>
                  <div className="label">
                    <p className="m-0">Role Name</p>
                    <p className="m-1">
                      {loginHistoryUserRowData &&
                        loginHistoryUserRowData["role_Name"]}
                    </p>
                  </div>
                </>
              </div>
              <div className="col-3 p-2 reportDescItem">
                <>
                  <div className="icon">
                    <Icon icon="ic:outline-email" />
                  </div>
                  <div className="label">
                    <p className="m-0">Email</p>
                    <p className="m-0">
                      {loginHistoryUserRowData &&
                        loginHistoryUserRowData["email"]}
                    </p>
                  </div>
                </>
              </div>
              <div className="col-2 p-2 reportDescItem">
                <>
                  <div className="icon">
                    <Icon icon="icon-park-outline:edit-name" />
                  </div>
                  <div className="label">
                    <p className="m-0">First Name</p>
                    <p className="m-1">
                      {loginHistoryUserRowData &&
                        loginHistoryUserRowData["first_Name"]}
                    </p>
                  </div>
                </>
              </div>
              <div className="col-2 p-2 reportDescItem">
                <>
                  <div className="icon">
                    <Icon icon="icon-park-outline:edit-name" />
                  </div>
                  <div className="label">
                    <p className="m-0">Last Name</p>
                    <p className="m-1">
                      {loginHistoryUserRowData &&
                        loginHistoryUserRowData["last_Name"]}
                    </p>
                  </div>
                </>
              </div>
              <div className="col-2 p-2 reportDescItem">
                <>
                  <div className="icon">
                    <Icon icon="material-symbols:store-outline" />
                  </div>
                  <div className="label">
                    <p className="m-0">Group Name</p>
                    <p className="m-1">
                      {loginHistoryUserRowData &&
                        loginHistoryUserRowData["group_ID_Desc"]}
                    </p>
                  </div>
                </>
              </div>
              <div className="col-1 p-2 reportDescItem">
                <>
                  <div className="icon">
                    <Icon icon="material-symbols:store-outline" />
                  </div>
                  <div className="label">
                    <p className="m-0">Store ID</p>
                    <p className="m-1">
                      {loginHistoryUserRowData &&
                        loginHistoryUserRowData["store_id"]}
                    </p>
                  </div>
                </>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <TableComponent
              isFromModal={false}
              tableDetails={LoginHistoryConstants["LoginHistory"]}
              reportList={loginHistoryData}
              iconColumn={""}
              onTableRowClick={() => {}}
              reportType={""}
              totalTableRow={{}}
              isTotalRowRequired={false}
            />
          </Modal.Body>
        </Modal>
      </>
    );
  } else {
    return <div>You have to contact Admin</div>;
  }
};
export default UserListForAdmin;
