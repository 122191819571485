import { useEffect, useState } from "react";
import { SelectPicker, Tabs, DatePicker } from "rsuite";
import Calendar from "./CalendarComponent";
import "./styles/_page-header.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  setGroup,
  setStore,
  setDate,
} from "../../redux/filterSelection/filterSelectionSlice";
import { RootState } from "redux/store";
// import "./styles/_calendar.scss";

const PageHeaderComponent = (props: any) => {
  const {
    setGroupTypeID,
    setAwgOrGotSelect,
    setUserStore,
    userContext,
    groupAndGroupID,
    awgOrGot,
    storeName,
    storeNameHeader,
    storeID,
    groupDepartmentData,
    isDepartment,
    onOptionChangeDepartment,
    setToDate,
    setFromDate,
    fromDate,
    toDate,
    onOptionChangeVendor,
    allVendorName,
    isVendor,
    isCalendar,
    SelectMainOrGrind,
    mainOrGrind,
    isDashboard,
    isPlacementGroup,
    oldCalendar,
  } = props;

  const [isGroupGrind, setIsGroupGrind] = useState(false);

  const SelectGroupType = (event) => {
    setGroupTypeID(event);
    setUserStore("");
    setIsGroupGrind(true);
  };

  const SelectAwgOrGot = (event) => {
    setAwgOrGotSelect(event);
  };
  const onOptionChangeHandler = (event) => {
    const VendorAndStore = event;
    setUserStore(storeID[VendorAndStore]);
    setIsGroupGrind(false);
  };

  const calenderOnClick = (startDate, endDate) => {
    setFromDate(startDate);
    setToDate(endDate);
  };

  return (
    <>
      {userContext && (
        <>
          <div>
            {isDashboard && window.innerWidth > 1200 ? (
              <>
                <SelectPicker
                  size="sm"
                  className="header-bar-item"
                  placeholder={
                    userContext?.groupType === 3 ? "Grind" : "Dashboard"
                  }
                  onChange={SelectMainOrGrind}
                  data={mainOrGrind.map((item) => ({
                    label: item.label,
                    value: item.value,
                  }))}
                />
              </>
            ) : isDashboard ? (
              userContext?.groupType !== 3 ? (
                <Tabs
                  style={{ maxWidth: "50" }}
                  defaultActiveKey={"Main"}
                  onSelect={(e) => SelectMainOrGrind(e)}
                  className="header-bar-item"
                >
                  <Tabs.Tab eventKey={"1"} title="Main"></Tabs.Tab>
                  <Tabs.Tab eventKey="2" title="Grind"></Tabs.Tab>
                  <Tabs.Tab eventKey="3" title="Placement"></Tabs.Tab>
                </Tabs>
              ) : (
                <Tabs
                  style={{ maxWidth: "50" }}
                  defaultActiveKey={"Main"}
                  onSelect={(e) => SelectMainOrGrind(e)}
                >
                  <Tabs.Tab eventKey="2" title="Grind"></Tabs.Tab>
                </Tabs>
              )
            ) : null}
          </div>
          <div className="page-header">
            <div className="page-header-items">
              {userContext && userContext.roleName === "Super Admin" ? (
                <>
                  <SelectPicker
                    placeholder="GOT"
                    style={{ marginRight: "10px" }}
                    onChange={SelectAwgOrGot}
                    label="Group Type"
                    data={awgOrGot.map((item) => ({
                      label: item.groupStore_desc,
                      value: item.groupStore_uid,
                    }))}
                  />

                  <SelectPicker
                    onChange={SelectGroupType}
                    label="Group"
                    virtualized
                    data={groupAndGroupID.map((item) => ({
                      label: item.groupStore_desc,
                      value: item.groupStore_uid,
                    }))}
                    // placeholder={groupAndGroupID.map((item) => {
                    //   if (item.groupStore_uid === chosenGroup) {
                    //     return item.groupStore_desc;
                    //   }
                    // })}
                  />
                  {!isPlacementGroup ? (
                    <SelectPicker
                      onChange={onOptionChangeHandler}
                      label="Store"
                      placeholder={
                        storeName && !isDashboard && storeName[0]
                          ? storeName[0]
                          : "All"
                      }
                      //placeholder="All"
                      virtualized
                      data={storeName.map((item, index) => ({
                        label: item,
                        value: index,
                      }))}
                    />
                  ) : null}
                </>
              ) : userContext && userContext.store !== 0 ? (
                <>
                  <label
                    style={{ height: "30px", fontWeight: 700 }}
                    className=" alert"
                  >
                    Store: {storeNameHeader}
                  </label>{" "}
                </>
              ) : userContext &&
                userContext.groupTypeId &&
                userContext.groupTypeId !== "" ? (
                <>
                  {" "}
                  {!isPlacementGroup ? (
                    <SelectPicker
                      onChange={onOptionChangeHandler}
                      label="Store"
                      placeholder={
                        userContext && userContext.roleName === "DM"
                          ? storeName[0]
                          : storeName && !isDashboard && storeName[0]
                          ? storeName[0]
                          : "All"
                      }
                      data={storeName.map((item, index) => ({
                        label: item,
                        value: index,
                      }))}
                    />
                  ) : null}
                </>
              ) : (
                <>
                  {!(
                    userContext &&
                    userContext.groupType === 2 &&
                    userContext.groupTypeId === 0
                  ) && (
                    <SelectPicker
                      onChange={SelectGroupType}
                      label="Group"
                      virtualized
                      data={groupAndGroupID.map((item) => ({
                        label: item.groupStore_desc,
                        value: item.groupStore_uid,
                      }))}
                    />
                  )}

                  {
                    <>
                      {" "}
                      {!isPlacementGroup ? (
                        isGroupGrind ? (
                          <SelectPicker
                            onChange={onOptionChangeHandler}
                            value={"All"}
                            label="Store"
                            placeholder={
                              isGroupGrind
                                ? "All"
                                : storeName && storeName[0]
                                ? storeName[0]
                                : "All"
                            }
                            virtualized
                            data={storeName.map((item, index) => ({
                              label: item,
                              value: index,
                            }))}
                          />
                        ) : (
                          <SelectPicker
                            onChange={onOptionChangeHandler}
                            label="Store"
                            placeholder={
                              (userContext?.groupType === 3 &&
                                userContext.groupTypeId === 0) ||
                              isGroupGrind
                                ? "All"
                                : storeName && storeName[0]
                                ? storeName[0]
                                : "All"
                            }
                            virtualized
                            data={storeName.map((item, index) => ({
                              label: item,
                              value: index,
                            }))}
                          />
                        )
                      ) : null}
                    </>
                  }
                </>
              )}

              {isVendor && (
                <SelectPicker
                  style={{ maxWidth: "200px" }}
                  onChange={onOptionChangeVendor}
                  label="Vendor"
                  virtualized
                  placeholder={
                    allVendorName && allVendorName[0]
                      ? allVendorName[0]["vendor_Name"]
                      : "All"
                  }
                  data={allVendorName.map((item, index) => ({
                    label: item.vendor_Name,
                    value: item.vendor_ID,
                  }))}
                />
              )}

              {isDepartment && (
                <SelectPicker
                  onChange={onOptionChangeDepartment}
                  label="Department"
                  placeholder="All"
                  virtualized
                  data={groupDepartmentData.map((item, index) => ({
                    label: item.department,
                    value: item.department,
                  }))}
                  style={{ width: 224 }}
                />
              )}
              {isCalendar && (
                <div className="calendar-container">
                  <div className="d-flex justify-content-between align-items-center">
                    {oldCalendar ? (
                      <DatePicker
                        style={{ maxWidth: "240px" }}
                        label="Report Date:"
                        oneTap
                        value={fromDate ? new Date(fromDate) : new Date()}
                        onChange={(value) => {
                          setFromDate(value);
                        }}
                      />
                    ) : (
                      <Calendar
                        value={[fromDate, toDate]}
                        calenderOnClick={calenderOnClick}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PageHeaderComponent;
