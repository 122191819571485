import { useEffect, useRef, useState } from "react";
import { Button, CheckPicker, Checkbox, DateRangePicker, Drawer, Dropdown, PickerHandle } from "rsuite";
import FileDownloadIcon from '@rsuite/icons/FileDownload';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as ReportService from "../../../services/reportServices";
import Constants from '../../.././utils/constants';
import TableComponent from "../../common/tableComponent";
import { Icon } from "@iconify/react";
import moment from "moment";
import { ReportConstants } from "./reportDonationConstants";

const ReportDonationComponent = (props: any) => {
    const [fromDate, setFromDate] = useState<Date>(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7));
    const [toDate, setToDate] = useState<Date>(new Date());
    const [showFilter, setShowFilter] = useState(false);
    const [reportList, setReportList] = useState(null);
    const [reportMasterList, setReportMasterList] = useState([]);
    const [columnFilters, setColumnFilters] = useState([]);
    const [selectedColumnFilters, setSelectedColumnFilters] = useState({});
    const [filterCount, setFilterCount] = useState({});
    const [isfilterSelected, setFilterSelected] = useState(false);
    const { combine, allowedMaxDays, afterToday } = DateRangePicker;
    const picker = useRef<PickerHandle>();

    const groupId = 6;

    useEffect(() => {
        getReportData(fromDate, toDate);
    }, [props.userContext]);

    useEffect(() => {
        columnFiltersData();
    }, [reportMasterList]);

    const getReportData = async (fromDate, toDate) => {
        const { handleSpinner, userContext = {} } = props;
        const { groupType, groupTypeId,store } = userContext;
        handleSpinner && handleSpinner(true);

        if(store !==0) {
            ReportService.getReportDonation(store, 1, moment(fromDate).format("YYYY-MM-DD"), moment(toDate).format("YYYY-MM-DD")).then((result) => {
                console.log("Donations: Result - ", result);
                const response = modifyReportData(result.records);
                console.log("Donations: Report - ", response);
                setReportList(response);
                setReportMasterList(response);
                // response.length > 0 && toast.success("Report Loaded Successfully");
            }).catch((err) => {
                console.error("Error: Get Orders - ", err);
               // toast.error("Error Loading Orders");
            }).finally(() => {
                handleSpinner && handleSpinner(false);
            });
        }else {
            ReportService.getReportDonation(groupTypeId, 2, moment(fromDate).format("YYYY-MM-DD"), moment(toDate).format("YYYY-MM-DD")).then((result) => {
                console.log("Donations: Result - ", result);
                const response = modifyReportData(result.records);
                console.log("Donations: Report - ", response);
                setReportList(response);
                setReportMasterList(response);
                // response.length > 0 && toast.success("Report Loaded Successfully");
            }).catch((err) => {
                console.error("Error: Get Orders - ", err);
               // toast.error("Error Loading Orders");
            }).finally(() => {
                handleSpinner && handleSpinner(false);
            });
        }
        
    }

    const modifyReportData = (data) => {
        let modifiedData = data.map(item => {
            item["formatedDonationDate"] = item.date_Printed && moment(item.date_Printed).format("MM-DD-YYYY");
            item["description"] = item.description !== "" && item.description.trim();
            item["total"] = (item.quantity * item.retail_Price).toFixed(2) ?? '0.00'

            return item;
        });
        return modifiedData;
    }

    const columnFiltersData = () => {
        let filtersArr = [];
        ReportConstants["Donation"].map((value) => {
            value.isSearch && filtersArr.push({
                key: value.DataKey,
                label: value.Header,
                data: [...new Set(reportMasterList.map(_ => _[value.DataKey]))].sort((a, b) => {
                    let x = a ? a : "";
                    let y = b ? b : "";
                    return x.toString().localeCompare(y.toString(), undefined, { numeric: true })
                })
            });
        });
        columnFiltersOnSelect();
        setColumnFilters(filtersArr);
        // console.log(filtersArr);
    }

    const columnFiltersOnSelect = () => {
        let selectedFiltersArr = {};
        ReportConstants["Donation"].map((value) => {
            if (value.isSearch) {
                selectedFiltersArr[value.DataKey] = [...new Set(reportMasterList.map(_ => _[value.DataKey]))];
            }
        });
        setFilterCount(selectedFiltersArr);
        setSelectedColumnFilters(selectedFiltersArr);
        filterSelected(selectedFiltersArr, selectedFiltersArr);
    }

    //#region Excel Export
    const exportToExcel = (excelData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: Constants.fileType });
        FileSaver.saveAs(data, fileName + Constants.fileExtension);
    }
    //#endregion

    const getCheckPickerData = (result) => {
        let valueArr = [];
        if (result.data) {
            valueArr = result.data.map(res => { return { label: res != '' ? res : "(Empty)", value: res } })
        }
        return valueArr;
    }

    //#region Filters Drawer
    const RenderDrawer = () => {
        return <><Drawer open={showFilter} onClose={() => setShowFilter(false)}>
            <Drawer.Header>
                <Drawer.Title>Filters</Drawer.Title>
            </Drawer.Header>
            <Drawer.Body>
                <div className="content">
                    <div className="row m-0">
                        {columnFilters && columnFilters.length > 0 && columnFilters.map((result) => {
                            return <div className="col-12 p-2">
                                <label className="px-3 pb-1 w-100">{result.label}</label>
                                <CheckPicker
                                    sticky
                                    preventOverflow
                                    className="w-100"
                                    menuMaxHeight={190}
                                    ref={picker}
                                    cleanable={selectedColumnFilters[result.key].length != filterCount[result.key].length}
                                    renderValue={(value, items) => {
                                        const _value = [...value].sort((a, b) => {
                                            let x = a ? a : "";
                                            let y = b ? b : "";
                                            return x.toString().localeCompare(y.toString(), undefined, { numeric: true })
                                        });
                                        _value[0] === "" && _value.splice(0, 1, "(Empty)");
                                        return <>{_value.length < 3 ? _value.splice(0, 2).join(', ') : _value[0] !== "(Empty)" ? _value.splice(0, 2).join(', ') : _value.splice(1, 2).join(', ')} {_value.length > 0 ? <span className="roundOff"> {`+${_value.length}`}</span> : ``}</>
                                    }}
                                    data={getCheckPickerData(result)}
                                    style={{ width: 224 }}
                                    value={selectedColumnFilters[result.key]}
                                    placeholder={result.label}
                                    renderMenuItem={(label, item) => {
                                        return (
                                            <div className="d-flex flex-column">
                                                <i className="rs-icon rs-icon-user" /> {label}
                                            </div>
                                        );
                                    }}
                                    onChange={(value, event) => {
                                        // console.log(value, event);
                                        let selectedData = { ...selectedColumnFilters };
                                        selectedData[result.key] = value;
                                        setSelectedColumnFilters(selectedData);
                                        filterSelected(filterCount, selectedData);
                                    }}
                                    renderExtraFooter={() => (
                                        <div className="footer-styles">
                                            <Checkbox
                                                indeterminate={selectedColumnFilters[result.key].length > 0 && selectedColumnFilters[result.key].length < filterCount[result.key].length}
                                                checked={selectedColumnFilters[result.key].length === filterCount[result.key].length}
                                                onChange={(value, checked) => {
                                                    // let t0 = performance.now();
                                                    let selectedData = { ...selectedColumnFilters };
                                                    selectedData[result.key] = checked ? filterCount[result.key] : [];
                                                    setSelectedColumnFilters(selectedData);
                                                    filterSelected(filterCount, selectedData);
                                                    // let t1 = performance.now();
                                                    // console.log("Onchange is taking " + (t1 - t0) + " milliseconds" );
                                                }}
                                            >
                                                Select all
                                            </Checkbox>
                                        </div>
                                    )}
                                />
                            </div>
                        })}
                    </div>
                </div>
                <div className="footer text-end">
                    {isfilterSelected && <Button appearance="ghost"
                        className="mx-2"
                        onClick={() => {
                            clearFilter();
                        }}>Reset Filter</Button>}
                    <Button appearance="primary"
                        className="mx-2"
                        disabled={!isfilterSelected && reportList && reportList.length == reportMasterList.length}
                        onClick={() => {
                            setShowFilter(false);
                            onFilterClick();
                        }}>Apply Filter</Button>
                </div>
            </Drawer.Body>
        </Drawer></>;
    }
    //#endregion

    //#region
    const filterSelected = (filterCount, selectedData) => {
        let isSelected = false;
        for (const data in filterCount) {
            if (filterCount[data] && selectedData[data] && filterCount[data].length != selectedData[data].length)
                isSelected = true;
        }
        setFilterSelected(isSelected);
    }
    //#endregion

    //#region ClearFilter
    const clearFilter = () => {
        setSelectedColumnFilters(filterCount);
        filterSelected(filterCount, filterCount);
        setReportList(reportMasterList);
    }
    //#endregion

    //#region Panel Filter Onclick
    const onFilterClick = () => {
        let data = reportMasterList;
        data = data.filter(result => {
            let selected = true;
            for (let prop in selectedColumnFilters) {
                if (selected) {
                    selected = (selectedColumnFilters[prop] && selectedColumnFilters[prop].length > 0) ? selectedColumnFilters[prop].some(item => item.toString() == result[prop].toString()) : true;
                }
            }
            return selected;
        });
        setReportList(data);
    };
    //#endregion

    return <>
        <div className="p-3">
            <div className="m-3">
                <div className="d-flex justify-content-between">
                    <div className="row w-100">
                        <div className="col-7">
                            <label className="pb-1 w-100">Select Start Date & End Date:</label>
                            <div className="d-flex justify-content-between align-items-center">
                                <DateRangePicker defaultValue={[fromDate, toDate]}
                                    shouldDisableDate={combine(allowedMaxDays(90), afterToday())}
                                    value={[fromDate, toDate]}
                                    cleanable={false}
                                    appearance="default"
                                    placeholder="Date Range"
                                    format="MM-dd-yyyy"
                                    name="dateRange"
                                    character={" to "}
                                    ranges={[]}
                                    onChange={(value) => {
                                        if (value) {
                                            setFromDate(value[0]);
                                            setToDate(value[1]);
                                            getReportData(value[0], value[1]);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className=" col-5 d-flex justify-content-end align-items-end pe-0">
                            <div className="mx-2 filter" onClick={() => { setShowFilter(!showFilter) }}><Icon icon="material-symbols:filter-alt-outline-sharp" /></div>
                            <Dropdown className="ms-2 me-0 button-fixed" title="Export" appearance="primary" placement="bottomEnd">
                                <Dropdown.Item icon={<FileDownloadIcon />}
                                    onClick={() => {
                                        exportToExcel(reportList, "Donation Report");
                                    }}
                                >Export to Excel
                                </Dropdown.Item>
                            </Dropdown>
                        </div>
                    </div>
                </div>
                {RenderDrawer()}
                <TableComponent
                    isFromModal={false}
                    tableDetails={ReportConstants["Donation"]}
                    reportList={reportList}
                    iconColumn={"NA"}
                    onTableRowClick={() => { }}
                    reportType={""}
                    totalTableRow={{}}
                    handleShowFilter={setShowFilter}
                    isTotalRowRequired={false}
                />
            </div>
        </div>
    </>
};

export default ReportDonationComponent;