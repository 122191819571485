/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import * as ReportService from "../../../../services/reportServices";
import moment from "moment";
import { ReportLDTSConstants } from "../ReportLastDayToSaveConstants";

import DiscountTable from "./DiscountTable";
const PriceDetails = (props: any) => {
  const { storeID, UPC, vendorID, ItemID, fromDate, userContext } = props;

  const [priceDetailData, setPriceDetailData] = useState([]);
  useEffect(() => {
    GetPriceDetails(storeID, UPC, vendorID, ItemID, fromDate);
  }, [storeID, UPC, vendorID, ItemID, fromDate]);

  const modifyReportData = (data) => {
    data.map((item) => {
      item.start_Date
        ? (item.start_Date = moment(item.start_Date).format("MM/DD/YYYY"))
        : item.start_Date;
      item.add_Date
        ? (item.add_Date = moment(item.add_Date).format("MM/DD/YYYY"))
        : item.add_Date;
      item.end_Date
        ? (item.end_Date = moment(item.end_Date).format("MM/DD/YYYY"))
        : item.end_Date;

      return item;
    });
  };

  const GetPriceDetails = async (storeID, UPC, vendorID, ItemID, fromDate) => {
    const { handleSpinner } = props;

    handleSpinner && handleSpinner(true);
    ReportService.GetPriceDetails(storeID, UPC, vendorID, ItemID, fromDate, 2)
      .then((result) => {
        modifyReportData(result.records);
        setPriceDetailData(result.records);
      })
      .catch((err) => {
        console.error("Error: Get Orders - ", err);
        // toast.error("Error Loading Orders");
      })
      .finally(() => {
        handleSpinner && handleSpinner(false);
      });
  };

  return (
    <div>
      <DiscountTable
        isFromModal={false}
        tableDetails={ReportLDTSConstants["PriceDetails"]}
        reportList={priceDetailData}
        iconColumn={""}
        onTableRowClick={() => {}}
        reportType={""}
        totalTableRow={{}}
        isTotalRowRequired={false}
      />
    </div>
  );
};

export default PriceDetails;
