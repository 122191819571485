import SpinnerComponent from "../common/spinnerComponent";
import { IUserContext } from "../../models"
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import RoutingConstants from "../../utils/routingConstants";
import { StorageService } from "../../utils/sessionService";
import * as AuthService from "../../services/authService";
import * as UMSService from "../../services/umsService";

const SigninCallbackComponent1 = (props) => {
  const navigate = useNavigate();
  const { azureAuth } = props;
  useEffect(() => {
    azureAuth
      .handleRedirectPromise()
      .then((authResult) => {
        var loginRequest = {
          scopes: ["openid"], // optional Array<string>
        };
        console.log("Auth Result - ", authResult);
        // Check if user signed in
        if (authResult === null) {
          // redirect anonymous user to login page
          azureAuth.loginRedirect(loginRequest);
        } else {
          getUserDataByEmail(authResult, (result) => {
            console.log("User Email Context - ", result);

            if (result.role_Name !== "Super Admin") {
              if (result && result.store_ID !== 0) {
                getUserFileType(result.store_ID, "StoreGroup");
              } else {
                if (true) {
                  getUserFileType(result.nID);
                }
              }
            }

            handleResultToken(authResult, result, (context) => {
              console.log("User Context Object - ", context);

              StorageService.setSLSItem("UserContext", context, false);
              props.setUserContext(context);
              navigate(RoutingConstants.default, { replace: true });
            });
          });
        }
      })
      .catch((err) => {
        // TODO: Handle errors
        console.log(err);
      });
  }, []);
  const getUserDataByEmail = (authResult: any, callback?: any | void) => {
    const { account } = authResult;
    console.log(account);
    const email = account["username"] ?? "";
    if (email !== "")
      AuthService.getUserDataByEmail(email)
        .then((result) => {
          const { data } = result;

          // call back ile fonksiyondan gerekli veriyi alıyor getUserDataByEmail burada çağırma nedenim ilk olarak bu execute oluyor.
          getUsersGroupsData(data.user_ID, (nID) => {
            // var newData = nID.map((item) =>
            //   item.additional_Information.split(",")
            // );
            data.nID = Number(nID[0].groupStore_UID)
              ? Number(nID[0].groupStore_UID)
              : data.nID;
            data.nName = nID[0].description ? nID[0].description : data.nName;
            callback && callback(data);
          });
          getPagePrivilegesByRoleId(data.role_ID, (privilegeResult) => {
            data.privileges = privilegeResult;
            callback && callback(data);
          });

          InsertLoginHistoryData(data.user_ID);
        })
        .catch((err) => {
          if (err.response.status === 404) {
            navigate(RoutingConstants.userDoesNotExist, { replace: true });
          }
          throw err;
        });
  };

  const handleResultToken = (
    authResult: any,
    result: any,
    callback?: any | void
  ) => {
    console.log("Handle Auth Token - ", authResult);
    console.log("Auth API Object - ", result);
    const { accessToken, account } = authResult;
    const userContext: IUserContext = {
      token: accessToken,
      userId: account["localAccountId"] ?? "",
      userName: account["name"] ?? "",
      store: result["store_ID"] ?? "",
      email: account["username"] ?? "",
      roleId: result["role_ID"],
      roleName: result["role_Name"],
      isAdmin: result["isAdmin"],
      isUser: result["isUser"],
      groupType: result["nID_Type"] ?? 0,
      groupTypeId: result["nID"] ?? 0,
      groupTypeName: result["nName"] ?? "",
      user_ID: result["user_ID"] ?? 0,
      privileges: result["privileges"] ?? [],
    };
    callback && callback(userContext);
  };

  const getPagePrivilegesByRoleId = (roleId: string, callback?: void | any) => {
    if (roleId)
      AuthService.getPagePrivilegesByRoleId(roleId)
        .then((result) => {
          const { data } = result;
          callback && callback(data);
        })
        .catch((err) => {
          throw err;
        });
  };

  const getUsersGroupsData = (user_ID: number, callback?: void | any) => {
    if (user_ID)
      AuthService.getUsersGroups(user_ID)
        .then((result) => {
          const { records } = result;
          //   var newData = records.map((item) =>
          //     item.additional_Information.split(",")
          //   );
          callback && callback(records);
        })
        .catch((err) => {
          throw err;
        });
  };

  const getUserFileType = (StoreOrIdGroup: number, UserType = null) => {
    AuthService.getUserFileType(StoreOrIdGroup, UserType)
      .then((result) => {
        const { records } = result;
        StorageService.setSLSItem("UserType", records, false);
        props.setUserFileType(records);
      })
      .catch((err) => {
        throw err;
      });
  };

  const InsertLoginHistoryData = (user_ID) => {
    const InsertLoginTime = {
      Login_UserID: user_ID,
      Status: 1,
      Login_DateTime: new Date(),
      Logout_DateTime: null,
    };

    UMSService.InsertLoginHistoryPortal(Array(InsertLoginTime))
      .then((result) => {
        console.log(result)
        //result && toast.success(` Successfully Logged in`);
      })
      .catch((err) => {
        // toast.error("Error Loading Orders");
      })
      .finally(() => {});
  };

  return (
    <>
      <SpinnerComponent message={`Signing In`} />
    </>
  );
};

export default SigninCallbackComponent1;
