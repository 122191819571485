import { TableHeaderConfig } from "../../../../models";

export const TrackItDepartmentConstants = {
  WeekDataTable: [
    {
      ...new TableHeaderConfig(),
      Header: "Store ID",
      DataKey: "store_ID",
      Width: 150,
      TypeOfData: "Text",
      isFixed: true,
      isSearch: true,
      searchGroup: [2],
    },
    // {...new TableHeaderConfig(), Header: "Company Store Id", DataKey: "store_ID1",isFixed: false},
    {
      ...new TableHeaderConfig(),
      Header: "Department",
      DataKey: "department",
      Width: 150,
      TypeOfData: "text-center",
      Align: "left",
      isFixed: false,
      isSearch: true,
      searchGroup: [2],
    },

    {
      ...new TableHeaderConfig(),
      Header: "DM",
      DataKey: "dm",
      Width: 150,
      TypeOfData: "text-center",
      isSearch: true,
      searchGroup: [2],
    },
    // {...new TableHeaderConfig(), Header: "User ID", DataKey: "user_ID", isSearch: true, searchGroup: [2]},x

    // {...new TableHeaderConfig(), Header: "Department", DataKey: "department", Width: 150},
  ],
  GroupWeekDataTable: [
    // {...new TableHeaderConfig(), Header: "Store ID", DataKey: "store_ID", isFixed: false, isSearch: true, searchGroup: [2]},
    // {...new TableHeaderConfig(), Header: "Company Store Id", DataKey: "store_ID1",isFixed: false},
    // {...new TableHeaderConfig(), Header: "DM", DataKey: "dm", isSearch: true, searchGroup: [2]},
    // {...new TableHeaderConfig(), Header: "User ID", DataKey: "user_ID", isSearch: true, searchGroup: [2]},
    //{...new TableHeaderConfig(), Header: "Department", DataKey: "department", Width: 150},
    {
      ...new TableHeaderConfig(),
      Header: "Department",
      Width: 200,
      TypeOfData: "Text",
      DataKey: "department",
      isFixed: false,
      isSearch: true,
      searchGroup: [2],
    },
  ],

  CommonHeaderStart: [
    {
      ...new TableHeaderConfig(),
      Header: "Store ID",
      DataKey: "store_ID",
      isFixed: true,
      isSearch: true,
      searchGroup: [2],
    },
    {
      ...new TableHeaderConfig(),
      Header: "Company Store Id",
      DataKey: "store_ID1",
      isFixed: false,
    },
    {
      ...new TableHeaderConfig(),
      Header: "DM",
      DataKey: "dm",
      isSearch: true,
      searchGroup: [2],
    },
    {
      ...new TableHeaderConfig(),
      Header: "User ID",
      DataKey: "user_ID",
      isSearch: true,
      searchGroup: [2],
    },
    { ...new TableHeaderConfig(), Header: "Department", DataKey: "department" },
  ],
  GroupHeaderStart: [
    {
      ...new TableHeaderConfig(),
      Header: "Department",
      DataKey: "department",
      Width: 100,
      isFixed: true,
      isSortable: true,
      TypeOfData: "Text",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      ...new TableHeaderConfig(),
      Header: "DM",
      DataKey: "dm",
      TypeOfData: "text-center",
      isSearch: true,
      searchGroup: [2],
    },
  ],
  DetailCommonHeaderStart: [
    {
      ...new TableHeaderConfig(),
      Header: "Store ID",
      DataKey: "store_ID",
      isFixed: true,
      isSearch: true,
      searchGroup: [2],
    },
    {
      ...new TableHeaderConfig(),
      Header: "Company Store Id",
      DataKey: "store_ID1",
      isFixed: false,
      isSearch: true,
      searchGroup: [2],
    },
    {
      ...new TableHeaderConfig(),
      Header: "Reclaim",
      DataKey: "reclaim",
      isFixed: false,
      isSearch: true,
      searchGroup: [2],
    },
    {
      ...new TableHeaderConfig(),
      Header: "User ID",
      DataKey: "user_ID",
      isSearch: true,
      searchGroup: [2],
    },

    //{...new TableHeaderConfig(), Header: "Company Store Id", DataKey: "store_ID1",isFixed: false},
    {
      ...new TableHeaderConfig(),
      Header: "Delivery",
      DataKey: "FormatedDate",
      isFixed: false,
      TypeOfData: "Date",
    },
    { ...new TableHeaderConfig(), Header: "UPC", DataKey: "upc", Width: 150 },
    {
      ...new TableHeaderConfig(),
      Header: "Description",
      DataKey: "description",
      Width: 10,
      TypeOfData: "text-center-heading",
    },
    // {...new TableHeaderConfig(), Header: "Department", DataKey: "department", Width: 150, isSearch: true, searchGroup: [1, 2]},

    //{...new TableHeaderConfig(), Header: "Pos Department ID", DataKey: "poS_Department_ID", Width: 100},
    { ...new TableHeaderConfig(), Header: "Size", DataKey: "size", Width: 10 },
    {
      ...new TableHeaderConfig(),
      Header: "Units",
      DataKey: "units",
      Width: 10,
      TypeOfData: "Number",
    },
    {
      ...new TableHeaderConfig(),
      Header: "LBs",
      DataKey: "weight",
      Width: 10,
      Align: "right",
      TypeOfData: "Number",
    },
    {
      ...new TableHeaderConfig(),
      Header: "Vendor",
      DataKey: "vendor",
      Width: 10,
      Align: "right",
    },
    //{...new TableHeaderConfig(), Header: "Retail Loss", DataKey: "retail_Loss", Width: 150, TypeOfData: "Price" },
    {
      ...new TableHeaderConfig(),
      Header: "Price Unit",
      DataKey: "price_Unit",
      Width: 10,
      TypeOfData: "Price",
    },
  ],
  CommomHeaderEnd: [
    {
      ...new TableHeaderConfig(),
      Header: "Price Adj",
      DataKey: "price_Adj",
      Width: 150,
      Align: "right",
      TypeOfData: "Price",
      Icon: "price_Adj",
    },
    {
      ...new TableHeaderConfig(),
      Header: "Price adjustment",
      DataKey: "Badge_Type",
      Width: 150,
      isSearch: true,
      searchGroup: [1, 2],
      isHeader: false,
    },
    {
      ...new TableHeaderConfig(),
      Header: "Total",
      DataKey: "total",
      Width: 150,
      Align: "right",
      TypeOfData: "Price",
    },
  ],
  "Store Transfer": [
    {
      ...new TableHeaderConfig(),
      Header: "Transferred From",
      DataKey: "store_ID",
      isFixed: false,
      isSearch: true,
      searchGroup: [2],
    },
    {
      ...new TableHeaderConfig(),
      Header: "DM",
      DataKey: "dm",
      isSearch: true,
      searchGroup: [2],
    },
    {
      ...new TableHeaderConfig(),
      Header: "Department",
      DataKey: "department",
      isSearch: true,
      searchGroup: [1, 2],
    },
  ],
  GroupStoreTransfer: [
    {
      ...new TableHeaderConfig(),
      Header: "Transferred From",
      DataKey: "store_ID",
      isFixed: false,
      isSearch: true,
      searchGroup: [2],
    },
    {
      ...new TableHeaderConfig(),
      Header: "DM",
      DataKey: "dm",
      isSearch: true,
      searchGroup: [2],
    },
  ],
  Donation: [
    {
      ...new TableHeaderConfig(),
      Header: "",
      DataKey: "",
      Width: 100,
      isFixed: false,
      isSortable: false,
    },
    {
      ...new TableHeaderConfig(),
      Header: "",
      DataKey: "",
      Width: 100,
      isSortable: false,
    },
    {
      ...new TableHeaderConfig(),
      Header: "Store ID",
      DataKey: "store_ID",
      Width: 100,
      isFixed: false,
      isSearch: true,
      searchGroup: [2],
    },
    {
      ...new TableHeaderConfig(),
      Header: "Total",
      DataKey: "total",
      Width: 150,
      Align: "right",
      TypeOfData: "Price",
    },
    {
      ...new TableHeaderConfig(),
      Header: "",
      DataKey: "",
      Width: 100,
      isSortable: false,
    },
    {
      ...new TableHeaderConfig(),
      Header: "",
      DataKey: "",
      Width: 100,
      isSortable: false,
    },
  ],
  DonationDetail: [
    {
      ...new TableHeaderConfig(),
      Header: "Store ID",
      DataKey: "store_ID",
      Width: 100,
      isFixed: true,
      isSearch: true,
      searchGroup: [2],
    },
    {
      ...new TableHeaderConfig(),
      Header: "Company Store Id",
      DataKey: "store_ID1",
      Width: 150,
      isFixed: false,
      isSearch: true,
      searchGroup: [1, 2],
    },
    {
      ...new TableHeaderConfig(),
      Header: "Donation Date",
      DataKey: "formatedDonationDate",
      isFixed: false,
    },
    {
      ...new TableHeaderConfig(),
      Header: "UPC",
      DataKey: "upc",
      Width: 150,
      isFixed: false,
      isSearch: true,
      searchGroup: [1, 2],
    },
    {
      ...new TableHeaderConfig(),
      Header: "Description",
      DataKey: "description",
      Width: 250,
      isFixed: false,
      TypeOfData: "text-center-heading",
      isSearch: true,
      searchGroup: [1, 2],
    },
    {
      ...new TableHeaderConfig(),
      Header: "Quantity",
      DataKey: "quantity",
      TypeOfData: "Number",
    },
    {
      ...new TableHeaderConfig(),
      Header: "Retail Price",
      DataKey: "retail_Price",
      TypeOfData: "Price",
    },
    {
      ...new TableHeaderConfig(),
      Header: "Total",
      DataKey: "total",
      Width: 150,
      Align: "right",
      TypeOfData: "Price",
    },
    { ...new TableHeaderConfig(), Header: "Markdown", DataKey: "" },
    {
      ...new TableHeaderConfig(),
      Header: "Donation Location",
      DataKey: "food_Bank",
      Width: 150,
    },
  ],
};
